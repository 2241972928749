import { useState } from "react";
import type { ListResponse } from "../../api/types";
import { AccountYearlyResult } from "../../api/models";
import { useQuery } from "@tanstack/react-query";
import { appApi } from "../../api";
import PNLRecords from "./pnlDataTable";
import { Link } from "react-router-dom";

const YearlyPNLRecords = () => {
  const queryKey = "yearlyPNLRecords";
  const [page, setPage] = useState(1);
  const { data, isFetching } = useQuery<ListResponse<AccountYearlyResult>>({
    queryKey: [queryKey, page],
    queryFn: () => appApi.pnl.getAccountYearlyPNL(page),
  });
  const columns = [
    {
      title: "Ano",
      dataIndex: "year",
      key: "year",
      render: (value: string) => (
        <Link to={`/pnl?period=monthly&year=${value}`}>{value}</Link>
      ),
    },
  ];

  return (
    <PNLRecords // @ts-ignore comment
      data={data}
      additionalColumns={columns}
      isFetching={isFetching}
      page={page}
      setPage={setPage}
    />
  );
};

export default YearlyPNLRecords;
