import {
  Col,
  Row,
  Spin,
  Form,
  Slider,
  SliderSingleProps,
  Button,
  Descriptions,
  InputNumber,
  Modal,
  Input,
} from "antd";
import { EditOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  AccountSimulationData,
  CurrentPosition,
  decodeOptionAction,
  SimulationDataParams,
  PriceSimulationData,
  AddedPosition,
} from "../../api/models";
import { appApi } from "../../api";
import CollapsibleCard from "../../components/collapsibleCard";
import { useState } from "react";
import ResponsiveTable from "../../components/ResponsiveTable";
import {
  numberToCurrency,
  numberToPercentageDisplay,
} from "../../services/utils";
import TickerDetailsLink from "../../components/tickerDetailsLink";

const marks: SliderSingleProps["marks"] = {
  50: "-50%",
  75: "-25%",
  100: "0%",
  125: "+25%",
  150: "+50%",
};

const SimulationPage = () => {
  const [form] = Form.useForm();
  const [formNewSimulatedPosition] = Form.useForm();
  const [newPositionWalletName, setNewPositionWalletName] =
    useState<string>("");

  const [mutationLoading, setMutationLoading] = useState(false);
  const { data, isLoading } = useQuery<AccountSimulationData>({
    queryKey: ["accountSimulationData"],
    queryFn: appApi.simulation.list,
  });
  const [simulatedData, setSimulatedData] = useState<AccountSimulationData>();
  const [prices, setPrices] = useState<PriceSimulationData[]>([]);
  const [newSimulatedPositions, setNewSimulatedPositions] = useState<
    AddedPosition[]
  >([]);

  const simulationMutation = useMutation({
    mutationFn: ({ globalAdjustmentPercentage }: SimulationDataParams) => {
      setMutationLoading(true);
      return appApi.simulation.simulate({
        globalAdjustmentPercentage: globalAdjustmentPercentage,
        prices: prices,
        newSimulatedPositions: newSimulatedPositions,
      });
    },
    onSuccess: async (response) => {
      setSimulatedData(response);
      setMutationLoading(false);
      if (newPositionWalletName !== "") {
        setNewPositionWalletName("");
        formNewSimulatedPosition.resetFields();
      }
    },
    onError: async () => {
      setMutationLoading(false);
    },
  });

  if (isLoading) {
    return <Spin />;
  }

  const displayedData = simulatedData || data;

  const startEditingPrice = (
    positionId: string,
    ticker: string,
    overridenProperty:
      | "priceOverride"
      | "amountOverride"
      | "volatilityOverride",
    overridenValue: number,
  ) => {
    const newPrices = prices.slice();
    const currentPosition = displayedData?.wallets
      ?.flatMap((wallet) => wallet.positions)
      .find((position) => position.id === positionId);

    if (!currentPosition) {
      return;
    }

    const existingPrice = newPrices.find((price) => price.ticker === ticker);
    if (existingPrice) {
      existingPrice[overridenProperty] = overridenValue;
      setPrices([...newPrices]);
      return;
    }

    const newPrice: PriceSimulationData = {
      ticker,
      amountOverride: null,
      priceOverride: null,
      adjustmentPercentage: 100,
      volatilityOverride: null,
      positionId: currentPosition.id,
    };
    newPrice[overridenProperty] = overridenValue;
    newPrices.push(newPrice);
    setPrices([...newPrices]);
  };

  const clearPriceOverride = (
    positionId: string,
    overridenProperty:
      | "priceOverride"
      | "amountOverride"
      | "volatilityOverride",
    ticker?: string,
  ) => {
    // match por ticker
    const newPrices = prices.slice();
    if (ticker) {
      const existingPrice = newPrices.find((price) => price.ticker === ticker);
      if (existingPrice) {
        existingPrice[overridenProperty] = null;
      }
      setPrices([...newPrices]);
      return;
    }

    // match por id
    const existingPrice = newPrices.find(
      (price) => price.positionId === positionId,
    );
    if (existingPrice) {
      existingPrice[overridenProperty] = null;
    }
    setPrices([...newPrices]);
  };

  const editedPriceByTicker = (ticker: string) => {
    return prices.find((price) => price.ticker === ticker);
  };

  const editedPriceById = (positionId: string) => {
    return prices.find((price) => price.positionId === positionId);
  };

  const updatePriceOverrideValue = (
    positionId: string,
    ticker: string,
    property: "priceOverride" | "amountOverride" | "volatilityOverride",
    value: any,
  ) => {
    const newPrices = prices.slice();
    const existingPrice = editedPriceById(ticker);
    if (existingPrice) {
      existingPrice[property] = value;
    } else {
      startEditingPrice(positionId, ticker, property, value);
    }
    setPrices([...newPrices]);
  };

  const addNewSimulatedPosition = (position: AddedPosition) => {
    const newSlice = newSimulatedPositions.slice();
    newSlice.push(position);
    setNewSimulatedPositions([...newSlice]);
    simulationMutation.mutateAsync({
      globalAdjustmentPercentage: form.getFieldValue(
        "globalAdjustmentPercentage",
      ),
      prices: prices,
      newSimulatedPositions: newSlice,
    });
  };

  return (
    <Col>
      <Row>
        <Modal
          title={`Adicionar nova posição simulada à carteira: ${newPositionWalletName}`}
          open={newPositionWalletName !== ""}
          onOk={() => {
            addNewSimulatedPosition({
              wallet: newPositionWalletName,
              ticker: formNewSimulatedPosition.getFieldValue("ticker"),
              amount: formNewSimulatedPosition.getFieldValue("amount"),
            });
          }}
          onCancel={() => {
            setNewPositionWalletName("");
            formNewSimulatedPosition.resetFields();
          }}
        >
          <Form
            form={formNewSimulatedPosition}
            layout="vertical"
            initialValues={{ amount: 100 }}
          >
            <Form.Item name="ticker" label="Ativo" required>
              <Input />
            </Form.Item>
            <Form.Item
              name="amount"
              label="Quantidade"
              rules={[
                {
                  required: true,
                  message: "Por favor, insira a quantidade",
                },
                {
                  type: "number",
                  message: "A quantidade deve ser um número",
                },
                // diferente de 0
                {
                  validator: (_, value) => {
                    if (value === 0) {
                      return Promise.reject(
                        "A quantidade deve ser diferente de 0",
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
          </Form>
        </Modal>
        <Col md={24} lg={12}>
          <CollapsibleCard title="Ajuste de preços geral">
            <Form form={form} layout="vertical">
              <Form.Item
                name="globalAdjustmentPercentage"
                className="pr-6 pl-6"
                initialValue={100}
              >
                <Slider
                  min={50}
                  max={150}
                  step={1}
                  marks={marks}
                  tooltip={{
                    formatter: (value: number | undefined) => (
                      <span>{value ? `${value - 100}%` : "0%"}</span>
                    ),
                  }}
                />
              </Form.Item>
              <Form.Item className="text-center">
                <Button
                  type="default"
                  className="mr-2"
                  loading={mutationLoading}
                  onClick={() => {
                    form.setFieldValue("globalAdjustmentPercentage", 100);
                    setPrices([]);
                    simulationMutation.mutateAsync({
                      globalAdjustmentPercentage: 100,
                      prices: [],
                      newSimulatedPositions: [],
                    });
                  }}
                >
                  Limpar simulação
                </Button>
                <Button
                  type="default"
                  loading={mutationLoading}
                  onClick={() =>
                    simulationMutation.mutateAsync({
                      globalAdjustmentPercentage: form.getFieldValue(
                        "globalAdjustmentPercentage",
                      ),
                      prices: prices,
                      newSimulatedPositions: newSimulatedPositions,
                    })
                  }
                  className="mr-2"
                >
                  Simular
                </Button>
                Ajuste aplicado:{" "}
                {form.getFieldValue("globalAdjustmentPercentage")
                  ? form.getFieldValue("globalAdjustmentPercentage") - 100
                  : 0}
                %
              </Form.Item>
            </Form>
          </CollapsibleCard>
        </Col>
        <Col md={24} lg={12}>
          <CollapsibleCard title="Resultados da simulação">
            <Descriptions column={1} bordered>
              <Descriptions.Item label="Resultado total da simulação">
                {displayedData?.totalAccountResult
                  ? numberToCurrency(displayedData?.totalAccountResult)
                  : "N/D"}
              </Descriptions.Item>
              <Descriptions.Item label="Valor total das carteiras de valor">
                {displayedData?.valueWalletsDelta
                  ? numberToCurrency(displayedData?.valueWalletsDelta)
                  : "N/D"}
              </Descriptions.Item>
            </Descriptions>
          </CollapsibleCard>
        </Col>
      </Row>
      {displayedData?.wallets.map((wallet) => {
        if (!wallet.positions.length) {
          return null;
        }
        return (
          <Row key={wallet.id}>
            <Col md={24}>
              <CollapsibleCard
                title={
                  <div className="flex flex-row justify-between w-full">
                    <div>{wallet.name}</div>
                    <Button
                      onClick={() => setNewPositionWalletName(wallet.name)}
                      icon={<PlusOutlined />}
                    >
                      Adicionar ativo a simulação
                    </Button>
                    <div>
                      Resultado: {numberToCurrency(wallet.totalWalletResult)}
                    </div>
                  </div>
                }
                key={wallet.id}
              >
                <ResponsiveTable
                  rowKey={(record) => record.id}
                  columns={[
                    {
                      title: "Ativo",
                      dataIndex: "ticker",
                      key: "ticker",
                      render: (value: string, record: CurrentPosition) => (
                        <TickerDetailsLink
                          ticker={value}
                          displayedText={
                            record.optionAlias ? record.optionAlias : value
                          }
                        />
                      ),
                    },
                    {
                      title: "Quantidade",
                      dataIndex: "amount",
                      key: "amount",
                      render: (value: number, record: CurrentPosition) => {
                        // se o preco do ativo estiver sendo editado, exibe um campo de edicao
                        const existingOverride = editedPriceById(record.id);
                        if (
                          existingOverride &&
                          existingOverride.amountOverride
                        ) {
                          return (
                            <div className="flex flex-row items-center">
                              <InputNumber
                                precision={0}
                                defaultValue={value}
                                value={existingOverride.amountOverride}
                                onChange={(newValue) =>
                                  updatePriceOverrideValue(
                                    record.id,
                                    record.ticker,
                                    "amountOverride",
                                    newValue,
                                  )
                                }
                              />
                              <Button
                                type="link"
                                icon={<CloseOutlined />}
                                onClick={() =>
                                  clearPriceOverride(
                                    record.id,
                                    "amountOverride",
                                  )
                                }
                              />
                            </div>
                          );
                        }
                        return (
                          <div className="flex flex-row items-center">
                            {value}
                            <Button
                              type="link"
                              icon={<EditOutlined />}
                              onClick={() =>
                                startEditingPrice(
                                  record.id,
                                  record.ticker,
                                  "amountOverride",
                                  value,
                                )
                              }
                            />
                          </div>
                        );
                      },
                    },
                    {
                      title: "Preço do último fechamento",
                      dataIndex: "previousMarketClosePrice",
                      key: "previousMarketClosePrice",
                      render: (value: number) =>
                        value != null ? numberToCurrency(value) : "N/D",
                    },
                    {
                      title: "Preço simulado",
                      dataIndex: "lastMarketPrice",
                      key: "lastMarketPrice",
                      render: (_: number, record: CurrentPosition) => {
                        const existingOverride = editedPriceByTicker(
                          record.ticker,
                        );
                        const value = record.fairOptionPrice
                          ? parseFloat(record.fairOptionPrice)
                          : parseFloat(record.lastMarketPrice);
                        if (
                          existingOverride &&
                          existingOverride.priceOverride
                        ) {
                          return (
                            <div className="flex flex-row items-center">
                              <InputNumber
                                precision={2}
                                min={0.01}
                                defaultValue={value}
                                value={existingOverride.priceOverride}
                                onChange={(newValue) =>
                                  updatePriceOverrideValue(
                                    record.id,
                                    record.ticker,
                                    "priceOverride",
                                    newValue,
                                  )
                                }
                              />
                              <Button
                                type="link"
                                icon={<CloseOutlined />}
                                onClick={() =>
                                  clearPriceOverride(
                                    record.id,
                                    "priceOverride",
                                    record.ticker,
                                  )
                                }
                              />
                            </div>
                          );
                        }
                        return (
                          <div className="flex flex-row items-center">
                            {value}
                            <Button
                              type="link"
                              icon={<EditOutlined />}
                              onClick={() =>
                                startEditingPrice(
                                  record.id,
                                  record.ticker,
                                  "priceOverride",
                                  value,
                                )
                              }
                            />
                          </div>
                        );
                      },
                    },
                    {
                      title: "Total da posição",
                      dataIndex: "positionTotal",
                      key: "positionTotal",
                      render: (value: number) =>
                        value != null ? numberToCurrency(value) : "N/D",
                    },
                    {
                      title: "Preço ativo adjacente",
                      dataIndex: "optionAssetLastPrice",
                      key: "optionAssetLastPrice",
                      render: (value: number, record: CurrentPosition) => {
                        if (
                          !record.optionUnderlyingAsset ||
                          !record.optionType
                        ) {
                          return "";
                        }
                        const existingOverride = editedPriceByTicker(
                          record.optionUnderlyingAsset,
                        );
                        if (
                          existingOverride &&
                          existingOverride.priceOverride
                        ) {
                          return (
                            <div className="flex flex-row items-center">
                              <InputNumber
                                precision={2}
                                min={0.01}
                                defaultValue={value}
                                value={existingOverride.priceOverride}
                                onChange={(newValue) =>
                                  updatePriceOverrideValue(
                                    record.id,
                                    record.optionUnderlyingAsset as string,
                                    "priceOverride",
                                    newValue,
                                  )
                                }
                              />
                              <Button
                                type="link"
                                icon={<CloseOutlined />}
                                onClick={() =>
                                  clearPriceOverride(
                                    record.id,
                                    "priceOverride",
                                    record.optionUnderlyingAsset as string,
                                  )
                                }
                              />
                            </div>
                          );
                        }
                        return (
                          <div className="flex flex-row items-center">
                            {value}
                            <Button
                              type="link"
                              icon={<EditOutlined />}
                              onClick={() =>
                                startEditingPrice(
                                  record.id,
                                  record.optionUnderlyingAsset as string,
                                  "priceOverride",
                                  value,
                                )
                              }
                            />
                          </div>
                        );
                      },
                    },
                    {
                      title: "Delta",
                      dataIndex: "optionDeltaDisplay",
                      key: "optionDeltaDisplay",
                    },
                    {
                      title: "Volatilidade",
                      dataIndex: "lastMarketPriceVolatility",
                      key: "lastMarketPriceVolatility",
                      render: (value: number, record: CurrentPosition) => {
                        const existingOverride = editedPriceByTicker(
                          record.ticker,
                        );
                        if (
                          existingOverride &&
                          existingOverride.volatilityOverride
                        ) {
                          return (
                            <div className="flex flex-row items-center">
                              <InputNumber
                                precision={2}
                                min={0.01}
                                defaultValue={value}
                                value={existingOverride.volatilityOverride}
                                onChange={(newValue) =>
                                  updatePriceOverrideValue(
                                    record.id,
                                    record.ticker,
                                    "volatilityOverride",
                                    newValue,
                                  )
                                }
                              />
                              <Button
                                type="link"
                                icon={<CloseOutlined />}
                                onClick={() =>
                                  clearPriceOverride(
                                    record.id,
                                    "volatilityOverride",
                                  )
                                }
                              />
                            </div>
                          );
                        }
                        return (
                          <div className="flex flex-row items-center">
                            {value ? numberToPercentageDisplay(value) : ""}
                            {record.optionType && (
                              <Button
                                type="link"
                                icon={<EditOutlined />}
                                onClick={() =>
                                  startEditingPrice(
                                    record.id,
                                    record.ticker,
                                    "volatilityOverride",
                                    value,
                                  )
                                }
                              />
                            )}
                          </div>
                        );
                      },
                    },
                    {
                      title: "Resultado diário",
                      dataIndex: "optionActionToPerform",
                      key: "optionActionToPerform",
                      render: (value: string) => decodeOptionAction(value),
                    },
                    {
                      title: "Variação",
                      dataIndex: "dayResultInPercentage",
                      key: "dayResultInPercentage",
                      render: (value: number) =>
                        value != null
                          ? numberToPercentageDisplay(value)
                          : "N/D",
                    },
                    {
                      title: "Resultado total",
                      dataIndex: "totalPositionDayResult",
                      key: "totalPositionDayResult",
                      render: (value: number) =>
                        value != null ? numberToCurrency(value) : "N/D",
                    },
                  ]}
                  dataSource={wallet.positions}
                  pagination={{ pageSize: 5 }}
                />
              </CollapsibleCard>
            </Col>
          </Row>
        );
      })}
    </Col>
  );
};

export default SimulationPage;
