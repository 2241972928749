import { useState } from "react";
import { Form, Input, Button, message, Typography } from "antd";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { appApi } from "../../api";

interface ForgotPasswordFormValues {
  email: string;
}

const ForgotPasswordPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const mutation = useMutation({
    mutationFn: ({ email }: ForgotPasswordFormValues) => {
      setLoading(true);
      return appApi.authentication.passwordReset({ email });
    },
    onSuccess: () => {
      setLoading(false);
      navigate("/");
    },
    onError: () => {
      setLoading(false);
      message.error(
        "Erro ao tentar realizar o reset da senha, por favor verifique os dados informados."
      );
    },
  });

  return (
    <>
      <Typography.Title level={4}>Esqueci minha senha</Typography.Title>
      <Form
        form={form}
        onFinish={mutation.mutateAsync}
        layout="vertical"
        style={{
          maxWidth: 500,
          width: "100%",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, type: "email" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item className="flex justify-center">
          <Button type="primary" htmlType="submit" loading={loading}>
            Resetar
          </Button>
        </Form.Item>
        <Form.Item className="flex justify-center">
          <Button
            type="link"
            onClick={() => {
              navigate("/");
            }}
          >
            Voltar para o login
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ForgotPasswordPage;
