import {
  Upload,
  Button,
  message,
  Table,
  Select,
  Typography,
  Row,
  Col,
  List,
  Tooltip,
  Form,
} from "antd";
import {
  UploadOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import React, { useEffect } from "react";
import useScreenSize from "../../../layouts/useScreenSize";
import {
  StockOperationCreationPayload,
  PositionSyncRequest,
} from "../../../api/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { appApi } from "../../../api";
import CollapsibleCard from "../../../components/collapsibleCard";
import {
  convertNumberStringToValidNumber,
  NumberFormats,
} from "../../../services/utils";

const enum FileFormats {
  PROFIT = "Profit",
  Fiainvest = "Fiainvest",
}

type ParsedRow = {
  key: number;
  ticker: string;
  amount: string;
  price: string;
  message: string;
  isValidRow: boolean;
  action: SyncActions;
};

const enum SyncActions {
  SYNC = "Sincronizar",
  IGNORE = "Ignorar",
}

type StockOperationCreationPayloadWithValidationAndAction =
  StockOperationCreationPayload & {
    message: string;
    isValidRow: boolean;
    action: SyncActions;
    key: string;
  };

const SyncPositions = () => {
  const queryClient = useQueryClient();
  const { isDesktop } = useScreenSize();
  const [fileFormat, setFileFormat] = React.useState<FileFormats | null>(null);
  const [isValidated, setIsValidated] = React.useState<boolean>(false);
  const [rowsToImport, setRowsToImport] = React.useState<string[]>([]);
  const [parsedRows, setParsedRows] = React.useState<ParsedRow[]>([]);
  const [numberFormat, setNumberFormat] = React.useState<NumberFormats>(
    NumberFormats.BRAZILIAN,
  );
  const [generatedOperations, setGeneratedOperations] = React.useState<
    StockOperationCreationPayloadWithValidationAndAction[]
  >([]);

  const uploadReader = (file: File) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const result = e?.target?.result;
      if (typeof result === "string") {
        const rows = result.split("\n");
        setRowsToImport(rows);
        setParsedRows([]);
        setGeneratedOperations([]);
        setIsValidated(false);
      } else {
        message.error("Erro ao ler arquivo, seu conteúdo não é válido.");
      }
    };
    reader.readAsText(file);

    // Prevent upload
    return false;
  };

  const validationMutation = useMutation({
    mutationFn: (values: ParsedRow[]) => {
      const payload = values.map((row) => {
        if (row.action === SyncActions.IGNORE || !row.isValidRow) {
          return null;
        }
        const payloadItem: PositionSyncRequest = {
          ticker: row.ticker,
          amount: parseInt(row.amount),
          price: row.price,
        };
        return payloadItem;
      });
      const filteredPayload = payload.filter(
        (row): row is PositionSyncRequest => row !== null,
      );
      return appApi.stockOperations.walletsSyncGenerateOperations({
        params: filteredPayload,
      });
    },
    onSuccess: (response) => {
      if (response) {
        let errorsFound = false;
        const newParsedRows = parsedRows.map((row) => {
          const validationError = response.validations.find(
            (record) => record?.ticker === row.ticker,
          );
          if (validationError) {
            errorsFound = true;
            return {
              ...row,
              message: validationError.message,
              isValidRow: false,
              action: SyncActions.IGNORE,
            };
          } else return row;
        });
        setParsedRows(newParsedRows);

        // setar operacoes geradas
        const generatedOperations: StockOperationCreationPayloadWithValidationAndAction[] =
          response.operations.map((operation) => {
            const op: StockOperationCreationPayloadWithValidationAndAction = {
              key: `${operation.ticker}-${operation.date}-${operation.wallet}-${operation.amount}`,
              date: operation.date,
              ticker: operation.ticker,
              amount: parseFloat(operation.amount),
              price: operation.price,
              wallet: operation.wallet,
              message: operation.message,
              isValidRow: !operation.isBlocking,
              action: operation.isBlocking
                ? SyncActions.IGNORE
                : SyncActions.SYNC,
            };
            return op;
          });
        setGeneratedOperations(generatedOperations);
        setIsValidated(true);
        if (errorsFound) {
          message.error(
            "Foram encontrados erros nas validações, verifique a tabela de importação para mais detalhes.",
          );
        }
        if (generatedOperations.length === 0) {
          message.success(
            "Nenhuma operação foi gerada. Sua carteira se encontra sincronizada.",
          );
        }
      }
    },
  });

  const importMutation = useMutation({
    mutationFn: (
      values: StockOperationCreationPayloadWithValidationAndAction[],
    ) => {
      const payload = values.map((row) => {
        if (row.action === SyncActions.IGNORE) {
          return null;
        }
        const payloadItem: StockOperationCreationPayload = {
          date: row.date,
          ticker: row.ticker,
          amount: row.amount,
          price: row.price,
          wallet: row.wallet,
          description: "Operação gerada para sincronismo de posição.",
        };
        return payloadItem;
      });
      const filteredPayload = payload.filter(
        (row): row is StockOperationCreationPayload => row !== null,
      );
      return appApi.stockOperations.bulkCreate(filteredPayload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["operationsHistory"] });
      queryClient.invalidateQueries({ queryKey: ["wallets"] });
      queryClient.invalidateQueries({ queryKey: ["currentPositions"] });
      queryClient.invalidateQueries({ queryKey: ["accounts"] });
      message.success("Operações importadas com sucesso.");
      setParsedRows([]);
      setGeneratedOperations([]);
      setRowsToImport([]);
    },
  });

  useEffect(() => {
    if (rowsToImport.length > 0) {
      const parsedRows = rowsToImport.map((row, index) => {
        let ticker = "";
        let amount = "";
        let price = "";

        // se a linha for vazia ou só tiver espacos em branco ignora
        if (row.trim() === "") {
          return null;
        }

        if (fileFormat === FileFormats.PROFIT) {
          // cabecalho do arquivo do profit fica na primeira linha
          // Ativo;Qtd;Preço Médio;Res. Aberto;Res. Aberto (%);Res. do Dia;Res. do Dia (%);Intraday;Custódia;Preço Atual;Valor da Posição;Ordens Abertas;Qtd. em Ordens Abertas;Custos Operacionais;Qtd Compra Total;Qtd Venda Total;Preço Compra Médio;Preço Venda Médio;Total Compra;Total Venda;Peso
          if (index === 0) {
            return null;
          }
          const fieldsList = row.split(";");
          ticker = fieldsList[0];
          amount = fieldsList[1];
          price = fieldsList[2];
        } else {
          // cabecalho do arquivo fiainvest fica na primeira linha
          // ativo;quantidade;preco_medio
          if (index === 0) {
            return null;
          }
          [ticker, amount, price] = row.split(";");
        }
        if (
          (ticker === "" || ticker === null || ticker === undefined) &&
          (amount === "" || amount === null || amount === undefined) &&
          (price === "" || price === null || price === undefined)
        ) {
          return null;
        }

        // processamento de campos
        let message = "";
        let isValidRow = true;

        amount = convertNumberStringToValidNumber(amount, numberFormat, false);
        price = convertNumberStringToValidNumber(price, numberFormat, true);

        const parsedAmount = Number(amount);
        if (!Number.isInteger(parsedAmount)) {
          message += "Quantidade inválida.";
          isValidRow = false;
        }
        price = price
          ? price.replace(",", ".").replace("R$", "").replace(" ", "")
          : "";
        const parsedPrice = Number(price);
        if (Number.isNaN(parsedPrice)) {
          message += "Preço inválido.";
          isValidRow = false;
        }

        return {
          key: index,
          ticker: ticker,
          amount: parsedAmount.toString(),
          price: parsedPrice.toString(),
          message: message,
          isValidRow,
          action: isValidRow ? SyncActions.SYNC : SyncActions.IGNORE,
        };
      });
      const filteredRows = parsedRows.filter(
        (row): row is ParsedRow => row !== null,
      );
      // sort invalid rows first
      filteredRows.sort((a, b) => {
        if (a.isValidRow && !b.isValidRow) {
          return 1;
        } else if (!a.isValidRow && b.isValidRow) {
          return -1;
        } else {
          return 0;
        }
      });
      setParsedRows(filteredRows);
      setIsValidated(false);
    }
  }, [rowsToImport, fileFormat, numberFormat]);

  const setRowAction = (key: number, action: SyncActions) => {
    const newParsedRows = parsedRows.map((row) => {
      if (row.key === key) {
        return { ...row, action };
      }
      return row;
    });
    setParsedRows(newParsedRows);
  };

  const renderActionSelection = (
    value: SyncActions | undefined,
    row: ParsedRow,
  ) => {
    return (
      <Select
        onChange={(value) => setRowAction(row.key, value)}
        placeholder="Ação a realizar"
        className=" min-w-32"
        value={value}
        disabled={!row.isValidRow}
      >
        <Select.Option value={SyncActions.SYNC}>Sincronizar</Select.Option>
        <Select.Option value={SyncActions.IGNORE}>Ignorar</Select.Option>
      </Select>
    );
  };

  const setOperationAction = (key: string, action: SyncActions) => {
    const newGeneratedOperations = generatedOperations.map((operation) => {
      if (operation.key === key) {
        return { ...operation, action };
      }
      return operation;
    });
    setGeneratedOperations(newGeneratedOperations);
  };

  const renderOperationSelection = (
    value: SyncActions | undefined,
    row: StockOperationCreationPayloadWithValidationAndAction,
  ) => {
    return (
      <Select
        onChange={(value) => setOperationAction(row.key, value)}
        placeholder="Ação a realizar"
        className=" min-w-32"
        value={value}
        disabled={!row.isValidRow}
      >
        <Select.Option value={SyncActions.SYNC}>Importar</Select.Option>
        <Select.Option value={SyncActions.IGNORE}>Ignorar</Select.Option>
      </Select>
    );
  };

  const renderPreValidationMessage = (value: string, record: ParsedRow) => {
    if (record.isValidRow) {
      return (
        <Tooltip title={"A checagem de formatação dos dados passou"}>
          <CheckCircleOutlined style={{ color: "green", fontSize: 20 }} />
        </Tooltip>
      );
    }
    const errorMessage = value ? value : record.message;
    record.message;
    return (
      <Tooltip title={errorMessage}>
        <CloseCircleOutlined style={{ color: "red", fontSize: 20 }} />
      </Tooltip>
    );
  };

  const columns = [
    {
      title: "Ativo",
      dataIndex: "ticker",
      key: "ticker",
      onFilter: (value: any, record: ParsedRow) =>
        record.ticker.startsWith(value as string),
      filters: [...new Set(parsedRows.map((row) => row.ticker)).values()].map(
        (ticker) => ({ text: ticker, value: ticker }),
      ),
      sorter: (a: ParsedRow, b: ParsedRow) => a.ticker.localeCompare(b.ticker),
      filterSearch: true,
    },
    {
      title: "Quantidade",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Preço médio",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Status",
      dataIndex: "message",
      key: "message",
      render: (value: string, record: ParsedRow) =>
        renderPreValidationMessage(value, record),
    },
    {
      title: "Ação",
      dataIndex: "action",
      key: "action",
      render: (value: SyncActions, row: ParsedRow) =>
        renderActionSelection(value, row),
    },
  ];

  const renderValidationMessage = (
    value: string,
    record: StockOperationCreationPayloadWithValidationAndAction,
  ) => {
    if (record.isValidRow) {
      return (
        <Tooltip title={"Nenhum problema encontrado"}>
          <CheckCircleOutlined style={{ color: "green", fontSize: 20 }} />
        </Tooltip>
      );
    }
    const errorMessage = value ? value : record.message;
    record.message;
    return (
      <Tooltip title={errorMessage}>
        <CloseCircleOutlined style={{ color: "red", fontSize: 20 }} />
      </Tooltip>
    );
  };

  const columnsOperations = [
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Ativo",
      dataIndex: "ticker",
      key: "ticker",
      onFilter: (
        value: any,
        record: StockOperationCreationPayloadWithValidationAndAction,
      ) => record.ticker.startsWith(value as string),
      filters: [
        ...new Set(generatedOperations.map((row) => row.ticker)).values(),
      ].map((ticker) => ({ text: ticker, value: ticker })),
      sorter: (
        a: StockOperationCreationPayloadWithValidationAndAction,
        b: StockOperationCreationPayloadWithValidationAndAction,
      ) => a.ticker.localeCompare(b.ticker),
      filterSearch: true,
    },
    {
      title: "Quantidade",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Preço da operação",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Carteira atual",
      dataIndex: "wallet",
      key: "wallet",
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Status",
      dataIndex: "message",
      key: "message",
      render: (
        value: string,
        record: StockOperationCreationPayloadWithValidationAndAction,
      ) => renderValidationMessage(value, record),
    },
    {
      title: "Ação",
      dataIndex: "action",
      key: "action",
      render: (
        value: SyncActions,
        row: StockOperationCreationPayloadWithValidationAndAction,
      ) => renderOperationSelection(value, row),
    },
  ];

  const importCount = generatedOperations.filter(
    (row) => row.action === SyncActions.SYNC,
  ).length;

  return (
    <Col className="w-full">
      <Row>
        <CollapsibleCard title="Instruções" collapsible={false}>
          <Typography.Paragraph>
            São aceitos arquivos nos formatos .txt e .csv.
          </Typography.Paragraph>
          <Typography.Paragraph>
            São aceitas listas de posições exportadas do profit ou montadas
            utilizando o template próprio da plataforma, que pode ser baixado{" "}
            <Button
              className="p-0"
              type="link"
              href="/templates/sincronizar_posicoes.csv"
            >
              aqui.
            </Button>
          </Typography.Paragraph>
          <Typography.Paragraph>
            A importação de posições gera ordens na plataforma que irão
            transformar suas posições atuais cadastradas nas posições definidas
            no arquivo importado. Se desejar, pode realizar a exportação de suas
            posições atuais para utilizar este arquivo como base.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Para posições que não estejam contidas no arquivo importado serão
            geradas ordens de zeragem da posição, ou seja, a plataforma irá
            vender ou comprar a quantidade necessária para que a posição fique
            zerada. Caso não queira afetar alguma das posições da lista, basta
            selecionar a opção de ignorar na tabela de ordens geradas.
          </Typography.Paragraph>
        </CollapsibleCard>
      </Row>
      <CollapsibleCard title="Leitura do arquivo" collapsible={false}>
        <Row className="flex justify-between w-full">
          <Select
            onChange={(value) => {
              setFileFormat(value);
              setParsedRows([]);
              setGeneratedOperations([]);
            }}
            placeholder="Formato do arquivo utilizado"
            className=" min-w-32"
          >
            <Select.Option value={FileFormats.PROFIT}>Profit</Select.Option>
            <Select.Option value={FileFormats.Fiainvest}>
              Fiainvest
            </Select.Option>
          </Select>
          <Select
            onChange={(value) => setNumberFormat(value)}
            placeholder="Formato de números"
            className=" min-w-32"
            value={numberFormat}
          >
            <Select.Option value={NumberFormats.BRAZILIAN}>
              Formato Brasileiro (1.000,00)
            </Select.Option>
            <Select.Option value={NumberFormats.AMERICAN}>
              Formato Americano (1,000.00)
            </Select.Option>
          </Select>
          <Upload
            accept=".txt, .csv"
            showUploadList={true}
            beforeUpload={uploadReader}
            multiple={false}
            maxCount={1}
            disabled={fileFormat === null}
          >
            <Button disabled={fileFormat === null} icon={<UploadOutlined />}>
              Escolher arquivo
            </Button>
          </Upload>
        </Row>
        <Row className="mt-4 mb-4 w-full">
          {isDesktop && (
            <Table
              dataSource={parsedRows}
              pagination={{ pageSize: 8, showSizeChanger: false }}
              columns={columns}
              size="small"
              className="w-full"
            />
          )}
          {!isDesktop && (
            <List
              size="small"
              dataSource={parsedRows}
              itemLayout="vertical"
              pagination={{ pageSize: 1 }}
              className="w-full"
              renderItem={(item: ParsedRow) => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <div className="flex justify-between">
                        <span>{item.ticker}</span>
                      </div>
                    }
                  />
                  <Form layout="vertical">
                    <Form.Item label={"Quantidade"}>{item.amount}</Form.Item>
                    <Form.Item label={"Preço"}>{item.price}</Form.Item>
                    <Form.Item label="Status">
                      {renderPreValidationMessage(item.message, item)}
                    </Form.Item>
                    <Form.Item label="Ação">
                      {renderActionSelection(item.action, item)}
                    </Form.Item>
                  </Form>
                </List.Item>
              )}
            />
          )}
        </Row>
        <Row className="flex justify-end">
          <Button
            type="primary"
            onClick={() => validationMutation.mutateAsync(parsedRows)}
            disabled={validationMutation.isPending || parsedRows.length === 0}
            loading={validationMutation.isPending}
          >
            Avaliar diferenças
          </Button>
        </Row>
      </CollapsibleCard>
      <CollapsibleCard title="Operações geradas" collapsible={false}>
        <Row className="mt-4 mb-4 w-full">
          {isDesktop && (
            <Table
              dataSource={generatedOperations}
              pagination={{ pageSize: 8, showSizeChanger: false }}
              columns={columnsOperations}
              size="small"
              className="w-full"
            />
          )}
          {!isDesktop && (
            <List
              size="small"
              dataSource={generatedOperations}
              itemLayout="vertical"
              pagination={{ pageSize: 1 }}
              className="w-full"
              renderItem={(
                item: StockOperationCreationPayloadWithValidationAndAction,
              ) => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <div className="flex justify-between">
                        <span>{item.ticker}</span>
                        <span>{item.date}</span>
                      </div>
                    }
                  />
                  <Form layout="vertical">
                    <Form.Item label={"Quantidade"}>{item.amount}</Form.Item>
                    <Form.Item label={"Preço"}>{item.price}</Form.Item>
                    <Form.Item label="Status">
                      {renderValidationMessage(item.message, item)}
                    </Form.Item>
                    <Form.Item label="Carteira">{item.wallet}</Form.Item>
                    <Form.Item label="Ação">
                      {renderOperationSelection(item.action, item)}
                    </Form.Item>
                  </Form>
                </List.Item>
              )}
            />
          )}
        </Row>
        <Row className="flex justify-end">
          <Button
            className="ml-2"
            type="primary"
            onClick={() => importMutation.mutateAsync(generatedOperations)}
            disabled={
              !isValidated ||
              importCount === 0 ||
              validationMutation.isPending ||
              importMutation.isPending
            }
            loading={importMutation.isPending}
          >
            Corrigir diferenças ({importCount})
          </Button>
        </Row>
      </CollapsibleCard>
    </Col>
  );
};

export default SyncPositions;
