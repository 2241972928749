import { Tooltip } from "antd";
import { numberToCurrency } from "../services/utils";

const PriceDisplay = ({
  price,
  hasOverride,
}: {
  price: number | string | null | undefined;
  hasOverride?: boolean;
}) => {
  return (
    <span
      className={`${price && parseFloat(price as string) < 0 ? "text-red-500" : ""}`}
    >
      {price ? numberToCurrency(price) : "R$ 0,00"}
      {hasOverride && (
        <Tooltip title="Valor ajustado manualmente">
          <span className="text-xs text-gray-400"> ***</span>
        </Tooltip>
      )}
    </span>
  );
};

export default PriceDisplay;
