import { Breadcrumb, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useSearchParams, Link } from "react-router-dom";
import MonthlyIncomeRecords from "./monthlyRecords";
import MonthlyIncomeRecordEntries from "./monthlyDetails";

const MonthlyIncome = () => {
  const [searchParams] = useSearchParams();
  const year = searchParams.get("year");
  const month = searchParams.get("month");

  return (
    <>
      <div className="p-2 flex justify-between items-center">
        <Breadcrumb className="pb-2">
          <Breadcrumb.Item>
            {year && month ? (
              <Link to={""} className="underline">
                Renda mensal
              </Link>
            ) : (
              <Tooltip title="A renda mensal é apurada considerando os dividendos e JCP cuja data COM tenha ocorrido no mês correspondente. No caso das opções a renda do mês considera a data de expiração das opções ou a data da negociação, conforme definição da personalização da conta.">
                Renda mensal <InfoCircleOutlined />
              </Tooltip>
            )}
          </Breadcrumb.Item>
          {year && month && (
            <Breadcrumb.Item>
              {year} - {month}
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
        {year && month && (
          <Link to={""} className="underline">
            Voltar
          </Link>
        )}
      </div>
      {year && month ? (
        <MonthlyIncomeRecordEntries />
      ) : (
        <MonthlyIncomeRecords />
      )}
    </>
  );
};

export default MonthlyIncome;
