import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { appApi } from "../../../../api";
import { Wallet, WalletNames } from "../../../../api/models";
import { ListResponse } from "../../../../api/types";
import { Alert, Row, Col, message, Tabs } from "antd";
import { useEffect, useState } from "react";
import CollapsibleCard from "../../../../components/collapsibleCard";
import MonitoredItems from "./monitoredItems";
import AvailableItems from "./availableItems";
import AssetTab from "./assetTab";

export type SelectedTickerConfig = {
  ticker: string;
  targetPercentage: number;
};

const PicturesWallet = () => {
  const queryClient = useQueryClient();
  const [monitoredKeys, setMonitoredKeys] = useState<SelectedTickerConfig[]>(
    [],
  );
  const { data: valueWalletData, isLoading: isLoadingValue } = useQuery<
    ListResponse<Wallet>
  >({
    queryKey: ["wallets", WalletNames.VALUE],
    queryFn: () => appApi.wallets.getList(WalletNames.VALUE),
  });

  const { data: picturesWalletData, isLoading: isLoadingPictures } = useQuery<
    ListResponse<Wallet>
  >({
    queryKey: ["wallets", WalletNames.PICTURES],
    queryFn: () => appApi.wallets.getList(WalletNames.PICTURES),
  });

  const valueWallet =
    valueWalletData?.results && valueWalletData?.results.length > 0
      ? valueWalletData.results[0]
      : null;

  const picturesWallet =
    picturesWalletData?.results && picturesWalletData?.results.length > 0
      ? picturesWalletData.results[0]
      : null;

  useEffect(() => {
    if (picturesWallet) {
      const selectedTickers =
        picturesWallet.walletParameters?.selectedTickers ?? [];
      setMonitoredKeys(selectedTickers);
    }
  }, [picturesWallet]);

  const editWalletMutation = useMutation({
    mutationFn: (keys: SelectedTickerConfig[]) => {
      return appApi.wallets.update({
        id: picturesWallet?.id ?? "",
        walletParameters: {
          selectedTickers: keys,
        },
      });
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ["wallets", WalletNames.PICTURES],
      });
      message.success("Lista atualizada.");
    },
    onError: async () => {
      await queryClient.refetchQueries({
        queryKey: ["wallets", WalletNames.PICTURES],
      });
      const selectedTickers =
        picturesWallet?.walletParameters?.selectedTickers ?? [];
      setMonitoredKeys(selectedTickers);
    },
  });

  if (isLoadingValue || isLoadingPictures) {
    return <div>Carregando...</div>;
  }
  if (!valueWallet) {
    return (
      <Alert message="Erro ao carregar a carteira de ações." type="error" />
    );
  }
  if (!picturesWallet) {
    return (
      <Alert message="Erro ao carregar a carteira de figuras." type="error" />
    );
  }

  let monitoredItemTabs = monitoredKeys.map((item) => ({
    key: item.ticker,
    label: item.ticker,
    children: <AssetTab asset={item.ticker} picturesWallet={picturesWallet} />,
  }));

  monitoredItemTabs = [
    {
      key: "all",
      label: "Todos os ativos",
      children: <AssetTab asset="all" picturesWallet={picturesWallet} />,
    },
    ...monitoredItemTabs,
  ];

  return (
    <Row>
      <Col md={12} lg={8} className="w-full">
        <Row className="w-full">
          <MonitoredItems
            monitoredKeys={monitoredKeys}
            setMonitoredKeys={setMonitoredKeys}
            valueWallet={valueWallet}
            editWalletMutation={editWalletMutation as any}
          />
        </Row>
        <Row className="w-full">
          <AvailableItems
            valueWallet={valueWallet}
            monitoredKeys={monitoredKeys}
            editWalletMutation={editWalletMutation as any}
            setMonitoredKeys={setMonitoredKeys}
          />
        </Row>
      </Col>
      <Col sm={24} md={12} lg={16} className="w-full">
        <Tabs items={monitoredItemTabs} />
        <Row>
          <Col md={24} lg={12} className="w-full">
            <CollapsibleCard title="Orientação - Call Coberta">
              <h3>Montagem</h3>
              <p>
                <strong>Venda</strong> de <strong>Call Delta % 23 a 28</strong>
              </p>
              <p>
                <strong>Compra</strong> de{" "}
                <strong>Call Pozinho Delta % 6 a 8</strong>, se houver liquidez
              </p>
              <br />
              <h3>Rolagem</h3>
              <p>
                <strong>2 a 3 semanas</strong> antes do vencimento
              </p>
              <p>
                <strong>Rolar</strong> venda de <strong>Call</strong>
              </p>
              <p>
                <strong>Rolar</strong> Compra de <strong>Call</strong> pozinho
                se <strong>Delta % {">"} 6</strong>
              </p>
              <br />
              <h3>Delta Hedge</h3>
              <p>
                <strong>Monitorar diariamente</strong>
              </p>
              <p>
                <strong>Rolar</strong> venda de{" "}
                <strong>
                  Call Delta % {"<"} 15% ou {">"} 45%
                </strong>
              </p>
              <p>
                <strong>Rolar</strong> compra de{" "}
                <strong>Call pozinho se Delta % {">"} 6%</strong>
              </p>
            </CollapsibleCard>
          </Col>
          <Col md={24} lg={12} className="w-full">
            <CollapsibleCard title="Orientação - Put Spread">
              <h3>Montagem</h3>
              <p>
                <strong>Venda</strong> de <strong>Put Delta % 50</strong>
              </p>
              <p>
                <strong>Compra</strong> de <strong>Put Delta % 30</strong>
              </p>
              <br />
              <h3>Rolagem</h3>
              <p>
                <strong>2 a 3 semanas</strong> antes do vencimento
              </p>
              <p>
                <strong>Rolar</strong> ou <strong>zerar</strong> venda e compra
                de <strong>Put</strong>
              </p>
              <br />
              <h3>Delta Hedge</h3>
              <p>
                <strong>Mantenha atenção na Figura Call Coberta</strong>
              </p>
            </CollapsibleCard>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PicturesWallet;
