import { useState } from "react";
import type { ListResponse } from "../api/types";
import { CalendarItem } from "../api/models";
import { appApi } from "../api";
import { Calendar, List, Modal, Tag, Tooltip } from "antd";
import { Avatar, Badge, Space } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";

const getColorTag = (item: CalendarItem) => {
  const color =
    item.country === "BR" && item.type === "holiday"
      ? "red"
      : item.country === "US" && item.type === "holiday"
        ? "blue"
        : "purple";
  const calendarDescription =
    color === "red" ? "BR" : color === "blue" ? "US" : "***";
  return (
    <Tooltip title={item.name} key={item.name}>
      <Tag key={item.name} color={color}>
        {calendarDescription}
      </Tag>
    </Tooltip>
  );
};

const CalendarData = () => {
  const [visible, setVisible] = useState(false);
  const { data } = useQuery<ListResponse<CalendarItem>>({
    queryKey: ["calendar"],
    queryFn: () => appApi.resources.getCalendar(),
  });

  // only count items that have the date coming up in the next 7 days
  const comingUpHolidays =
    data?.results?.filter((item) => {
      return dayjs(item.date, "YYYY-MM-DD").isBefore(dayjs().add(7, "days"));
    }) || [];

  const dates = data?.results || [];
  const count = comingUpHolidays.length;

  const dateCellRender = (value: Dayjs) => {
    return (
      <div className="flex items-center justify-center">
        {dates.map((item) => {
          if (dayjs(item.date).isSame(value, "day")) {
            return getColorTag(item);
          }
          return null;
        })}
      </div>
    );
  };

  return (
    <>
      <Space size="middle">
        <Badge size="default" count={count}>
          <Avatar
            shape="square"
            icon={<CalendarOutlined size={30} />}
            onClick={() => setVisible(!visible)}
            style={{ fontSize: "24px", cursor: "pointer" }}
          />
        </Badge>
      </Space>
      <Modal
        title="Datas relevantes dos próximos 90 dias"
        onCancel={() => setVisible(false)}
        footer={null}
        open={visible}
      >
        <Calendar
          validRange={[dayjs(), dayjs().add(90, "days")]}
          cellRender={dateCellRender}
          mode="month"
        />
        <List
          itemLayout="horizontal"
          dataSource={dates}
          header={<div>Datas</div>}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <div className="flex justify-between">
                    <span>{item.name}</span>
                    <span>{getColorTag(item)}</span>
                  </div>
                }
                description={dayjs(item.date).format("DD/MM/YYYY")}
              />
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};

export default CalendarData;
