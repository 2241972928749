import { WalletNames } from "../../../api/models";
import ValueWalletTemplate from "./valueWalletTemplate";

const ValueWallet = () => {
  return (
    <ValueWalletTemplate
      walletName={WalletNames.VALUE}
      title="Carteira de Ações"
      descriptionOneTitle="O que fazer"
      descriptionOne={
        <>
          <p>
            <span style={{ fontWeight: "bold" }}>Montar Carteira de Ações</span>
            : Definir Delta Carteira de Ações - de 10 a 25 papeis em setores
            diferentes. Nenhuma ação representa mais de 8% da carteira
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Comprar ações (lastro)</span>:
            Ações para ficar de herança! Compre bons negócios nunca pagando caro
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Aproveite as "crises"</span>:
            IBOV -15% | -25% | -35% | -45% da máxima de 3 meses
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              Mexa pouco nas suas ações:
            </span>{" "}
            6 meses a 1 ano.
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Colecione ações:</span> Foco
            deve ser em ter o maior número possível de ações das empresas
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              Coloque ações em margem / garantia na B3
            </span>{" "}
            para operar opções
          </p>
        </>
      }
    />
  );
};

export default ValueWallet;
