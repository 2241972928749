import { Form, Button, Select, Modal } from "antd";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { appApi } from "../../../api";
import { WALLET_QUERY_KEYS } from "../constants";
import {
  CurrentPosition,
  decodePositionNotificationFreezeMode,
} from "../../../api/models";
import { PositionNotificationFreezeMode } from "../../../api/models";

interface FormValues {
  positionNotificationFreezeMode: PositionNotificationFreezeMode | null;
}

const DhNotificationMode = ({
  currentPosition,
}: {
  currentPosition: CurrentPosition;
}) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const positionsKey = ["currentPositions"];
  const [mutationLoading, setMutationLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modal, contextHolder] = Modal.useModal();

  const splitPositionMutation = useMutation({
    mutationFn: ({ positionNotificationFreezeMode }: FormValues) => {
      setMutationLoading(true);
      return appApi.currentPositions.update({
        id: currentPosition.id,
        positionNotificationFreezeMode: positionNotificationFreezeMode,
      });
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: positionsKey });
      WALLET_QUERY_KEYS.forEach(async (key: any) => {
        queryClient.invalidateQueries({ queryKey: [key] });
      });
      setMutationLoading(false);
      setVisible(false);
    },
    onError: async () => {
      setMutationLoading(false);
    },
  });

  const onFormSubmit = async () => {
    const values = form.getFieldsValue() as FormValues;
    const { positionNotificationFreezeMode } = values;

    if (positionNotificationFreezeMode === PositionNotificationFreezeMode.ALL) {
      splitPositionMutation.mutateAsync({
        positionNotificationFreezeMode: null,
      });
    } else if (
      positionNotificationFreezeMode === PositionNotificationFreezeMode.OFF
    ) {
      modal.confirm({
        title:
          "Tem certeza que deseja desativar as notificações para essa opção?",
        onOk: () => {
          splitPositionMutation.mutateAsync(values);
        },
      });
    } else {
      splitPositionMutation.mutateAsync(values);
    }
  };

  if (currentPosition.amount < 0 && currentPosition.itemType === "OPTION") {
    return (
      <div>
        <Button type="link" onClick={() => setVisible(true)}>
          {decodePositionNotificationFreezeMode(
            currentPosition.positionNotificationFreezeMode,
          )}
        </Button>
        <Modal
          title={`Controlar notificações para a opção ${currentPosition.ticker}`}
          open={visible}
          onCancel={() => {
            setVisible(false);
            form.resetFields();
          }}
          onOk={() => onFormSubmit()}
          confirmLoading={mutationLoading}
        >
          <Form
            layout="vertical"
            form={form}
            initialValues={{
              positionNotificationFreezeMode:
                currentPosition.positionNotificationFreezeMode
                  ? currentPosition.positionNotificationFreezeMode
                  : PositionNotificationFreezeMode.ALL,
            }}
          >
            <Form.Item
              name="positionNotificationFreezeMode"
              label="Notificações de delta hedge"
              rules={[
                { required: true, message: "Por favor informe uma opção" },
              ]}
            >
              <Select>
                <Select.Option value={PositionNotificationFreezeMode.ALL}>
                  Todas as notificações
                </Select.Option>
                <Select.Option value={PositionNotificationFreezeMode.OFF}>
                  Nenhuma notificação
                </Select.Option>
                <Select.Option value={PositionNotificationFreezeMode.LIQUIDITY}>
                  Somente notificar quando o ativo voltar a ter liquidez
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        {contextHolder}
      </div>
    );
  }
  return null;
};

export default DhNotificationMode;
