import { useState } from "react";
import {
  Form,
  Button,
  message,
  Row,
  Col,
  Spin,
  InputNumber,
  Select,
  Input,
} from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { appApi } from "../../api";
import CollapsibleCard from "../../components/collapsibleCard";
import { PersonalizationParameter } from "../../api/models";

const PersonalizationPage = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  // load account details
  const { data, isLoading } = useQuery({
    queryKey: ["accountPersonalizationParameters"],
    queryFn: appApi.accounts.getPersonalizationParameters,
  });

  const categoriesList = data?.map((item) => item.category) || [];
  const categories = [...new Set(categoriesList)];

  const setParameterMutation = useMutation({
    mutationFn: (parameterValues: { key: string; value: any }[]) => {
      setLoading(true);
      return appApi.accounts.setPersonalizationParameters(parameterValues);
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ["accounts"] });
      await queryClient.invalidateQueries({
        queryKey: ["monthlyIncomeRecords"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["incomeChart"],
      });
      setLoading(false);
      message.success("Personalização atualizada com sucesso.");
    },
    onError: async () => {
      setLoading(false);
    },
  });

  const transformFormValuesIntoParameterValuesAndSubmit = (values: any) => {
    const parameterValues = Object.entries(values).map(([key, value]) => {
      return {
        key,
        value,
      };
    });
    setParameterMutation.mutateAsync(parameterValues);
  };

  const renderFormItem = (item: PersonalizationParameter) => {
    const {
      key,
      label,
      defaultValue,
      userDefinedValue,
      type,
      description,
      choices,
      visible,
    } = item;

    if (!visible) {
      return null;
    }
    const value =
      userDefinedValue !== undefined && userDefinedValue !== null
        ? userDefinedValue
        : defaultValue;
    return (
      <Form.Item
        key={key}
        name={key}
        label={label || key}
        initialValue={value}
        tooltip={description}
      >
        {type === "choices" && (
          <Select style={{ width: "100%" }}>
            {choices.map((choice) => (
              <Select.Option key={choice} value={choice}>
                {choice}
              </Select.Option>
            ))}
          </Select>
        )}
        {(type === "integer" || type === "decimal") && (
          <InputNumber
            precision={type === "decimal" ? 2 : 0}
            style={{ width: "100%" }}
            min={item.lowerLimit || undefined}
            max={item.upperLimit || undefined}
          />
        )}
        {type === "boolean" && (
          <Select className="w-full">
            <Select.Option value={true}>Sim</Select.Option>
            <Select.Option value={false}>Não</Select.Option>
          </Select>
        )}
        {type === "string" && <Input style={{ width: "100%" }} />}
      </Form.Item>
    );
  };

  if (isLoading) {
    return <Spin />;
  }

  return (
    <>
      <Row>
        <Col xs={24}>
          <CollapsibleCard title="Personalização" collapsible={false}>
            <Form
              form={form}
              onFinish={transformFormValuesIntoParameterValuesAndSubmit}
              layout="horizontal"
              style={{
                maxWidth: 500,
                width: "100%",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {categories.map((categoryItem) => (
                <div
                  key={`category-container-${categoryItem}`}
                  className="w-full"
                >
                  <h3 key={categoryItem}>{categoryItem}</h3>
                  {data?.map((item: PersonalizationParameter) => {
                    if (categoryItem == item.category) {
                      return renderFormItem(item);
                    } else {
                      return null;
                    }
                  })}
                </div>
              ))}
              <Form.Item className="w-full">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading || isLoading}
                  style={{ alignSelf: "flex-start" }}
                >
                  Salvar
                </Button>
              </Form.Item>
            </Form>
          </CollapsibleCard>
        </Col>
      </Row>
    </>
  );
};

export default PersonalizationPage;
