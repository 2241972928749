import { Button, message, Tooltip } from "antd";
import { RobotOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { appApi } from "../../../api";
import { WALLET_QUERY_KEYS } from "../constants";
import { WalletNames } from "../../../api/models";
import useScreenSize from "../../../layouts/useScreenSize";

const AutoGenerateStrategiesButton = ({
  walletName,
}: {
  walletName: WalletNames;
}) => {
  const queryClient = useQueryClient();
  const positionsKey = ["currentPositions"];
  const [mutationLoading, setMutationLoading] = useState(false);
  const { isMobile } = useScreenSize();

  const mutation = useMutation({
    mutationFn: () => {
      const payload = {
        walletName: walletName,
      };
      setMutationLoading(true);
      return appApi.strategyBundle.autoCreateBundles(payload);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: positionsKey });
      WALLET_QUERY_KEYS.forEach(async (key: any) => {
        queryClient.invalidateQueries({ queryKey: [key] });
      });
      setMutationLoading(false);
      message.success("Estratégias geradas com sucesso!");
    },
    onError: async () => {
      setMutationLoading(false);
    },
  });

  return (
    <Tooltip
      title={
        "Gera agrupamentos de estratégia para melhor visualização das opções da carteira conforme as estratégias pré definidas da FIA."
      }
    >
      <Button
        icon={<RobotOutlined />}
        type={isMobile ? "text" : "default"}
        onClick={() => mutation.mutateAsync()}
        className="ml-2"
        loading={mutationLoading}
      >
        {isMobile ? "" : "Gerar Estratégias"}
      </Button>
    </Tooltip>
  );
};

export default AutoGenerateStrategiesButton;
