import { Tabs } from "antd";
import type { TabsProps } from "antd";
import CurrentPositionsTab from "./CurrentPositions/currentPositions";
import OperationsHistory from "./operationsHistory";
import PositionEventHistory from "./positionEventHistory";
import { useSearchParams } from "react-router-dom";
import OperationsImport from "./importOperations";
import SyncPositions from "./syncPositions";

export enum TabKeys {
  CURRENT_POSITIONS = "current_positions",
  VALUE = "value",
  INCOME = "income",
  DEFENSIVE = "defensive",
  PICTURES = "pictures",
  INTERNATIONAL = "international",
  TRANSACTIONS = "transactions",
  EVENTS = "events",
  FIIS = "fiis",
  FIAGROS = "fiagros",
  IMPORT_OPERATIONS = "import_operations",
  SYNC_POSITIONS = "sync_positions",
}

const items: TabsProps["items"] = [
  {
    key: TabKeys.CURRENT_POSITIONS,
    label: "Posições",
    children: <CurrentPositionsTab />,
  },
  {
    key: TabKeys.SYNC_POSITIONS,
    label: "Sincronizar posições",
    children: <SyncPositions />,
  },
  {
    key: TabKeys.IMPORT_OPERATIONS,
    label: "Importar Operações",
    children: <OperationsImport />,
  },
  {
    key: TabKeys.TRANSACTIONS,
    label: "Histórico de Operações",
    children: <OperationsHistory />,
  },
  {
    key: TabKeys.EVENTS,
    label: "Eventos de Posições",
    children: <PositionEventHistory />,
  },
];

const WalletPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab") || "current_positions";
  let selectedTab = "current_positions";
  if (Object.values(TabKeys).includes(tab as TabKeys)) {
    selectedTab = tab;
  }

  return (
    <Tabs
      defaultActiveKey={selectedTab}
      items={items}
      onChange={(activeKey) => setSearchParams({ tab: activeKey })}
    />
  );
};

export default WalletPage;
