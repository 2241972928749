import { SetStateAction } from "react";
import type { ListResponse } from "../../api/types";
import ResponsiveTable, {
  EnhancedTableColumnType,
} from "../../components/ResponsiveTable";
import PriceDisplay from "../../components/priceDisplay";

const wallets = [
  {
    label: "Ações",
    dataIndex: "ações",
  },
  {
    label: "Internacional",
    dataIndex: "internacional",
  },
  {
    label: "Renda",
    dataIndex: "renda",
  },
  {
    label: "Carrego",
    dataIndex: "carrego",
  },
  {
    label: "Figuras",
    dataIndex: "figuras",
  },
  {
    label: "FIIs",
    dataIndex: "fundosImobiliários",
  },
  {
    label: "Fiagros",
    dataIndex: "fundosDoAgronegócio",
  },
  {
    label: "Outros",
    dataIndex: "outros",
  },
];

enum CamelCaseWalletNames {
  acoes = "ações",
  internacional = "internacional",
  renda = "renda",
  carrego = "carrego",
  figuras = "figuras",
  fundosImobiliarios = "fundosImobiliários",
  fundosDoAgronegocio = "fundosDoAgronegócio",
  outros = "outros",
}

// editable column dataIndex map
const EditableColumnDataIndexMap = {
  ações: "acoes",
  internacional: "internacional",
  renda: "renda",
  carrego: "carrego",
  figuras: "figuras",
  fundosImobiliários: "fundosImobiliarios",
  fundosDoAgronegócio: "fundosDoAgronegocio",
  outros: "outros",
};

const generateWalletsPNLColumns = () => {
  return wallets.map((wallet) => {
    const column: EnhancedTableColumnType<any> = {
      title: wallet.label,
      dataIndex:
        EditableColumnDataIndexMap[wallet.dataIndex as CamelCaseWalletNames],
      render: (_: any, record: any) => {
        if (wallet.dataIndex) {
          if (record.wallets[wallet.dataIndex]) {
            return (
              <div className="flex flex-row">
                <PriceDisplay
                  price={record.wallets[wallet.dataIndex].pnlResult}
                  hasOverride={
                    record.wallets[wallet.dataIndex].overridenPnlResult !==
                      null &&
                    record.wallets[wallet.dataIndex].overridenPnlResult !==
                      undefined
                  }
                />
              </div>
            );
          }
          return null;
        }
        return null;
      },
    };
    return column;
  });
};

const PNLRecords = ({
  data,
  isFetching,
  page,
  setPage,
  additionalColumns,
  columnsAfterWallets,
}: {
  data: ListResponse<any>;
  isFetching: boolean;
  page: number;
  setPage: (value: SetStateAction<number>) => void;
  additionalColumns: any;
  columnsAfterWallets: any;
}) => {
  const walletColumns = generateWalletsPNLColumns();

  let columns = [
    ...additionalColumns,
    {
      title: "PNL do período",
      dataIndex: "pnlResult",
      render: (value: string) => <PriceDisplay price={value} />,
    },
    {
      title: "PNL Acumulado",
      dataIndex: "accumulatedPnlResult",
      render: (value: string) => <PriceDisplay price={value} />,
    },
  ];

  columns = [...columns, ...walletColumns];

  if (columnsAfterWallets) {
    columns = [...columns, ...columnsAfterWallets];
  }

  return (
    <ResponsiveTable
      dataSource={data?.results}
      columns={columns}
      loading={isFetching}
      className="w-full"
      size="small"
      pagination={{
        current: page,
        onChange: (page) => {
          setPage(page);
        },
        total: data?.count,
        pageSize: 10,
        showSizeChanger: false,
      }}
      rowKey="id"
    />
  );
};

export default PNLRecords;
