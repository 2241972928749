import { WalletNames } from "../../api/models";

export const WALLET_QUERY_KEYS = [
  "operationsHistory",
  "wallets",
  "currentPositions",
  "positionEvents",
  ["wallets", WalletNames.DEFENSIVE],
  ["wallets", WalletNames.VALUE],
  ["wallets", WalletNames.FIIS],
  ["wallets", WalletNames.FIAGROS],
  ["wallets", WalletNames.INCOME],
  ["wallets", WalletNames.INTERNATIONAL],
  ["wallets", WalletNames.PICTURES],
];
