import { User } from "../api/models";

const USER_LOCAL_STORAGE_KEY = "USER-DATA";
const ACCESS_TOKEN_KEY = "ACCESS-TOKEN";
const PREFERRED_PNL_VISUALIZATION = "PREFERRED-PNL-VISUALIZATION";

export function saveUser(user: User): void {
  localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(user));
}

export function getUser(): User | undefined {
  const user = localStorage.getItem(USER_LOCAL_STORAGE_KEY);
  return user ? JSON.parse(user) : undefined;
}

export function removeUser(): void {
  localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
}

export function saveToken(token: string): void {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
}

export function getToken(): string | null {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export function removeToken(): void {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
}

type PnlVisualization = {
  period: string;
  displayedBarMode: "totalized" | "detailed" | "daily";
  detailPeriod: string;
};

export function getPreferredPnlVisualization(): PnlVisualization | undefined {
  const string_stored = localStorage.getItem(PREFERRED_PNL_VISUALIZATION);
  return string_stored ? JSON.parse(string_stored) : undefined;
}

export function savePreferredPnlVisualization(
  preferredPnlVisualization: PnlVisualization,
): void {
  localStorage.setItem(
    PREFERRED_PNL_VISUALIZATION,
    JSON.stringify(preferredPnlVisualization),
  );
}

export default {
  saveUser,
  getUser,
  removeUser,
  saveToken,
  getToken,
  removeToken,
};
