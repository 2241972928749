import { request } from "./request";
import { JSONResponse, ListResponse } from "./types";
import {
  Account,
  Communication,
  CurrentPosition,
  LoginResponse,
  OptionsMonitor,
  PositionEvent,
  StockOperation,
  User,
  Wallet,
  WalletNames,
  WalletDailyResult,
  WalletMonthlyResult,
  WalletYearlyResult,
  AccountDailyResult,
  AccountMonthlyResult,
  AccountYearlyResult,
  AccountMonthlyIncome,
  AccountMonthlyIncomeEntry,
  PnlChartData,
  WalletAllocations,
  IncomeChartData,
  OpenOptionsData,
  PersonalizationParameter,
  StockBulkOperationValidationError,
  StockOperationCreationPayload,
  B3Instrument,
  TickerPrice,
  StockPriceHistory,
  StockDividendHistory,
  StockSplitHistory,
  StockBonificationHistory,
  BlackAndScholesCalculatorResponse,
  PositionSyncRequest,
  StockBulkOperationGenerationResponse,
  CashflowResult,
  PositionNotificationFreezeMode,
  OptionsExerciseData,
  SimulationDataParams,
  AccountSimulationData,
  CalendarItem,
} from "./models";

export const appApi = {
  authentication: {
    login: async (params: { email: string; password: string }) => {
      const { data } = await request.post<JSONResponse<LoginResponse>>(
        "/auth/login/",
        params,
      );
      return data;
    },
    logout: async () => {
      const { data } = await request.post("/auth/logout/");
      return data;
    },
    refreshUser: async () => {
      const { data } = await request.get<JSONResponse<User>>("/auth/user/");
      return data;
    },
    passwordReset: async (params: { email: string }) => {
      const { data } = await request.post("/auth/password-reset/", params);
      return data;
    },
    passwordChange: async (params: {
      newPassword1: string;
      newPassword2: string;
    }) => {
      const { data } = await request.post("/auth/password/change/", params);
      return data;
    },
    loginAsUser: async (params: { userId: string; token: string }) => {
      const { data } = await request.post("/user/login_as_user/", params);
      return data;
    },
  },
  resources: {
    getInstruments: async (
      ticker?: string,
      isExactMatchSearch?: boolean,
      optionType?: string,
      category?: string,
      strikeGreaterThan?: number,
      strikeLessThan?: number,
      relatedAsset?: string,
      pageSize?: number,
      page?: number,
    ) => {
      let url = `/resources/b3_instruments/?page=${page || 1}&page_size=${
        pageSize || 10
      }`;
      if (ticker && isExactMatchSearch) {
        url += `&exact_ticker=${ticker}`;
      }
      if (ticker && !isExactMatchSearch) {
        url += `&ticker=${ticker}`;
      }
      if (optionType) {
        url += `&option_type=${optionType}`;
      }
      if (category) {
        url += `&category=${category}`;
      }
      if (strikeGreaterThan) {
        url += `&strike_greater_than=${strikeGreaterThan}`;
      }
      if (strikeLessThan) {
        url += `&strike_less_than=${strikeLessThan}`;
      }
      if (relatedAsset) {
        url += `&related_asset=${relatedAsset}`;
      }
      if (pageSize) {
        url += `&page_size=${pageSize}`;
      }
      const { data } = await request.get<ListResponse<B3Instrument>>(url);
      return data;
    },
    getPrice: async (ticker: string) => {
      const { data } = await request.get<ListResponse<TickerPrice>>(
        `/resources/ticker_prices/?ticker=${ticker}`,
      );
      return data;
    },
    getHistoricalPrices: async (
      ticker: string,
      page?: number,
      pageSize?: number,
    ) => {
      const { data } = await request.get<ListResponse<StockPriceHistory>>(
        `/resources/stock_price_history/?ticker=${ticker}&page=${
          page || 1
        }&page_size=${pageSize || 10}`,
      );
      return data;
    },
    getHistoricalDividends: async (
      ticker: string,
      page?: number,
      pageSize?: number,
    ) => {
      const { data } = await request.get<ListResponse<StockDividendHistory>>(
        `/resources/stock_dividends_history/?ticker=${ticker}&page=${
          page || 1
        }&page_size=${pageSize || 10}`,
      );
      return data;
    },
    getHistoricalSplits: async (
      ticker: string,
      page?: number,
      pageSize?: number,
    ) => {
      const { data } = await request.get<ListResponse<StockSplitHistory>>(
        `/resources/stock_split_history/?ticker=${ticker}&page=${
          page || 1
        }&page_size=${pageSize || 10}`,
      );
      return data;
    },
    getHistoricalBonifications: async (
      ticker: string,
      page?: number,
      pageSize?: number,
    ) => {
      const { data } = await request.get<
        ListResponse<StockBonificationHistory>
      >(
        `/resources/stock_bonification_history/?ticker=${ticker}&page=${
          page || 1
        }&page_size=${pageSize || 10}`,
      );
      return data;
    },
    getCalendar: async () => {
      const { data } = await request.get<ListResponse<CalendarItem>>(
        "resources/calendar/?next_days=90",
      );
      return data;
    },
  },
  accounts: {
    getList: async () => {
      const { data } =
        await request.get<ListResponse<Account>>("/user/accounts/");
      return data;
    },
    update: async (params: {
      id: string;
      accountNumber: string;
      lastOperationsSyncDate: string | null;
    }) => {
      const { data } = await request.patch(
        `/user/accounts/${params.id}/`,
        params,
      );
      return data;
    },
    getPersonalizationParameters: async () => {
      const { data } = await request.get<PersonalizationParameter[]>(
        "/user/personalization_parameters/",
      );
      return data;
    },
    setPersonalizationParameters: async (
      params: {
        key: string;
        value: string;
      }[],
    ) => {
      const { data } = await request.post(
        "/user/personalization_parameters/set_parameters/",
        params,
      );
      return data;
    },
    resetAllParameters: async () => {
      const { data } = await request.post(
        "/user/personalization_parameters/reset/",
      );
      return data;
    },
  },
  communications: {
    getList: async (type?: string, pageSize?: number) => {
      let url = `/user/communications/`;
      if (type) {
        url += `?type=${type}`;
      }
      if (pageSize) {
        url += type ? `&page_size=${pageSize}` : `?page_size=${pageSize}`;
      }
      const { data } = await request.get<ListResponse<Communication>>(url);
      return data;
    },
    update: async (params: { id: string; read: boolean }) => {
      const { data } = await request.patch(
        `/user/communications/${params.id}/`,
        params,
      );
      return data;
    },
    markAllAsRead: async () => {
      const { data } = await request.post(
        `/user/communications/mark_all_as_read/`,
      );
      return data;
    },
  },
  wallets: {
    getWalletAllocations: async () => {
      const { data } = await request.get<WalletAllocations>(
        "/wallet/wallet_allocations/",
      );
      return data;
    },
    getAccountOpenOptionsPositions: async () => {
      const { data } = await request.get<OpenOptionsData>(
        "/wallet/account_open_options_positions/",
      );
      return data;
    },
    getList: async (
      walletName: WalletNames | null | undefined,
      type?: string,
    ) => {
      let url = "/wallet/wallets/";
      if (walletName) {
        url += `?name=${walletName}`;
      }
      if (type) {
        url += walletName ? `&type=${type}` : `?type=${type}`;
      }
      const { data } = await request.get<ListResponse<Wallet>>(url);
      return data;
    },
    update: async (params: {
      id: string;
      percentageTargeted?: number;
      walletParameters?: any;
    }) => {
      const { data } = await request.patch(
        `/wallet/wallets/${params.id}/`,
        params,
      );
      return data;
    },
    updateItem: async (params: {
      id: string;
      amountOverride?: number;
      wallet?: string;
    }) => {
      const { data } = await request.patch(
        `/wallet/wallet_items/${params.id}/`,
        params,
      );
      return data;
    },
    calculateWalletDetails: async (params: {
      walletId: string;
      percentageTargeted: number;
    }) => {
      const { data } = await request.post(
        `/wallet/wallets/${params.walletId}/calculate_wallet_details/`,
        {
          simulation_percentage: params.percentageTargeted,
        },
      );
      return data;
    },
  },
  currentPositions: {
    getList: async (
      page: number,
      ticker?: string | null,
      pageSize?: number | null,
      sortColumn?: string | null,
      sortOrder?: string | null,
    ) => {
      let url = `/wallet/current_positions/?page=${page}`;
      if (ticker) {
        url += `&ticker=${ticker}`;
      }
      if (pageSize) {
        url += `&page_size=${pageSize}`;
      }
      if (sortColumn) {
        url += `&sort_by=${sortColumn}`;
      }
      if (sortOrder) {
        url += `&sort_order=${sortOrder}`;
      }
      const { data } = await request.get<ListResponse<CurrentPosition>>(url);
      return data;
    },
    create: async (params: {
      id: string;
      amount: number;
      lastPrice: number;
      averagePrice?: number;
      ticker: string;
      walletId: string;
    }) => {
      const { data } = await request.post(`/wallet/current_positions/`, params);
      return data;
    },
    update: async (params: {
      id: string;
      optionLockTicker?: string | null;
      positionNotificationFreezeMode?: PositionNotificationFreezeMode | null;
      lastPrice?: number;
      averagePrice?: number;
      newWalletName?: string;
    }) => {
      const { data } = await request.patch(
        `/wallet/current_positions/${params.id}/`,
        params,
      );
      return data;
    },
    splitPosition: async (params: {
      id: string;
      splitAmount: number;
      walletName: string;
    }) => {
      const { data } = await request.post(
        `/wallet/current_positions/${params.id}/split_position/`,
        params,
      );
      return data;
    },
    getExerciseOptions: async (
      expirationDate?: string,
      baseTicker?: string,
      walletName?: string,
    ) => {
      let url = `/wallet/account_options_exercise/?`;
      if (expirationDate) {
        url += `expiration_date=${expirationDate}`;
      }
      if (baseTicker) {
        url += expirationDate
          ? `&base_ticker=${baseTicker}`
          : `base_ticker=${baseTicker}`;
      }
      if (walletName) {
        url +=
          expirationDate || baseTicker
            ? `&wallet_name=${walletName}`
            : `wallet_name=${walletName}`;
      }
      const { data } = await request.get<OptionsExerciseData>(url);
      return data;
    },
  },
  strategyBundle: {
    create_or_update: async (params: {
      strategy: string;
      description?: string | null;
      items: { position: string }[];
      baseTicker?: string | null;
      id?: string | null;
      wallet?: string;
    }) => {
      const { data } = await request.post(
        `/wallet/position_strategy_bundles/`,
        params,
      );
      return data;
    },
    delete: async (id: string) => {
      const { data } = await request.delete(
        `/wallet/position_strategy_bundles/${id}/`,
      );
      return data;
    },
    autoCreateBundles: async (params: { walletName: string }) => {
      const { data } = await request.post(
        `/wallet/position_strategy_bundles/auto_create_bundles/`,
        params,
      );
      return data;
    },
  },
  stockOperations: {
    getList: async (
      page: number,
      ticker?: string | null,
      pageSize?: number | null,
      sortColumn?: string | null,
      sortOrder?: string | null,
    ) => {
      let url = `/wallet/stock_operations/?page=${page}`;
      if (ticker) {
        url += `&ticker=${ticker}`;
      }
      if (pageSize) {
        url += `&page_size=${pageSize}`;
      }
      if (sortColumn) {
        url += `&sort_by=${sortColumn}`;
      }
      if (sortOrder) {
        url += `&sort_order=${sortOrder}`;
      }
      const { data } = await request.get<ListResponse<StockOperation>>(url);
      return data;
    },
    create: async (params: StockOperationCreationPayload) => {
      const { data } = await request.post(`/wallet/stock_operations/`, {
        operations: [params],
        isFrontEnd: true,
      });
      return data;
    },
    bulkCreate: async (operations: StockOperationCreationPayload[]) => {
      const { data } = await request.post(`/wallet/stock_operations/`, {
        operations,
      });
      return data;
    },
    delete: async ({ id }: { id: string }) => {
      const { data } = await request.delete(`/wallet/stock_operations/${id}/`);
      return data;
    },
    validateOperations: async ({
      params,
    }: {
      params: StockOperationCreationPayload[];
    }) => {
      const { data } = await request.post<StockBulkOperationValidationError[]>(
        `/wallet/stock_operations/validate_operations/`,
        params,
      );
      return data;
    },
    walletsSyncGenerateOperations: async ({
      params,
    }: {
      params: PositionSyncRequest[];
    }) => {
      const { data } = await request.post<StockBulkOperationGenerationResponse>(
        `/wallet/stock_operations/wallets_sync_generate_operations/`,
        params,
      );
      return data;
    },
  },
  positionEvent: {
    getList: async (
      page: number,
      ticker?: string | null,
      pageSize?: number | null,
      sortColumn?: string | null,
      sortOrder?: string | null,
    ) => {
      let url = `/wallet/position_events/?page=${page}`;
      if (pageSize) {
        url += `&page_size=${pageSize}`;
      }
      if (ticker) {
        url += `&ticker=${ticker}`;
      }
      if (pageSize) {
        url += `&page_size=${pageSize}`;
      }
      if (sortColumn) {
        url += `&sort_by=${sortColumn}`;
      }
      if (sortOrder) {
        url += `&sort_order=${sortOrder}`;
      }
      const { data } = await request.get<ListResponse<PositionEvent>>(url);
      return data;
    },
    applyEvent: async ({ id }: { id: string }) => {
      const { data } = await request.post(
        `/wallet/position_events/${id}/apply_event/`,
      );
      return data;
    },
    revertEvent: async ({ id }: { id: string }) => {
      const { data } = await request.post(
        `/wallet/position_events/${id}/revert_event/`,
      );
      return data;
    },
  },
  blackAndScholesCalculator: {
    get: async (
      assetPrice: number,
      optionType: string,
      volatility: number,
      strike: number,
      businessDaysToExpiration: number,
      optionBlackScholesPrice: number,
    ) => {
      let url = `/options_income/black_and_scholes_calculator/?`;
      url += `asset_price=${assetPrice}`;
      url += `&option_type=${optionType}`;
      url += `&strike=${strike}`;
      url += `&bus_days_to_expiration=${businessDaysToExpiration}`;
      if (volatility) url += `&input_volatility=${volatility}`;
      if (optionBlackScholesPrice)
        url += `&option_price=${optionBlackScholesPrice}`;
      const { data } =
        await request.get<BlackAndScholesCalculatorResponse>(url);
      return data;
    },
  },
  optionsMonitor: {
    get: async ({
      ticker,
      expirationDate,
      volatility,
      price,
      optionsType,
      strikes,
      upperDelta,
      lowerDelta,
    }: {
      ticker: string;
      expirationDate: string;
      volatility?: number;
      price?: number;
      optionsType?: string;
      strikes?: number;
      upperDelta?: number;
      lowerDelta?: number;
    }) => {
      let url = `/options_income/options_monitor_builder/?base_ticker=${ticker}&expiration_date=${expirationDate}`;
      if (volatility) {
        url += `&input_volatility=${volatility}`;
      }
      if (price) {
        url += `&base_ticker_price=${price}`;
      }
      if (optionsType) {
        url += `&options_type=${optionsType}`;
      }
      if (strikes) {
        url += `&strikes=${strikes}`;
      }
      if (upperDelta) {
        url += `&upper_delta=${upperDelta}`;
      }
      if (lowerDelta) {
        url += `&lower_delta=${lowerDelta}`;
      }
      const { data } = await request.get<OptionsMonitor>(url);
      return data;
    },
  },
  optionMonthlyExpirationDates: {
    get: async ({
      maxDaysDistance,
      minDaysDistance,
    }: {
      maxDaysDistance?: number;
      minDaysDistance?: number;
    }) => {
      let url = `/options_income/option_expiration_dates/`;
      if (maxDaysDistance || minDaysDistance) {
        url += `?`;
      }
      if (maxDaysDistance) {
        url += `max_days_distance=${maxDaysDistance}`;
      }
      if (minDaysDistance) {
        url += maxDaysDistance
          ? `&min_days_distance=${minDaysDistance}`
          : `min_days_distance=${minDaysDistance}`;
      }
      const { data } = await request.get<string[]>(url);
      return data;
    },
  },
  pnl: {
    getPnlChartData: async (period: string, periodCount: number) => {
      let url = `/pnl/pnl_chart_data/?period=${period}&period_count=${periodCount}`;
      const { data } = await request.get<PnlChartData[]>(url);
      return data;
    },
    getIncomeChartData: async (periodCount: number) => {
      let url = `/pnl/monthly_income_chart_data/?period_count=${periodCount}`;
      const { data } = await request.get<IncomeChartData[]>(url);
      return data;
    },
    getWalletDailyPNL: async (
      page: number,
      date: string,
      walletId?: string,
    ) => {
      let url = "/pnl/wallet_daily/?page=" + page;
      if (walletId) {
        url += `&wallet=${walletId}`;
      }
      if (date) {
        url += `&date=${date}`;
      }
      const { data } = await request.get<ListResponse<WalletDailyResult>>(url);
      return data;
    },
    getWalletMonthlyPNL: async (walletId: string, page: number) => {
      let url = "/pnl/wallet_monthly/?page=" + page;
      if (walletId) {
        url += `&wallet=${walletId}`;
      }
      const { data } =
        await request.get<ListResponse<WalletMonthlyResult>>(url);
      return data;
    },
    getWalletYearlyPNL: async (walletId: string, page: number) => {
      let url = "/pnl/wallet_yearly/?page=" + page;
      if (walletId) {
        url += `&wallet=${walletId}`;
      }
      const { data } = await request.get<ListResponse<WalletYearlyResult>>(url);
      return data;
    },
    getAccountDailyPNL: async (
      page: number,
      year?: string | null,
      month?: string | null,
    ) => {
      let url = "/pnl/account_daily/?page=" + page;
      if (year) {
        url += `&year=${year}`;
      }
      if (month) {
        url += `&month=${month}`;
      }
      const { data } = await request.get<ListResponse<AccountDailyResult>>(url);
      return data;
    },
    getCashflow: async () => {
      const { data } = await request.get<CashflowResult>(
        "/pnl/account_cashflow/",
      );
      return data;
    },
    getAccountMonthlyPNL: async (page: number, year?: string | null) => {
      let url = "/pnl/account_monthly/?page=" + page;
      if (year) {
        url += `&year=${year}`;
      }
      const { data } =
        await request.get<ListResponse<AccountMonthlyResult>>(url);
      return data;
    },
    getAccountYearlyPNL: async (page: number) => {
      const { data } = await request.get<ListResponse<AccountYearlyResult>>(
        "/pnl/account_yearly/?page=" + page,
      );
      return data;
    },
    getAccountMonthlyIncome: async (page: number) => {
      const { data } = await request.get<ListResponse<AccountMonthlyIncome>>(
        "/pnl/account_monthly_income/?page=" + page,
      );
      return data;
    },
    createAccountMonthlyIncome: async (params: {
      year: number | string;
      month: number | string;
      overridenDividendsIncome?: string | null;
      overridenOptionsIncome?: string | null;
      overridenMiniIndexIncome?: string | null;
    }) => {
      const { data } = await request.post(
        "/pnl/account_monthly_income/",
        params,
      );
      return data;
    },
    getAccountMonthlyIncomeEntries: async (
      page: number,
      year: string,
      month: string,
      type?: string,
      asset?: string | null,
    ) => {
      let url = `/pnl/account_monthly_income_entries/?page=${page}&year=${year}&month=${month}`;
      if (type) {
        url += `&entry_type=${type}`;
      }
      if (asset) {
        url += `&asset=${asset}`;
      }
      const { data } =
        await request.get<ListResponse<AccountMonthlyIncomeEntry>>(url);
      return data;
    },
    overrideAccountMonthlyIncomeEntry: async (params: {
      id: string;
      overridenIncome: string;
    }) => {
      const { data } = await request.post(
        `/pnl/account_monthly_income_entries/${params.id}/override_entry/`,
        params,
      );
      return data;
    },
    recalculateAccountResult: async (id: string) => {
      const { data } = await request.post(
        `/pnl/account_daily/${id}/recalculate_account_result/`,
      );
      return data;
    },
    ignoreRecord: async (id: string) => {
      const { data } = await request.post(
        `/pnl/account_daily/${id}/ignore_daily_account_result/`,
      );
      return data;
    },
    unignoreRecord: async (id: string) => {
      const { data } = await request.post(
        `/pnl/account_daily/${id}/unignore_daily_account_result/`,
      );
      return data;
    },
    overrideDailyPnlWalletResults: async (params: {
      date: string;
      walletResults: {
        [key: string]: number | null;
      };
    }) => {
      const { data } = await request.post(
        `/pnl/account_daily/override_daily_pnl_wallet_results/`,
        params,
      );
      return data;
    },
    overrideMonthlyPnlWalletResults: async (params: {
      year: string | number;
      month: string | number;
      walletResults: {
        [key: string]: number | null;
      };
    }) => {
      const { data } = await request.post(
        `/pnl/account_monthly/override_monthly_pnl_wallet_results/`,
        params,
      );
      return data;
    },
  },
  simulation: {
    list: async () => {
      const { data } = await request.get<AccountSimulationData>(
        "/wallet/scenario_simulator/",
      );
      return data;
    },
    simulate: async (params: SimulationDataParams) => {
      const { data } = await request.post<AccountSimulationData>(
        "/wallet/scenario_simulator/",
        params,
      );
      return data;
    },
  },
};
