import { Tabs } from "antd";
import type { TabsProps } from "antd";
import ValueWallet from "./valueWallet";
import InternationalWallet from "./internationalWallet";
import FiisWallet from "./fiisWallet";
import FiagrosWallet from "./FiagrosWallet";
import { useSearchParams } from "react-router-dom";
import OthersWallet from "./othersWallet";

export enum TabKeys {
  VALUE = "value",
  INTERNATIONAL = "international",
  TRANSACTIONS = "transactions",
  FIIS = "fiis",
  FIAGROS = "fiagros",
  OTHERS = "others",
}

const items: TabsProps["items"] = [
  {
    key: TabKeys.VALUE,
    label: "Ações",
    children: <ValueWallet />,
  },
  {
    key: TabKeys.FIIS,
    label: "Fundos imobiliários",
    children: <FiisWallet />,
  },
  {
    key: TabKeys.FIAGROS,
    label: "Fundos do agronegócio",
    children: <FiagrosWallet />,
  },
  {
    key: TabKeys.INTERNATIONAL,
    label: "Internacional",
    children: <InternationalWallet />,
  },
  {
    key: TabKeys.OTHERS,
    label: "Outros",
    children: <OthersWallet />,
  },
];

const WalletPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab") || "current_positions";
  let selectedTab = TabKeys.VALUE;
  if (Object.values(TabKeys).includes(tab as TabKeys)) {
    selectedTab = tab as TabKeys;
  }

  return (
    <Tabs
      defaultActiveKey={selectedTab}
      items={items}
      onChange={(activeKey) => setSearchParams({ tab: activeKey })}
    />
  );
};

export default WalletPage;
