import { WalletNames } from "../../../api/models";
import ValueWalletTemplate from "./valueWalletTemplate";

const OthersWallet = () => {
  return (
    <ValueWalletTemplate
      walletName={WalletNames.OTHERS}
      title="Outros ativos"
      descriptionTwoTitle="Orientações"
      descriptionTwo={
        <>
          <p>
            Carteira para agrupar ativos que não façam parte de nenhuma outra
            estratégia mapeada.
          </p>
        </>
      }
    />
  );
};

export default OthersWallet;
