import "chart.js/auto";
import { IncomeChartData } from "../../api/models";
import { appApi } from "../../api";
import { Form, Select, Alert } from "antd";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { useQuery } from "@tanstack/react-query";
import CollapsibleCard from "../../components/collapsibleCard";
import { useState, useEffect } from "react";

ChartJS.register(ArcElement, Tooltip, Legend);

const monthslist = [12, 24, 36];

const IncomeChart = () => {
  const [detailPeriod, setDetailPeriod] = useState(30);

  const { data, isLoading } = useQuery<IncomeChartData[]>({
    queryKey: ["incomeChart", detailPeriod],
    queryFn: () => appApi.pnl.getIncomeChartData(detailPeriod),
  });

  // subscribing to the visibility changed event and re-rendering the chart whenever the tab transitions to visible
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        const chart = ChartJS.getChart("incomeChart");
        if (chart) {
          chart.update();
        }
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  const chartData = {
    labels: data ? data.map((item) => item.date) : [],
    datasets: [
      {
        label: "Renda com opções",
        data: data ? data.map((item) => item.details.optionsIncome) : [],
        backgroundColor: "#34eb8f",
      },
      {
        label: "Renda com dividendos",
        data: data ? data.map((item) => item.details.dividendsIncome) : [],
        backgroundColor: "#34c3eb",
      },
      {
        label: "Renda com mini índice",
        data: data ? data.map((item) => item.details.miniIndexIncome) : [],
        backgroundColor: "#ebb434",
      },
    ],
  };

  return (
    <CollapsibleCard title={"Renda"} loading={isLoading}>
      <Form layout="inline" style={{ marginBottom: "20px" }}>
        <Form.Item
          name="periodCountMonths"
          label="Quantidade de meses"
          rules={[{ required: true }]}
          style={{ minWidth: 75 }}
          initialValue={monthslist[0]}
        >
          <Select
            onChange={(value) => setDetailPeriod(value)}
            value={detailPeriod}
          >
            {monthslist.map((value) => (
              <Select.Option key={value} value={value}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      {(!data && !isLoading) || data?.length === 0 ? (
        <Alert
          type="warning"
          message="Você ainda não possui nenhum resultado de PNL registrado."
        />
      ) : (
        <Bar data={chartData} options={options} id="incomeChart" />
      )}
    </CollapsibleCard>
  );
};

export default IncomeChart;
