import { OptionsMonitor, OptionsMonitorRecord } from "../../../api/models";
import { numberToPercentageDisplay } from "../../../services/utils";
import { formatDateTime } from "../../../services/dates";
import TickerDetailsLink from "../../../components/tickerDetailsLink";
import ResponsiveTable from "../../../components/ResponsiveTable";
import PriceDisplay from "../../../components/priceDisplay";
import PriceDetails from "../../../components/priceDetails";

const DHMonitorTable = ({
  optionsMonitor,
  isLoading,
}: {
  optionsMonitor?: OptionsMonitor;
  isLoading: boolean;
}) => {
  const records: OptionsMonitorRecord[] = optionsMonitor?.records || [];

  const columns = [
    {
      title: "Código",
      dataIndex: "ticker",
      key: "ticker",
      render: (ticker: string) => <TickerDetailsLink ticker={ticker} />,
    },
    {
      title: "Strike",
      dataIndex: "strike",
      key: "strike",
      render: (value: number) => <PriceDisplay price={value} />,
    },
    {
      title: "Último Preço",
      dataIndex: "lastPrice",
      key: "lastPrice",
      render: (_: number, record: OptionsMonitorRecord) => (
        <PriceDetails
          price={record.lastPrice}
          priceAsk={record.priceAsk}
          priceBid={record.priceBid}
          priceStatus={record.priceStatus}
          liquidityStatus={record.liquidityStatus}
          priceDate={record.lastPriceDate}
          showAsTooltip={true}
        />
      ),
    },
    {
      title: "Última Atualização de Preço",
      dataIndex: "lastPriceDate",
      key: "lastPriceDate",
      render: (value: string) => (value ? formatDateTime(value) : "N/D"),
    },
    {
      title: "Preço (BS)",
      dataIndex: "bsPrice",
      key: "bsPrice",
      render: (value: number) => <PriceDisplay price={value} />,
    },
    {
      title: "Delta",
      dataIndex: "deltaDisplay",
      key: "deltaDisplay",
    },
    {
      title: "Volatilidade",
      dataIndex: "volatility",
      key: "volatility",
      render: (value: number, record: OptionsMonitorRecord) =>
        value !== undefined ? (
          value && record.volatilityStatus !== "Mercado" ? (
            <span style={{ color: "red" }}>
              {numberToPercentageDisplay(value)}
            </span>
          ) : (
            numberToPercentageDisplay(value)
          )
        ) : (
          "N/D"
        ),
    },
  ];

  return (
    <ResponsiveTable
      dataSource={records}
      columns={columns}
      size="small"
      loading={isLoading}
      rowKey="ticker"
      pagination={false}
      priorityKeys={["strike", "deltaDisplay", "ticker"]}
    />
  );
};

export default DHMonitorTable;
