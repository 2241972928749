import {
  Descriptions,
  DescriptionsProps,
  Divider,
  List,
  Table,
  TableColumnType,
  TableProps,
} from "antd";
import useScreenSize from "../layouts/useScreenSize";
import { EditableCell } from "./editableTableCell";

type ResponsiveTableProps = TableProps<any> & {
  columnSize?: 24 | 16 | 12 | 8 | 6;
  priorityKeys?: string[];
  isEditingRow?: (record: any) => boolean;
};

export type EnhancedTableColumnType<T> = TableColumnType<T> & {
  editable?: boolean;
  inputType?: "number" | "text" | "select";
  selectOptions?: string[];
  inputProps?: any;
  editableInCurrentRow?: (record: T) => boolean;
  editableFieldInitialValue?: (record: T) => any;
};

const ResponsiveTable = ({
  columns,
  dataSource,
  columnSize,
  priorityKeys,
  isEditingRow,
  ...restProps
}: ResponsiveTableProps) => {
  const { isSmallMobile, isTablet, isDesktop } = useScreenSize();

  let displayedColumns: EnhancedTableColumnType<any>[] = [];
  let displayedDescriptionItems: TableColumnType<any>[] = [];

  let maxDisplayedColumns = isSmallMobile ? 2 : 3;
  if (isTablet) {
    maxDisplayedColumns = !columnSize || columnSize >= 12 ? 5 : 3;
  }
  if (isDesktop) {
    maxDisplayedColumns =
      !columnSize || columnSize == 24
        ? 15
        : columnSize >= 16
          ? 11
          : columnSize >= 12
            ? 7
            : columnSize >= 8
              ? 5
              : 3;
  }

  const shouldReorderColumns = columns
    ? maxDisplayedColumns < columns?.length
    : false;

  if (!shouldReorderColumns) {
    displayedColumns = columns || [];
  }

  // adiciona colunas de prioridade, seguindo sua ordem
  if (shouldReorderColumns && priorityKeys && priorityKeys.length > 0) {
    let priorityIndex = 0;
    while (
      displayedColumns.length < maxDisplayedColumns &&
      priorityIndex <= 3
    ) {
      const priorityKey = priorityKeys[priorityIndex];
      const priorityColumn = columns?.find(
        (column) => column.key === priorityKey,
      );
      if (priorityColumn) {
        displayedColumns.push(priorityColumn);
      }
      priorityIndex++;
    }
  }
  // adiciona restante das colunas caso as de prioridade nao tenham preenchido
  if (shouldReorderColumns && displayedColumns.length < maxDisplayedColumns) {
    columns?.forEach((column) => {
      if (
        (!priorityKeys ||
          !priorityKeys.includes(column.key?.toString() || "")) &&
        displayedColumns.length < maxDisplayedColumns
      ) {
        displayedColumns.push(column);
      }
    });
  }
  // adiciona restante das colunas nao presentes na lista de colunas exibidas como description items
  columns?.forEach((column) => {
    if (!displayedColumns.includes(column)) {
      displayedDescriptionItems.push(column);
    }
  });

  const buildDescriptionItem = (
    record: any,
    index: number,
    column: TableColumnType<any>,
  ) => {
    return {
      label: column.title as any,
      children: column.render
        ? column.render(
            column.dataIndex
              ? (record[column.dataIndex.toString()] as any)
              : "",
            record,
            index,
          )
        : record[
            column?.dataIndex?.toString() || column?.key?.toString() || "id"
          ] || "",
    };
  };

  const renderExpandedChildItem = (record: any, index: number) => {
    const descriptionItems: DescriptionsProps["items"] = columns?.map(
      (column) => {
        return buildDescriptionItem(record, index, column);
      },
    ) as any;
    return (
      <div>
        <Divider />
        <Descriptions items={descriptionItems} />
      </div>
    );
  };

  const expandedContent = (record: any, index: number) => {
    if (!record.children) {
      const descriptionItems: DescriptionsProps["items"] =
        displayedDescriptionItems.map((column) => {
          return buildDescriptionItem(record, index, column);
        }) as any;
      return <Descriptions items={descriptionItems} />;
    } else {
      const descriptionItems: DescriptionsProps["items"] =
        displayedDescriptionItems.map((column) => {
          return buildDescriptionItem(record, index, column);
        }) as any;
      return (
        <List
          header={<Descriptions items={descriptionItems} />}
          dataSource={record.children}
          renderItem={renderExpandedChildItem}
          rowKey={(item) => item.id}
        />
      );
    }
  };

  const mergedColumns = displayedColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.inputType ? col.inputType : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        inputProps: col.inputProps,
        editing: isEditingRow ? isEditingRow(record) : false,
        selectOptions: col.selectOptions,
        editableFieldInitialValue: col.editableFieldInitialValue
          ? col.editableFieldInitialValue(record)
          : undefined,
        currentRowEditing: col.editableInCurrentRow
          ? col.editableInCurrentRow(record)
          : true,
      }),
    };
  });

  return (
    <Table
      columns={mergedColumns as any}
      components={{
        body: {
          cell: EditableCell,
        },
      }}
      expandable={
        displayedDescriptionItems.length > 0
          ? {
              expandedRowRender: (record, index) =>
                expandedContent(record, index),
            }
          : undefined
      }
      dataSource={dataSource}
      {...restProps}
    />
  );
};

export default ResponsiveTable;
