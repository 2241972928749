import { useState } from "react";
import {
  Form,
  Button,
  message,
  Row,
  Col,
  DatePicker,
  Spin,
  InputNumber,
} from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { appApi } from "../../api";
import CollapsibleCard from "../../components/collapsibleCard";

interface UpdateAccountValues {
  accountNumber: number;
  lastOperationsSyncDate: Date;
}

const ChangePasswordPage = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  // load account details
  const { data, isLoading } = useQuery({
    queryKey: ["accounts"],
    queryFn: appApi.accounts.getList,
  });

  const account = data
    ? data.results[0]
    : { id: "pending", accountNumber: "", lastOperationsSyncDate: null };

  const mutation = useMutation({
    mutationFn: ({
      accountNumber,
      lastOperationsSyncDate,
    }: UpdateAccountValues) => {
      setLoading(true);
      return appApi.accounts.update({
        id: account.id,
        accountNumber: accountNumber.toString(),
        lastOperationsSyncDate: lastOperationsSyncDate
          ? lastOperationsSyncDate.toISOString()
          : null,
      });
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ["accounts"] });
      setLoading(false);
      message.success(
        "Dados da conta Metatrader atualizados com sucesso, você já pode executar seu EA nesta conta no metatrader.",
      );
    },
    onError: () => {
      setLoading(false);
    },
  });

  if (isLoading) {
    return <Spin />;
  }

  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={12}>
          <CollapsibleCard title="Metatrader">
            <Form
              form={form}
              onFinish={mutation.mutateAsync}
              layout="vertical"
              style={{
                maxWidth: 500,
                width: "100%",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Form.Item
                name="accountNumber"
                label="Número da conta metatrader"
                initialValue={account.accountNumber}
                tooltip="Este é o número da sua conta metatrader para identificação e cruzamento dos dados na plataforma."
                rules={[
                  {
                    required: true,
                    message:
                      "Por favor informe o número da conta do metatrader.",
                  },
                ]}
              >
                <InputNumber precision={0} style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                name="lastOperationsSyncDate"
                label="Última atualização de posição da conta"
                initialValue={
                  account.lastOperationsSyncDate
                    ? dayjs(account.lastOperationsSyncDate)
                    : null
                }
                tooltip="Esta data é utilizada pelo metatrader para sincronizar suas operações da carteira com a plataforma. Todas as operações realizadas antes desta data serão ignoradas e não serão sincronizadas."
              >
                <DatePicker
                  format="DD/MM/YYYY hh:mm A"
                  showTime={{ use12Hours: false }}
                  style={{ width: "100%" }}
                  placeholder="Data até a qual sua carteira está sincronizada"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading || isLoading}
                >
                  Alterar
                </Button>
              </Form.Item>
            </Form>
          </CollapsibleCard>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <CollapsibleCard title="Como utilizar o metatrader">
            <p>
              Assim como o profit, o Metatrader é uma plataforma de negociação
              que fica atrelada a sua conta na corretora, e através dele podem
              ser realizadas todas as negociações que desejar, porém com o
              diferencial de ser uma plataforma gratuita na maioria das
              corretoras.
            </p>
            <p>
              O Metatrader na FIA pode ser utilizado para manter suas operações
              e posições atualizadas automaticamente na plataforma, sem a
              necessidade de cadastro manual e sem a necessidade de realizar
              suas operações através do metatrader.
            </p>
            <p>
              <a
                href={"/eas_fia/configuracao_metatrader_fiainvest.pdf"}
                target="_blank"
              >
                Instruções de instalação e configuração (PDF)
              </a>
            </p>
            <p>
              <a href={"/eas_fia/FIAWalletSyncV101.zip"}>
                Baixar Expert Advisor da FIA (Robô Metatrader)
              </a>
            </p>
          </CollapsibleCard>
        </Col>
      </Row>
    </>
  );
};

export default ChangePasswordPage;
