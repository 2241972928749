import {
  Form,
  Slider,
  SliderSingleProps,
  List,
  Button,
  Typography,
} from "antd";
import CollapsibleCard from "../../../../components/collapsibleCard";
import { SelectedTickerConfig } from "./picturesWallet";
import { UseMutationResult } from "@tanstack/react-query";
import { Wallet } from "../../../../api/models";

const MonitoredItems = ({
  valueWallet,
  monitoredKeys,
  editWalletMutation,
  setMonitoredKeys,
}: {
  valueWallet: Wallet;
  monitoredKeys: SelectedTickerConfig[];
  editWalletMutation: UseMutationResult;
  setMonitoredKeys: React.SetStateAction<any>;
}) => {
  const removeTickerToConfig = (ticker: string) => {
    const newSelectedKeys = [...monitoredKeys];
    newSelectedKeys.splice(
      newSelectedKeys.findIndex((item) => item.ticker === ticker),
      1,
    );
    editWalletMutation.mutateAsync(newSelectedKeys);
    setMonitoredKeys(newSelectedKeys);
  };

  const updateTickerConfig = (ticker: string, targetPercentage: number) => {
    const newSelectedKeys = [...monitoredKeys];
    const index = newSelectedKeys.findIndex((item) => item.ticker === ticker);
    newSelectedKeys[index].targetPercentage = targetPercentage;
    editWalletMutation.mutateAsync(newSelectedKeys);
    setMonitoredKeys(newSelectedKeys);
  };

  const calculateAmountOfCalls = (ticker: string, targetPercentage: number) => {
    const position = valueWallet.positions.find(
      (item) => item.ticker === ticker,
    );
    if (!position) {
      return 0;
    }
    // deve retornar um numero inteiro multiplo de 100
    return Math.floor((targetPercentage * position.amount) / 100 / 100) * 100;
  };
  const marks: SliderSingleProps["marks"] = {
    25: "25%",
    50: "50%",
    75: "75%",
    100: "100%",
  };
  return (
    <CollapsibleCard
      title="Posições monitoradas"
      classNameBody="flex align-middle justify-center items-center"
    >
      <List
        dataSource={monitoredKeys}
        style={{ width: "100%" }}
        pagination={{ pageSize: 10 }}
        renderItem={(item: SelectedTickerConfig) => {
          const callsToSell = calculateAmountOfCalls(
            item.ticker,
            item.targetPercentage,
          );
          return (
            <List.Item style={{ width: "100%" }}>
              <List.Item.Meta
                title={item.ticker}
                description={
                  <Form className="flex flex-col p-2" layout="vertical">
                    <Form.Item label="Porcentagem alvo" className="mb-1 mt-1">
                      <Slider
                        min={25}
                        max={100}
                        step={5}
                        marks={marks}
                        defaultValue={item.targetPercentage}
                        onChangeComplete={(value: number) =>
                          updateTickerConfig(item.ticker, value || 50)
                        }
                        tooltip={{
                          formatter: (value: number | undefined) => (
                            <span>{value ? `${value}%` : "0%"}</span>
                          ),
                        }}
                        className="mr-3 ml-3"
                      />
                    </Form.Item>
                    <Typography.Text type="secondary">
                      {callsToSell > 0
                        ? `Quantidade de opções: ${callsToSell}`
                        : "Sua posição atual na empresa não permite a montagem de posição de opções para renda."}
                    </Typography.Text>
                  </Form>
                }
              />
              <Button onClick={() => removeTickerToConfig(item.ticker)}>
                Remover
              </Button>
            </List.Item>
          );
        }}
      />
    </CollapsibleCard>
  );
};

export default MonitoredItems;
