import { message } from "antd";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import { getToken } from "../services/localStorage";
import localStorageService from "../services/localStorage";

declare module "axios" {
  export interface AxiosRequestConfig {
    handleErrors?: boolean;
    snakeCaseRequest?: boolean;
    camelCaseResponse?: boolean;
  }
}

export const request = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  handleErrors: true, // habilita o interceptador de erros
  snakeCaseRequest: true,
  camelCaseResponse: true,
});

request.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = getToken();
    if (token && config && config.headers) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    const snakeCaseParams = config.snakeCaseRequest && config.params;
    const snakeCaseData =
      config.snakeCaseRequest &&
      config.data &&
      !(config.data instanceof FormData);
    return {
      ...config,
      ...(snakeCaseParams && {
        params: snakecaseKeys(config.params, { deep: true }),
      }),
      ...(snakeCaseData && {
        data: snakecaseKeys(config.data, { deep: true }),
      }),
    };
  },
  (error) => Promise.reject(error),
);

export const checkSessionExpiration = (response: AxiosResponse) => {
  if (!response) return true;
  if (!response.status) return true;
  if (response.status === 401) return true;
  return false;
};

export const convertErrorMessage = (errorMessage: string) => {
  if (typeof errorMessage === "object") {
    let convertedMessage = JSON.stringify(errorMessage);
    convertedMessage = convertedMessage
      .replace("{", "")
      .replace("}", "")
      .replace("[", "")
      .replace("]", "")
      .replace(/"/g, "")
      .replace("non_field_errors:", "")
      .replace(":", ": ")
      .replace('"', "");
    return convertedMessage;
  }
  return errorMessage;
};

export const findErrorMessage = (error: any) => {
  let errorMessage = "";
  if (!error.response || !error.response.data) {
    errorMessage = error.toString();
  }
  if (error.response?.status === 500) {
    // se erro 500, exibe somente a segunda linha do erro
    const errorMessageList = error.response.data.split("\n");
    if (errorMessageList.length > 1) {
      errorMessage = errorMessageList[1];
    } else {
      errorMessage = error.response.data;
    }
  } else if (error.response?.status === 502) {
    errorMessage =
      "Servidor em manutenção, por gentileza aguarde alguns instantes.";
  } else {
    errorMessage =
      error.response?.data?.message ??
      error.response?.data?.error?.message ??
      error.response?.data?.error ??
      error.response?.data ??
      error.message;
  }
  return convertErrorMessage(errorMessage);
};

request.interceptors.response.use(
  (response) => {
    if (response.config.camelCaseResponse) {
      response.data = camelcaseKeys(response.data, { deep: true });
    }
    return response;
  },
  (error) => {
    if (error.config?.handleErrors) {
      const sessionExpired = checkSessionExpiration(error.response);
      if (sessionExpired) {
        message.error("Sessão expirada, por favor faça login novamente.");
        localStorageService.removeToken();
        localStorageService.removeUser();
        window.location.reload();
        return;
      }
      message.error(findErrorMessage(error));
    }
    return Promise.reject(error);
  },
);
