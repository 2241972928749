import { Select, Form, FormItemProps } from "antd";

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

type propTypes = {
  disabled?: boolean;
  required?: boolean;
} & FormItemProps;

export const MonthSelectionInput = ({
  disabled,
  required,
  ...restProps
}: propTypes) => (
  <Form.Item
    name="month"
    label="Mês"
    rules={required ? [{ required: true, message: "Campo obrigatório" }] : []}
    {...restProps}
  >
    <Select placeholder="Mês" style={{ width: "100%" }} disabled={disabled}>
      {months.map((month) => (
        <Select.Option key={month} value={month}>
          {month}
        </Select.Option>
      ))}
    </Select>
  </Form.Item>
);
