export interface Account {
  id: string;
  user: string;
  accountNumber: string;
  created: string;
  updated: string;
  metatraderLastConnectionDate: string;
  metatraderConnectionStatus: string;
  metatraderSyncDate: string;
  lastOperationsSyncDate: string;
  accountLastProcessingDate: string;
  accountAcceleratedProcessingPending: boolean;
}

export interface B3Instrument {
  id: string;
  created: string;
  updated: string;
  corporateName: string | null;
  ticker: string;
  asset: string;
  assetDescription: string;
  tradingStartDt: string;
  tradingEndDt: string;
  expirationDt: string;
  segmentName: string;
  marketingName: string;
  category: string | null;
  isin: string;
  lotSize: number;
  exercisePrice: string;
  optionType: string;
  optionStyle: string;
  tickerCategoryCode: string | null;
}

export const assetCategoryDecoder = (category: string) => {
  switch (category) {
    case "SHARES":
      return "Ação";
    case "BDR":
      return "BDR";
    case "ETF EQUITIES":
      return "ETF de Ação";
    case "ETF FOREIGN INDEX":
      return "ETF de índice do exterior";
    case "FUNDS":
      return "Fundos";
    case "OPTION ON EQUITIES":
      return "Opção de ativo";
    case "OPTION ON INDEX":
      return "Opção de índice";
    case "STOCK FUTURE":
      return "Futuro de Ação";
    case "UNIT":
      return "Unit";
    case "INDEX":
      return "Índice";
    default:
      return "Desconhecido";
  }
};

export interface TickerPrice {
  ticker: string;
  bid: string;
  ask: string;
  last: string;
  averagePrice: string;
  spread: string;
  status: string;
  created: string;
  updated: string;
  liquidityStatus: string;
  relatedAssetLastPrice: string;
  optionVolatility: string;
  relatedAssetTicker: string;
  fairOptionPrice: string;
  businessDaysToExpiration: string;
  optionDelta: string;
  optionDeltaDisplay: string;
}

export interface StockPriceHistory {
  id: string;
  created: string;
  updated: string;
  ticker: string;
  date: string;
  openPrice: string;
  closePrice: string;
  highPrice: string;
  lowPrice: string;
  volume: string;
}

export interface StockSplitHistory {
  ticker: string;
  date: string;
  ratio: string;
}

export interface StockBonificationHistory {
  ticker: string;
  date: string;
  ratio: string;
}

export interface StockDividendHistory {
  ticker: string;
  comDate: string;
  payDate: string;
  amount: string;
  type: string;
  liquidAmount: string;
}

export interface BlackAndScholesCalculatorResponse {
  busDaysToExpiration: number;
  assetPrice: number;
  volatility: number;
  optionType: string;
  strike: number;
  optionBlackScholesPrice: number;
  optionBlackScholesDelta: number;
}

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  passwordRecentlyReset: boolean;
  isStaff: boolean;
  fcmRegistrationToken: string;
  accounts: Account[];
}

export interface LoginResponse {
  access: string;
  refresh: string;
  user: User;
}

export enum PositionNotificationFreezeMode {
  OFF = "OFF",
  LIQUIDITY = "LIQUIDITY",
  ALL = "ALL",
}

export interface CurrentPosition {
  id: string;
  created: string;
  updated: string;
  ticker: string;
  amount: number;
  lastPrice: string;
  lastMarketPrice: string;
  lastMarketPriceBid: number;
  lastMarketPriceAsk: number;
  lastMarketPriceDate: string;
  lastMarketPriceStatus: string;
  lastMarketPriceLiquidityStatus: string;
  lastMarketPriceVolatility: number | null;
  previousMarketClosePrice: string;
  dayResult: string;
  dayResultInPercentage: string;
  totalPositionDayResult: string;
  averagePrice: string;
  account: string;
  expiration: string | null;
  positionTotal: string;
  strike: string | null;
  walletName: string | null;
  walletId: string | null;
  optionType: string | null;
  itemType: string;
  lastTradeDate: string | null;
  fairOptionPrice: string | null;
  optionAssetLastPrice: string | null;
  businessDaysToExpiration: string | null;
  optionActionToPerform: string | null;
  optionDelta: number | null;
  optionAlias: string | null;
  optionDeltaDisplay: string | null;
  optionUnderlyingAsset: string | null;
  optionLockTicker: string | null;
  positionNotificationFreezeMode: PositionNotificationFreezeMode | null;
}

export const decodePositionNotificationFreezeMode = (
  mode: string | PositionNotificationFreezeMode | null,
): string => {
  switch (mode) {
    case PositionNotificationFreezeMode.OFF:
      return "Desativadas";
    case PositionNotificationFreezeMode.LIQUIDITY:
      return "Com Liquidez";
    default:
      return "Ativadas";
  }
};

export interface PositionStrategyBundleItem {
  id: string;
  created: string;
  updated: string;
  position: string;
  strategyBundle: string;
}

export interface PositionStrategyBundle {
  id: string;
  created: string;
  updated: string;
  wallet: string;
  baseTicker: string | null;
  strategy: string;
  description: string | null;
  items: PositionStrategyBundleItem[];
}

export interface WalletAllocation {
  walletName: string;
  percentage: number;
  totalValue: number;
}

export interface WalletAllocations {
  totalSum: number;
  wallets: WalletAllocation[];
}

export interface OpenOptionPosition {
  ticker: string;
  amount: number;
  strike: string;
  expirationDate: string;
  optionType: string;
  premium: string;
  positionValue: string;
  positionOpenResult: string;
  wallet: string | null;
}

export interface OpenOptionsData {
  totalPremium: number;
  totalCurrentPosition: number;
  totalResult: number;
  positions: OpenOptionPosition[];
}

export enum WalletNames {
  DEFENSIVE = "Carrego",
  INCOME = "Renda",
  VALUE = "Ações",
  PICTURES = "Figuras",
  INTERNATIONAL = "Internacional",
  FIIS = "Fundos imobiliários",
  FIAGROS = "Fundos do agronegócio",
  OTHERS = "Outros",
}

export enum CamelCaseWalletNames {
  defensive = "carrego",
  income = "renda",
  value = "ações",
  pictures = "figuras",
  international = "internacional",
  fiis = "fundosImobiliários",
  fiagros = "fundosDoAgronegócio",
  others = "outros",
}

export interface WalletDetails {
  totalDeltaInValueWallet?: string;
  currentMiniIndex?: string;
  miniIndexLastPrice?: string;
  nextMiniIndex?: string;
  nextMiniIndexPrice?: string;
  miniIndexUnitDelta?: string;
  currentShortBova11Delta?: string;
  expectedShortBova11Delta?: string;
  expectedIndexSellAmount?: string;
  currentIndexSellAmount?: string;
  bova11LastPrice?: string;
  totalValue?: string;
  expectedBova11SoldPutAmount?: string;
  expectedBova11PurchasedPutAmount?: string;
  expectedBova11PurchasedCallAmount?: string;
  currentBova11SoldPutAmount?: string;
  currentBova11PurchasedPutAmount?: string;
  currentBova11PurchasedCallAmount?: string;
  nextEligibleExpirationDate?: string;
  amountOfDaysToExpiration?: string;
  desiredProtectionDeltaTarget?: string;
  expectedBova11CallsSoldAmount?: string;
  expectedBova11CallsPurchasedAmount?: string;
  currentBova11CallsSoldAmount?: string;
  currentBova11CallsPurchasedAmount?: string;
  errorMessageList?: string[];
  currentSoldBova11Amount?: number;
  soldBova11Delta?: number;
  beta?: number;
  volatility?: number;
  correlation?: number;
  correlatedAsset?: string;
  correlatedAssetVolatility?: string;
  valueWalletBeta?: number | null;
}

export type ValueWalletDetails = Pick<
  WalletDetails,
  | "totalValue"
  | "beta"
  | "volatility"
  | "correlation"
  | "correlatedAsset"
  | "correlatedAssetVolatility"
>;

export type DefensiveWalletDetails = Pick<
  WalletDetails,
  | "totalDeltaInValueWallet"
  | "desiredProtectionDeltaTarget"
  | "bova11LastPrice"
  | "currentMiniIndex"
  | "miniIndexLastPrice"
  | "nextMiniIndex"
  | "nextMiniIndexPrice"
  | "miniIndexUnitDelta"
  | "expectedIndexSellAmount"
  | "currentShortBova11Delta"
  | "expectedShortBova11Delta"
  | "nextEligibleExpirationDate"
  | "amountOfDaysToExpiration"
  | "expectedBova11SoldPutAmount"
  | "expectedBova11PurchasedPutAmount"
  | "expectedBova11PurchasedCallAmount"
  | "currentBova11SoldPutAmount"
  | "currentBova11PurchasedPutAmount"
  | "currentBova11PurchasedCallAmount"
  | "currentIndexSellAmount"
  | "errorMessageList"
  | "currentSoldBova11Amount"
  | "soldBova11Delta"
  | "valueWalletBeta"
>;

export type IncomeWalletDetails = Pick<
  WalletDetails,
  | "totalDeltaInValueWallet"
  | "bova11LastPrice"
  | "desiredProtectionDeltaTarget"
  | "expectedBova11CallsSoldAmount"
  | "expectedBova11CallsPurchasedAmount"
  | "currentBova11CallsSoldAmount"
  | "currentBova11CallsPurchasedAmount"
  | "nextEligibleExpirationDate"
  | "errorMessageList"
  | "valueWalletBeta"
>;

export interface Wallet {
  id: string;
  name: WalletNames;
  percentageTargeted: string;
  walletDetails: WalletDetails;
  walletParameters: any | null;
  positions: CurrentPosition[];
  strategies: PositionStrategyBundle[];
  account: string;
}

export interface StockOperation {
  id: string;
  created: string;
  updated: string;
  ticker: string;
  amount: number;
  price: string;
  date: string;
  key?: string | null;
  account: string;
  isExercise: boolean;
  exerciseResult: string | null;
  exerciseDetails: any | null;
  description: string | null;
}

export enum PositionEventTypes {
  STOCK_SPLIT = "SPLIT",
  STOCK_GROUPING = "GROUPING",
  STOCK_BONIFICATION = "BONIFICATION",
  STOCK_DIVIDEND = "DIVIDEND",
  STOCK_JCP = "JCP",
  PERFORMANCE = "RENDIMENTO",
}

export interface PositionEvent {
  id: string;
  created: string;
  updated: string;
  account: string;
  ticker: string;
  positionAmount: number;
  eventType: PositionEventTypes;
  eventTypeDisplay: string;
  eventAmount: string;
  stockAmount: number;
  financialAmount: string;
  comDate: string;
  payDate: string;
  isApplied: boolean;
  appliedDate: string | null;
  unappliedDate: string | null;
}

export interface Communication {
  id: string;
  created: string;
  updated: string;
  user: string;
  title: string;
  message: string;
  read: boolean;
  actionItemCode: string;
  actionItemComplete: boolean;
  actionItemCompleteDate: string;
  actionItemRefreshDate: string;
  notificationAmountSent: number;
  notificationLastSendDate: string;
}

export interface OptionsMonitorRecord {
  type: string;
  ticker: string;
  strike: number;
  lastPrice: number;
  bsPrice: number;
  delta: number;
  deltaDisplay: string;
  volatility: number;
  lastPriceDate: string | null;
  volatilityStatus: string;
  priceStatus: string;
  liquidityStatus: string;
  priceBid: number;
  priceAsk: number;
}

export interface OptionsMonitor {
  baseTicker: string;
  expirationDate: string;
  businessDays: number;
  baseTickerPrice: number;
  baseTickerPriceUpdatedAt: string;
  baseTickerFuturePrice: number;
  yearInterestRate: number;
  inputVolatility: number | null;
  isMarketOpen: boolean;
  fairBaseTickerVolatility: number | null;
  records: OptionsMonitorRecord[];
}

export enum PriceStatus {
  UPDATED = "UPDATED",
  OUTDATED = "OUTDATED",
  NOT_FOUND = "NOT_FOUND",
  MARKET_CLOSED = "MARKET_CLOSED",
}

export enum PriceLiquidityStatus {
  NOT_FOUND = "NOT_FOUND",
  NO_LIQUIDITY = "NO_LIQUIDITY",
  LIQUID = "LIQUID",
}

export enum PriceStatusDisplay {
  UPDATED = "Atualizado",
  OUTDATED = "Desatualizado",
  NOT_FOUND = "Não encontrado",
  MARKET_CLOSED = "Mercado fechado - Preços podem estar divergentes",
}

export enum PriceLiquidityStatusDisplay {
  NOT_FOUND = "Não encontrado",
  NO_LIQUIDITY = "Não",
  LIQUID = "Sim",
}

export const decodePriceStatus = (status: string): PriceStatusDisplay => {
  switch (status) {
    case PriceStatus.UPDATED:
      return PriceStatusDisplay.UPDATED;
    case PriceStatus.OUTDATED:
      return PriceStatusDisplay.OUTDATED;
    case PriceStatus.NOT_FOUND:
      return PriceStatusDisplay.NOT_FOUND;
    case PriceStatus.MARKET_CLOSED:
      return PriceStatusDisplay.MARKET_CLOSED;
    default:
      return status as PriceStatusDisplay;
  }
};

export const decodePriceLiquidityStatus = (
  status: string,
): PriceLiquidityStatusDisplay => {
  switch (status) {
    case PriceLiquidityStatus.NOT_FOUND:
      return PriceLiquidityStatusDisplay.NOT_FOUND;
    case PriceLiquidityStatus.NO_LIQUIDITY:
      return PriceLiquidityStatusDisplay.NO_LIQUIDITY;
    case PriceLiquidityStatus.LIQUID:
      return PriceLiquidityStatusDisplay.LIQUID;
    default:
      return status as PriceLiquidityStatusDisplay;
  }
};

export enum OptionAction {
  INCOME_DELTA_HEDGE = "INCOME_DELTA_HEDGE",
  CARREGO_DELTA_HEDGE = "CARREGO_DELTA_HEDGE",
  FIGURES_DELTA_HEDGE = "FIGURES_DELTA_HEDGE",
  DELTA_HEDGE_BY_EXPIRATION = "DELTA_HEDGE_BY_EXPIRATION",
  MONITOR_FOR_EXERCISE = "MONITOR_FOR_EXERCISE",
  DELTA_HEDGE_BY_ASSOCIATION = "DELTA_HEDGE_BY_ASSOCIATION",
}

export const decodeOptionAction = (action: string): string => {
  switch (action) {
    case OptionAction.INCOME_DELTA_HEDGE:
      return "Delta Hedge de Renda";
    case OptionAction.CARREGO_DELTA_HEDGE:
      return "Delta Hedge de Carrego";
    case OptionAction.FIGURES_DELTA_HEDGE:
      return "Delta Hedge de Figuras";
    case OptionAction.DELTA_HEDGE_BY_EXPIRATION:
      return "Rolagem por vencimento";
    case OptionAction.MONITOR_FOR_EXERCISE:
      return "Opção com trava. Monitorar o exercício.";
    case OptionAction.DELTA_HEDGE_BY_ASSOCIATION:
      return "Delta Hedge de opção pózinho";
    default:
      return action;
  }
};

export interface PositionResultDetails {
  date: string;
  ticker: string;
  dayTradePnl: number;
  tradedPositionPnl: number;
  carriedPositionPnl: number;
  totalPnl: number;
  positionChangeAtDate: number;
  positionAtEod: number;
  positionPreviousDay: number;
  tradesAvgPrice: number;
  dayTradeResultBalance: number;
  previousClosePrice: number;
  closePrice: number;
}

export interface Dividend {
  ticker: string;
  comDate: string;
  payDate: string;
  value: string;
}

export interface JSCP {
  ticker: string;
  comDate: string;
  payDate: string;
  value: string;
}

export interface EventResultDetails {
  walletItemId: string;
  dividends: string;
  jscp: string;
  dividendDetails: Dividend | undefined;
  jscpDetails: JSCP | undefined;
}

export interface ResultSummary {
  pnlResult: string;
  accumulatedPnlResult: string;
  overridenPnlResult: string | null;
  positionResult: string;
  eventResult: string;
  moneyInResult: string;
  moneyOutResult: string;
  incomeResult: string;
}

export interface WalletResult extends ResultSummary {
  wallet: string;
  walletName: string;
}

export interface WalletDailyResult extends WalletResult {
  date: string;
  positionResultDetails: PositionResultDetails[];
  eventResultDetails: EventResultDetails[];
}

export interface WalletMonthlyResult extends WalletResult {
  year: string;
  month: string;
}

export interface WalletYearlyResult extends WalletResult {
  year: string;
}

export interface AccountResult extends ResultSummary {
  account: string;
  accountUserEmail: string;
}

export interface AccountDailyResult extends AccountResult {
  date: string;
  resultIgnored: boolean;
  id: string;
  isPendingProcessing: boolean;
  wallets: {
    [key in CamelCaseWalletNames]?: WalletDailyResult;
  };
}

export interface PnlChartData {
  date: string;
  pnl: number;
  accumulatedPnl: number;
  details: {
    previousDayPnl: number;
    wallets: {
      [key in CamelCaseWalletNames]?: number;
    };
  };
}

export interface IncomeChartData {
  date: string;
  income: number;
  details: {
    optionsIncome: number;
    dividendsIncome: number;
    miniIndexIncome: number;
  };
}

export interface AccountMonthlyResult extends AccountResult {
  year: string;
  month: string;
  wallets: {
    [key in CamelCaseWalletNames]?: WalletMonthlyResult;
  };
}

export interface AccountYearlyResult extends AccountResult {
  year: string;
  wallets: {
    [key in CamelCaseWalletNames]?: WalletYearlyResult;
  };
}

export interface IncomeDetails {
  id: string;
  dividendsIncome: string;
  optionsIncome: string;
  totalIncome: string;
  miniIndexIncome: string;
  created: string;
  updated: string;
}

export interface AccountMonthlyIncome extends IncomeDetails {
  account: string;
  year: string;
  month: string;
  overridenOptionsIncome: string | null;
  overridenDividendsIncome: string | null;
  overridenMiniIndexIncome: string | null;
  isDividendsIncomeOverriden: boolean;
  isOptionsIncomeOverriden: boolean;
  isMiniIndexIncomeOverriden: boolean;
  totalDividendsIncome: string;
  totalOptionsIncome: string;
  totalMiniIndexIncome: string;
  hasOverridenIncome: boolean;
  isPendingProcessing?: boolean;
}

export interface AccountMonthlyIncomeEntry extends IncomeDetails {
  date: string;
  event: PositionEvent | null;
  stockOperation: StockOperation | null;
  incomeDetails: any | null;
  overridenIncome: string | null;
}

export interface PersonalizationParameter {
  key: string;
  label: string;
  defaultValue: string | boolean | number | null;
  type: string;
  category: string;
  description: string;
  choices: string[];
  userDefinedValue: string;
  upperLimit: number | null;
  lowerLimit: number | null;
  visible: boolean;
}

export interface StockBulkOperationValidationError {
  ticker: string;
  amount: string;
  price: string;
  date: string;
  wallet?: string;
  existingOperation: boolean;
  message: string;
  isBlocking: boolean;
}

export interface StockOperationCreationPayload {
  ticker: string;
  amount: number;
  price: string;
  date: string;
  wallet?: string | undefined;
  key?: string;
  description?: string;
}

export interface StockBulkOperationGenerationResponse {
  validations: {
    ticker: string;
    message: string;
  }[];
  operations: StockBulkOperationValidationError[];
}

export interface PositionSyncRequest {
  ticker: string;
  amount: number;
  price: string;
}

export interface MiniIndexCashflow {
  ticker: string;
  date: string;
  cashflow: number;
  positionChangeCashflow: number;
  carriedOverPositionCashflow: number;
  dayTradeCashflow: number;
  previousClosePrice: number;
  closePrice: number;
  tradesLeftOverAvgPrice: number;
  tradesLeftOverPositionAmount: number;
}

export interface DividendCashflow {
  ticker: string;
  date: string;
  cashflow: number;
  quantity: number;
  dividendPerShare: number;
}

export interface OperationCashflow {
  ticker: string;
  operationDate: string;
  cashflow: number;
  quantity: number;
  price: number;
  liquidationPeriod: string;
}

export interface CashflowResult {
  currentDate: string;
  dateMinus1: string;
  dateMinus2: string;
  datePlus1: string;
  datePlus2: string;
  cashflowResultD0: number;
  cashflowResultD1: number;
  cashflowResultD2: number;
  cashflowD0Operations: OperationCashflow[];
  cashflowD0Dividends: DividendCashflow[];
  cashflowD0MiniIndexes: MiniIndexCashflow[];
  cashflowD1Dividends: DividendCashflow[];
  cashflowD1Operations: OperationCashflow[];
  cashflowD1MiniIndexes: MiniIndexCashflow[];
  cashflowD2Operations: OperationCashflow[];
  cashflowD2Dividends: DividendCashflow[];
}

export interface TickerPriceDetails {
  ticker: string;
  average: number;
  bid: number;
  ask: number;
  status: string;
  liquidityStatus: string;
  date: string;
}

export interface ExerciseResultData {
  exerciseResult: string;
  cashResult: number;
  amountDelta: number;
}

export interface ExerciseOption {
  walletName: string;
  walletId: string;
  ticker: string;
  optionType: string;
  quantity: number;
  strikePrice: number;
  expirationDate: string;
  exerciseData: ExerciseResultData;
  optionAssetLastPrice: number;
  priceDistanceInPercentage: number;
}

export interface BaseTickerExerciseData {
  orderToNulifyExercise: boolean;
  exerciseAmount: number;
  orderAmount: number;
  orderPriceToSend: number;
  orderLikelyExecutionPrice: number;
  cashResultWithOrder: number;
  cashResultWithoutOrder: number;
  orderMaxPrice: number;
  orderMinPrice: number;
}

export interface OptionBaseTicker {
  baseTicker: string;
  currentAmount: number;
  price: TickerPriceDetails;
  orderType: string;
  options: ExerciseOption[];
  hasOptionsInExercise: boolean;
  baseTickerExerciseData: BaseTickerExerciseData;
}

export interface OptionExpiration {
  expirationDate: string;
  daysToExpiration: number;
  cashResultWithOrder: number;
  cashResultWithoutOrder: number;
  baseTickers: OptionBaseTicker[];
}

export interface OptionsExerciseData {
  walletsList: string[];
  relatedAssets: string[];
  expirationDates: string[];
  data: OptionExpiration;
}

export interface PriceSimulationData {
  ticker: string;
  amountOverride: number | null;
  priceOverride: number | null;
  adjustmentPercentage: number;
  volatilityOverride: number | null;
  positionId: string;
}

export interface AddedPosition {
  ticker: string;
  amount: number;
  wallet: string;
}

export interface SimulationDataParams {
  globalAdjustmentPercentage: number;
  prices: PriceSimulationData[];
  newSimulatedPositions: AddedPosition[];
}

export interface WalletSimulationData extends Wallet {
  totalWalletResult: string;
  totalWalletDelta: string;
}

export interface AccountSimulationData {
  totalAccountResult: string;
  totalAccountDelta: string;
  valueWalletsDelta: string;
  wallets: WalletSimulationData[];
}

export interface CalendarItem {
  date: string;
  name: string;
  country: string;
  type: string;
}
