import { useState, useEffect } from "react";

// Design for desktop displays from 1280×720 through 1920×1080.
// Design for mobile displays from 360×640 through 414×896.
// Design for tablet displays from 601×962 through 1280×800

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.outerWidth,
    height: window.outerWidth,
  });
  const isSmallMobile = screenSize.width < 380;
  const isMobile = screenSize.width < 768;
  const isTablet = screenSize.width >= 768 && screenSize.width <= 1024;
  const isDesktop = screenSize.width > 1024;

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.outerWidth,
        height: window.outerWidth,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { screenSize, isSmallMobile, isMobile, isTablet, isDesktop };
};

export default useScreenSize;
