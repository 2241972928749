import { useState, useEffect } from "react";
import type { ListResponse } from "../../api/types";
import { AccountMonthlyIncome } from "../../api/models";
import { useQuery } from "@tanstack/react-query";
import { appApi } from "../../api";
import { Link } from "react-router-dom";
import ResponsiveTable from "../../components/ResponsiveTable";
import CollapsibleCard from "../../components/collapsibleCard";
import PriceDisplay from "../../components/priceDisplay";
import { EditMonthlyIncomeRecord } from "./editMonthlyIncomeRecord";
import { Tooltip, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { InfoCircleOutlined } from "@ant-design/icons";

const MonthlyIncomeRecords = () => {
  const queryKey = "monthlyIncomeRecords";
  const [refetchInterval, setRefreshInterval] = useState<number | undefined>(
    120000,
  );
  const [accountCalculationsInProgress, setAccountCalculationsInProgress] =
    useState<boolean>(false);

  const [page, setPage] = useState(1);
  const { data, isFetching } = useQuery<ListResponse<AccountMonthlyIncome>>({
    queryKey: [queryKey, page],
    queryFn: () => appApi.pnl.getAccountMonthlyIncome(page),
    refetchInterval: refetchInterval,
  });

  useEffect(() => {
    const hasRecordsCalculating = data?.results?.some(
      (record) => record.isPendingProcessing,
    );
    if (hasRecordsCalculating) {
      setRefreshInterval(20000);
      setAccountCalculationsInProgress(true);
    } else {
      setRefreshInterval(360000);
      if (accountCalculationsInProgress) {
        setAccountCalculationsInProgress(false);
        message.success("Recálculo de renda finalizado.");
      }
    }
  }, [data]);

  const columns = [
    {
      title: "Período",
      dataIndex: "id",
      render: (_: string, record: AccountMonthlyIncome) => {
        if (record.isPendingProcessing) {
          return (
            <div>
              {record.month} / {record.year}
              <span className="ml-2">
                <LoadingOutlined />
              </span>
            </div>
          );
        }
        return (
          <Link to={`/income/?year=${record.year}&month=${record.month}`}>
            {`${record.month} / ${record.year}`}
          </Link>
        );
      },
    },
    {
      title: "Renda total",
      dataIndex: "totalIncome",
      render: (value: string, record: AccountMonthlyIncome) => (
        <PriceDisplay price={value} hasOverride={record.hasOverridenIncome} />
      ),
    },
    {
      title: (
        <Tooltip title="A renda de opções po padrão é apurada para o mês de vencimento de cada opção, e somente é contabilizada após a zeragem da posição da opção. Este comportamento pode ser alterado para contabilizar a renda após a execução da ordem através de uma personalização da conta.">
          Renda de opções{"  "}
          <InfoCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "totalOptionsIncome",
      render: (value: string, record: AccountMonthlyIncome) => (
        <PriceDisplay
          price={value}
          hasOverride={record.isOptionsIncomeOverriden}
        />
      ),
    },
    {
      title: (
        <Tooltip title="A renda de dividendos é registrada a partir da data COM do dividendo, porém é contabilizada para o mês de pagamento de fato do dividendo.">
          Renda de dividendos{"  "}
          <InfoCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "totalDividendsIncome",
      render: (value: string, record: AccountMonthlyIncome) => (
        <PriceDisplay
          price={value}
          hasOverride={record.isDividendsIncomeOverriden}
        />
      ),
    },
    {
      title: (
        <Tooltip title="A renda de mini índice segue os ajustes diários, contabilizados diretamente para o mês corrente em que houve o ajuste">
          Renda de mini índice{"  "}
          <InfoCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "totalMiniIndexIncome",
      render: (value: string, record: AccountMonthlyIncome) => (
        <PriceDisplay
          price={value}
          hasOverride={record.isMiniIndexIncomeOverriden}
        />
      ),
    },
    {
      title: "Editar",
      dataIndex: "id",
      render: (_: string, record: AccountMonthlyIncome) => (
        <EditMonthlyIncomeRecord
          year={record.year}
          month={record.month}
          overridenDividendsIncome={record.overridenDividendsIncome}
          overridenOptionsIncome={record.overridenOptionsIncome}
          overridenMiniIndexIncome={record.overridenMiniIndexIncome}
        />
      ),
    },
  ];

  return (
    <CollapsibleCard
      title="Renda Mensal"
      collapsible={false}
      action={<EditMonthlyIncomeRecord />}
    >
      <ResponsiveTable
        dataSource={data?.results}
        columns={columns}
        loading={isFetching}
        size="small"
        pagination={{
          current: page,
          onChange: (page) => {
            setPage(page);
          },
          total: data?.count,
          pageSize: 10,
          showSizeChanger: false,
        }}
        rowKey="id"
      />
    </CollapsibleCard>
  );
};

export default MonthlyIncomeRecords;
