import { OpenOptionsData } from "../../api/models";
import { appApi } from "../../api";
import { Typography, Descriptions } from "antd";
import { useQuery } from "@tanstack/react-query";
import CollapsibleCard from "../../components/collapsibleCard";
import useScreenSize from "../../layouts/useScreenSize";
import PriceDisplay from "../../components/priceDisplay";
import { OpenOptionPosition } from "../../api/models";
import { List, Modal } from "antd";
import { useState } from "react";
import { formatDate } from "../../services/dates";

const { Title, Text } = Typography;

const renderOpenOptionPositionDetails = (position: OpenOptionPosition) => {
  return (
    <List.Item>
      <Descriptions
        title={`Situação da posição ${position.ticker}`}
        layout="vertical"
      >
        <Descriptions.Item label="Prêmio recebido">
          <PriceDisplay price={position.premium} />
        </Descriptions.Item>
        <Descriptions.Item label="Situação atual">
          <PriceDisplay price={position.positionValue} />
        </Descriptions.Item>
        <Descriptions.Item label="Resultado">
          <PriceDisplay price={position.positionOpenResult} />
        </Descriptions.Item>
        <Descriptions.Item label="Vencimento">
          {formatDate(position.expirationDate)}
        </Descriptions.Item>
        <Descriptions.Item label="Quantidade">
          {position.amount}
        </Descriptions.Item>
      </Descriptions>
    </List.Item>
  );
};

const OptionPositionsIndicator = () => {
  const { data, isLoading } = useQuery<OpenOptionsData>({
    queryKey: ["AccountOptionsPositionsData"],
    queryFn: () => appApi.wallets.getAccountOpenOptionsPositions(),
  });

  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useScreenSize();
  const titleFontSize = isMobile ? 12 : 16;

  const openOptionsList: OpenOptionPosition[] = data?.positions || [];

  return (
    <div className="w-full">
      <CollapsibleCard
        title={"Posições de opções em aberto"}
        loading={isLoading}
      >
        <div
          className="flex justify-between p-2 cursor-pointer"
          onClick={() => setIsOpen(true)}
        >
          <div className="flex flex-col align-middle justify-center  w-1/3 items-center bg-slate-200 p-2 rounded mr-1  cursor-pointer">
            <Text>Prêmios</Text>
            <Title level={4} style={{ marginTop: 0, fontSize: titleFontSize }}>
              <PriceDisplay price={data?.totalPremium} />
            </Title>
          </div>
          <div className="flex flex-col align-middle justify-center items-center w-1/3 bg-purple-200 p-2 rounded cursor-pointer">
            <Text>Mercado</Text>
            <Title level={4} style={{ marginTop: 0, fontSize: titleFontSize }}>
              <PriceDisplay price={data?.totalCurrentPosition} />
            </Title>
          </div>
          <div
            className={`flex flex-col align-middle justify-center items-center w-1/3 p-2 rounded ml-1  cursor-pointer ${
              data?.totalResult
                ? data.totalResult > 0
                  ? "bg-green-200"
                  : "bg-red-200"
                : "bg-gray-200"
            }`}
          >
            <Text>Resultado parcial</Text>
            <Title level={4} style={{ marginTop: 0, fontSize: titleFontSize }}>
              <PriceDisplay price={data?.totalResult} />
            </Title>
          </div>
        </div>
      </CollapsibleCard>
      <Modal
        title={`Detalhes das posições de opções abertas`}
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
      >
        <List
          itemLayout="vertical"
          dataSource={openOptionsList}
          renderItem={(item) => renderOpenOptionPositionDetails(item)}
        />
      </Modal>
    </div>
  );
};

export default OptionPositionsIndicator;
