import { Alert, Row, Col, AlertProps } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { appApi } from "../../api";
import { useAuth } from "../../providers/authProvider";
import { LoginResponse } from "../../api/models";

const LoginAsUserPage = () => {
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("user_id");
  const token = searchParams.get("token");
  const [loggedInUserData, setLoggedInUserData] =
    useState<LoginResponse | null>(null);
  const { performLogin } = useAuth();
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState<string>("");

  const mutation = useMutation({
    mutationFn: () => {
      setLoading(true);
      return appApi.authentication.loginAsUser({
        userId: userId!,
        token: token!,
      });
    },
    retry: false,
    onSuccess: (response: any) => {
      setLoading(false);
      setLoggedInUserData(response);
    },
    onError: (error: any) => {
      setLoading(false);
      setServerError(error?.response?.data || "Erro desconhecido.");
    },
  });

  useEffect(() => {
    if (userId && token) {
      mutation.mutate();
    }
  }, [userId, token]);

  const loginAfter5Seconds = () => {
    setTimeout(() => {
      if (!loggedInUserData) {
        return;
      }
      performLogin(loggedInUserData);

      // navigate to dashboard and reload
      window.location.href = "/dashboard";
      window.location.reload(); // force reload to avoid any issues with the current
    }, 5000);
  };

  useEffect(() => {
    if (loggedInUserData) {
      loginAfter5Seconds();
    }
  }, [loggedInUserData]);

  let alertType: AlertProps["type"] = "info";
  let alertDescription = "Carregando...";

  if (!userId) {
    alertType = "error";
    alertDescription = "ID de usuário não informado.";
  }
  if (!token) {
    alertType = "error";
    alertDescription = "Token não informado.";
  }

  if (loading) {
    alertDescription = "Logando como usuário...";
  }

  if (serverError) {
    alertType = "error";
    alertDescription = serverError;
  }

  if (loggedInUserData) {
    alertType = "success";
    alertDescription = `Logado como ${loggedInUserData.user.email}. Redirecionando...`;
  }

  return (
    <Row>
      <Col className="w-full">
        <Row>
          <Alert description={alertDescription} type={alertType} showIcon />
        </Row>
      </Col>
    </Row>
  );
};

export default LoginAsUserPage;
