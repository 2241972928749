import { Tabs } from "antd";
import type { TabsProps } from "antd";
import DefensiveWallet from "./defensiveWallet";
import IncomeWallet from "./incomeWallet";
import { useSearchParams } from "react-router-dom";
import PicturesWallet from "./picturesWallet/picturesWallet";
import ExerciseScreen from "./exerciseScreen";

export enum TabKeys {
  CURRENT_POSITIONS = "current_positions",
  VALUE = "value",
  INCOME = "income",
  DEFENSIVE = "defensive",
  PICTURES = "pictures",
  INTERNATIONAL = "international",
  TRANSACTIONS = "transactions",
  EVENTS = "events",
  FIIS = "fiis",
  FIAGROS = "fiagros",
  EXERCISE = "exercise",
  OTHERS = "others",
}

const items: TabsProps["items"] = [
  {
    key: TabKeys.INCOME,
    label: "Renda",
    children: <IncomeWallet />,
  },
  {
    key: TabKeys.DEFENSIVE,
    label: "Carrego",
    children: <DefensiveWallet />,
  },
  {
    key: TabKeys.PICTURES,
    label: "Figuras",
    children: <PicturesWallet />,
  },
  {
    key: TabKeys.EXERCISE,
    label: "Exercício de opções",
    children: <ExerciseScreen />,
  },
];

const WalletPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab") || TabKeys.INCOME;
  let selectedTab = TabKeys.INCOME;
  if (Object.values(TabKeys).includes(tab as TabKeys)) {
    selectedTab = tab as TabKeys;
  }

  return (
    <Tabs
      defaultActiveKey={selectedTab}
      items={items}
      onChange={(activeKey) => setSearchParams({ tab: activeKey })}
      tabBarStyle={{ paddingLeft: 16, paddingRight: 16 }}
    />
  );
};

export default WalletPage;
