import { useEffect, useState } from "react";
import {
  Form,
  Button,
  Typography,
  InputNumber,
  Select,
  message,
  Alert,
  Tabs,
} from "antd";
import { useQuery } from "@tanstack/react-query";
import { appApi } from "../../../api";
import { OptionsMonitor, PriceStatus } from "../../../api/models";
import DHMonitorTable from "./dhOptionsList";
import {
  decodeErrorMessage,
  numberToPercentageDisplay,
} from "../../../services/utils";
import { numberToCurrency } from "../../../services/utils";
import { formatDateTime } from "../../../services/dates";

interface FormValues {
  ticker: string;
  expirationDate: string;
  volatility?: number;
  price?: number;
}

export enum OptionType {
  CALL = "Call",
  PUT = "Put",
}

export enum OperationType {
  BUY = "BUY",
  SELL = "SELL",
}

export type MonitorTab = {
  key: string;
  label: string;
  ticker: string;
  optionType: OptionType;
  operationType: OperationType;
  minDelta: number;
  maxDelta: number;
};

const DeltaHedgeOptionsMonitor = ({
  monitorTabs,
}: {
  monitorTabs: MonitorTab[];
}) => {
  const [expirationDate, setExpirationDate] = useState<string>("");
  const [volatility, setVolatility] = useState<number | undefined>(15);
  const [dynamicRefetchInterval, setDynamicRefetchInterval] = useState(10000);
  const [queryIndex, setQueryIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState(
    monitorTabs
      ? monitorTabs.length > 0
        ? monitorTabs[0]
        : undefined
      : undefined,
  );

  const { data: expirationDatesList, isLoading: isLoadingExpirationDates } =
    useQuery({
      queryKey: ["dhOptionsExpirationDatesList"],
      queryFn: () =>
        appApi.optionMonthlyExpirationDates.get({
          minDaysDistance: 20,
          maxDaysDistance: 90,
        }),
    });

  useEffect(() => {
    if (expirationDatesList && expirationDatesList.length > 0) {
      setExpirationDate(expirationDatesList[0]);
    }
  }, [expirationDatesList]);

  const { data, isLoading, error } = useQuery<OptionsMonitor>({
    queryKey: [
      "DHIncomeOptionsMonitor",
      selectedTab?.ticker,
      expirationDate,
      volatility,
      selectedTab?.optionType,
      selectedTab?.minDelta,
      selectedTab?.maxDelta,
    ],
    queryFn: () =>
      appApi.optionsMonitor.get({
        ticker: selectedTab?.ticker || "",
        expirationDate,
        volatility,
        price: undefined,
        optionsType: selectedTab?.optionType,
        lowerDelta: selectedTab?.minDelta,
        upperDelta: selectedTab?.maxDelta,
      }),
    enabled: expirationDate !== "" && selectedTab !== undefined,
    retry: false,
    refetchInterval: dynamicRefetchInterval,
  });

  useEffect(() => {
    if (error) {
      message.error(decodeErrorMessage(error));
    }
  }, [error]);

  useEffect(() => {
    if (data?.records) {
      const hasPrice = data.records.every((record) => {
        return [PriceStatus.UPDATED, PriceStatus.MARKET_CLOSED].includes(
          record?.priceStatus as PriceStatus,
        );
      });
      setDynamicRefetchInterval(hasPrice ? 180000 : 10000);
    }
  }, [data]);

  if (isLoadingExpirationDates || expirationDate === "") {
    return <Typography.Text>Carregando...</Typography.Text>;
  }

  if (!selectedTab) {
    return <Alert message="Nenhum ativo definido" type="warning" />;
  }

  const tabs = monitorTabs.map((tab) => ({
    key: tab.key,
    label: tab.label,
    children: <DHMonitorTable optionsMonitor={data} isLoading={isLoading} />,
  }));

  return (
    <>
      <Form
        layout="inline"
        initialValues={{
          ticker: selectedTab?.ticker,
          expirationDate,
          volatility,
        }}
        onFinish={(values: FormValues) => {
          setExpirationDate(values.expirationDate);
          setVolatility(values.volatility);
          setQueryIndex(queryIndex + 1);
        }}
        style={{ marginBottom: "20px" }}
      >
        <Form.Item
          name="expirationDate"
          label="Vencimento"
          rules={[{ required: true }]}
        >
          <Select style={{ minWidth: 150 }}>
            {expirationDatesList?.map((date) => (
              <Select.Option key={date} value={date}>
                {date}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="volatility"
          label="Volatilidade padrão"
          rules={[{ required: false }]}
          tooltip="Volatilidade padrão para o cálculo do preço das opções quando não é possível obter a volatilidade implícita da opção no mercado."
        >
          <InputNumber suffix="%" />
        </Form.Item>
        <Form.Item className="flex justify-center">
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Atualizar
          </Button>
        </Form.Item>
        <Form.Item>
          Últ. preço do ativo {data?.baseTicker}:{" "}
          {numberToCurrency(data?.baseTickerPrice)}
        </Form.Item>
        <Form.Item>
          Data:
          {data?.baseTickerPriceUpdatedAt
            ? formatDateTime(data?.baseTickerPriceUpdatedAt)
            : "Sem preço"}
        </Form.Item>
        <Form.Item>Dias úteis até o vencimento: {data?.businessDays}</Form.Item>
        <Form.Item>
          Volatilidade justa:{" "}
          {data?.fairBaseTickerVolatility
            ? numberToPercentageDisplay(data.fairBaseTickerVolatility * 100)
            : "N/D"}
        </Form.Item>
        {data?.records?.find(
          (item) =>
            ![PriceStatus.UPDATED, PriceStatus.MARKET_CLOSED].includes(
              item?.priceStatus as PriceStatus,
            ),
        ) && (
          <Alert
            message="Alguns preços de opções ainda não foram atualizados. A atualização ocorrerá de forma automática."
            type="warning"
            showIcon
          />
        )}
        {data?.records?.length == 0 && !isLoading && (
          <Alert
            message="Nenhuma opção encontrada para o ativo e parâmetros selecionados."
            type="error"
            showIcon
          />
        )}
      </Form>
      <Tabs
        defaultActiveKey={selectedTab.key}
        onChange={(key) => {
          const tab = monitorTabs.find((tab) => tab.key === key);
          if (tab) setSelectedTab(tab);
        }}
        items={tabs}
      />
    </>
  );
};

export default DeltaHedgeOptionsMonitor;
