import { appApi } from "../../api";
import { useQuery } from "@tanstack/react-query";
import CollapsibleCard from "../collapsibleCard";
import { Line } from "react-chartjs-2";
import { Alert } from "antd";
import { useEffect, useState } from "react";

const PriceHistoryChart = ({ asset }: { asset: string }) => {
  const [refetchInterval, setRefreshInterval] = useState<number | undefined>(
    10000,
  );
  const { data, isLoading } = useQuery({
    queryKey: ["asset-price-history-chart", asset],
    queryFn: () =>
      appApi.resources.getHistoricalPrices(asset as string, 1, 365),
    enabled: asset !== "" && asset !== undefined,
    refetchInterval: refetchInterval,
  });

  useEffect(() => {
    if (data && data?.results?.length === 0) {
      setRefreshInterval(10000);
    } else {
      setRefreshInterval(undefined);
    }
  }, [data]);

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: false,
      },
    },
  };
  const chartData = {
    labels: data && data.results ? data.results.map((item) => item.date) : [],
    datasets: [
      {
        label: "Fechamento (R$)",
        data:
          data && data.results
            ? data.results.map((item) => item.closePrice)
            : [],
        borderColor: "blue",
        backgroundColor: "lightblue",
      },
    ],
  };
  return (
    <CollapsibleCard title="Gráfico de preços diários" loading={isLoading}>
      {(!data && !isLoading) || (data?.results?.length || 0) < 5 ? (
        <Alert
          type="warning"
          message="Nenhum preço histórico obtido para este ativo."
        />
      ) : (
        <Line data={chartData} options={options} id="historicalPriceChart" />
      )}
    </CollapsibleCard>
  );
};

export default PriceHistoryChart;
