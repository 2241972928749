import dayjs from "dayjs";
import timezonePlugin from "dayjs/plugin/timezone";
import utcPlugin from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);
dayjs.tz.setDefault("Etc/GMT-3");

export const formatDate = (date: string) => {
  try {
    return dayjs.tz(date).format("DD/MM/YYYY");
  } catch (error) {
    console.error("Error formatting date", date, error);
    return date;
  }
};

export const formatDateTime = (date: string) => {
  const dateFormatted = dayjs.utc(date).local();
  return dateFormatted.format("DD/MM/YYYY HH:mm");
};

export const formatTime = (date: string) => {
  return dayjs.tz(date, "UTC").format("HH:mm");
};

export const formatTimezone = (date: string) => {
  return dayjs(date).format("DD/MM/YYYY HH:mm z");
};

export const createNewDate = () => {
  return dayjs();
};

export const createNewDateFromString = (date: string) => {
  return dayjs(date);
};
export const newDateMinusDays = (days: number) => {
  return dayjs().subtract(days, "day");
};
