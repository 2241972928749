import { useQuery } from "@tanstack/react-query";
import { appApi } from "../../api";
import { Alert, Descriptions } from "antd";
import CollapsibleCard from "../collapsibleCard";
import {
  decodePriceLiquidityStatus,
  decodePriceStatus,
} from "../../api/models";
import { formatDateTime } from "../../services/dates";
import { useState, useEffect } from "react";
import PriceDisplay from "../priceDisplay";
import {
  numberToCurrency,
  numberToPercentageDisplay,
} from "../../services/utils";

const AssetPriceDetails = ({ asset }: { asset: string }) => {
  const priceRefreshInterval = 10000;
  const [refreshInterval, setRefreshInterval] = useState<number | undefined>(
    priceRefreshInterval,
  );

  const { data: assetPriceData, isLoading: isLoadingPrice } = useQuery({
    queryKey: ["asset-price", asset],
    queryFn: () => appApi.resources.getPrice(asset as string),
    enabled: asset !== "" && asset !== undefined,
    refetchInterval: refreshInterval,
  });

  useEffect(() => {
    if (assetPriceData && assetPriceData?.results?.length > 0) {
      if (assetPriceData?.results[0]?.status != "UPDATED") {
        setRefreshInterval(10000);
      } else {
        setRefreshInterval(undefined);
      }
    }
  }, [assetPriceData]);

  const assetPriceDetails =
    assetPriceData?.results && assetPriceData?.results.length > 0
      ? assetPriceData?.results[0]
      : undefined;

  return (
    <CollapsibleCard title="Último preço registrado" loading={isLoadingPrice}>
      {assetPriceDetails ? (
        <Descriptions column={2}>
          <Descriptions.Item label="Última negociação">
            <PriceDisplay price={assetPriceDetails?.last} />
          </Descriptions.Item>
          <Descriptions.Item label="Bid">
            <PriceDisplay price={assetPriceDetails?.bid} />
          </Descriptions.Item>
          <Descriptions.Item label="Ask">
            <PriceDisplay price={assetPriceDetails?.ask} />
          </Descriptions.Item>
          <Descriptions.Item label="Spread">
            <PriceDisplay price={assetPriceDetails?.spread} />
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            {decodePriceStatus(assetPriceDetails?.status)}
          </Descriptions.Item>
          <Descriptions.Item label="Data de atualização">
            {formatDateTime(assetPriceDetails?.updated)}
          </Descriptions.Item>
          {assetPriceDetails?.liquidityStatus ? (
            <Descriptions.Item label="Status de liquidez">
              {decodePriceLiquidityStatus(assetPriceDetails?.liquidityStatus)}
            </Descriptions.Item>
          ) : null}
          {assetPriceDetails?.optionVolatility ? (
            <Descriptions.Item label="Volatilidade">
              {numberToPercentageDisplay(assetPriceDetails?.optionVolatility)}
            </Descriptions.Item>
          ) : null}
          {assetPriceDetails?.relatedAssetLastPrice ? (
            <Descriptions.Item label="Preço do Ativo relacionado">
              {numberToCurrency(assetPriceDetails?.relatedAssetLastPrice)}
            </Descriptions.Item>
          ) : null}
          {assetPriceDetails?.businessDaysToExpiration ? (
            <Descriptions.Item label="Dias úteis para expiração">
              {assetPriceDetails?.businessDaysToExpiration}
            </Descriptions.Item>
          ) : null}
          {assetPriceDetails?.optionDeltaDisplay ? (
            <Descriptions.Item label="Delta">
              {numberToPercentageDisplay(assetPriceDetails?.optionDeltaDisplay)}
            </Descriptions.Item>
          ) : null}
          {assetPriceDetails?.fairOptionPrice ? (
            <Descriptions.Item label="Preço justo da opção">
              {numberToCurrency(assetPriceDetails?.fairOptionPrice)}
            </Descriptions.Item>
          ) : null}
        </Descriptions>
      ) : (
        <Alert
          message="Preço não encontrado. Foi solicitada uma atualização de preços para este ativo. Este quadro será atualizado automaticamente."
          type="warning"
        />
      )}
    </CollapsibleCard>
  );
};

export default AssetPriceDetails;
