import { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  InputNumber,
  Select,
  message,
} from "antd";
import { useQuery } from "@tanstack/react-query";
import { appApi } from "../../api";
import { OptionsMonitor, PriceStatus } from "../../api/models";
import MonitorTable from "./table";
import {
  decodeErrorMessage,
  numberToPercentageDisplay,
} from "../../services/utils";
import { numberToCurrency } from "../../services/utils";
import { formatDateTime } from "../../services/dates";
import CollapsibleCard from "../../components/collapsibleCard";

interface FormValues {
  ticker: string;
  expirationDate: string;
  volatility?: number;
  price?: number;
  strikes: number;
}

const OptionsMonitorPage = () => {
  const [ticker, setTicker] = useState("BOVA11");
  const [expirationDate, setExpirationDate] = useState<string>("");
  const [volatility, setVolatility] = useState<number | undefined>(15);
  const [price, setPrice] = useState<number | undefined>();
  const [strikes, setStrikes] = useState<number>(5);
  const [dynamicRefetchInterval, setDynamicRefetchInterval] = useState(60000);
  const [isMissingPrices, setIsMissingPrices] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);

  const { data: expirationDatesList, isLoading: isLoadingExpirationDates } =
    useQuery({
      queryKey: ["optionsMonitorExpirationDatesList"],
      queryFn: () =>
        appApi.optionMonthlyExpirationDates.get({
          minDaysDistance: 0,
          maxDaysDistance: 160,
        }),
    });

  useEffect(() => {
    if (expirationDatesList && expirationDatesList.length > 0) {
      setExpirationDate(expirationDatesList[0]);
    }
  }, [expirationDatesList]);

  const { data, isLoading, error } = useQuery<OptionsMonitor>({
    queryKey: [
      "optionsMonitor",
      ticker,
      expirationDate,
      volatility,
      price,
      strikes,
      refreshCounter,
    ],
    queryFn: () =>
      appApi.optionsMonitor.get({
        ticker,
        expirationDate,
        volatility,
        price,
        strikes,
      }),
    enabled: expirationDate !== "",
    refetchInterval: dynamicRefetchInterval,
    retry: true,
    retryDelay: 20000,
  });

  // se algum dos registros não tiver preço, exibir mensagem de aviso
  useEffect(() => {
    if (data?.records) {
      const hasPrice = data.records.every((record) => {
        return (
          record?.lastPriceDate !== null &&
          (record?.priceStatus === PriceStatus.UPDATED ||
            (record?.priceStatus === PriceStatus.MARKET_CLOSED &&
              !data.isMarketOpen))
        );
      });
      setDynamicRefetchInterval(hasPrice ? 180000 : 10000);
      setIsMissingPrices(!hasPrice);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      message.error(decodeErrorMessage(error));
    }
  }, [error]);

  if (isLoadingExpirationDates || expirationDate === "") {
    return <Typography.Text>Carregando...</Typography.Text>;
  }

  return (
    <>
      <CollapsibleCard title="Parâmetros" bordered={true} collapsible={true}>
        <Form
          layout="inline"
          initialValues={{ ticker, expirationDate, volatility, price, strikes }}
          onFinish={(values: FormValues) => {
            setTicker(values.ticker);
            setExpirationDate(values.expirationDate);
            setVolatility(values.volatility);
            setPrice(values.price);
            setStrikes(values.strikes);
            setRefreshCounter(refreshCounter + 1);
          }}
          style={{ marginBottom: "20px" }}
        >
          <Form.Item
            name="ticker"
            label="Código do Ativo"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="expirationDate"
            label="Vencimento"
            rules={[{ required: true }]}
          >
            <Select style={{ minWidth: 150 }}>
              {expirationDatesList?.map((date) => (
                <Select.Option key={date} value={date}>
                  {date}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="volatility"
            label="Volatilidade padrão"
            rules={[{ required: false }]}
            tooltip="Volatilidade padrão para o cálculo do preço das opções quando não é possível obter a volatilidade implícita da opção no mercado."
          >
            <InputNumber suffix="%" precision={2} />
          </Form.Item>
          <Form.Item
            name="strikes"
            label="Strikes"
            rules={[{ required: false }]}
            tooltip="Número de strikes a partir do preço do ativo."
          >
            <InputNumber max={30} min={1} precision={0} />
          </Form.Item>
          <Form.Item className="flex justify-center">
            <Button type="primary" htmlType="submit">
              Buscar
            </Button>
          </Form.Item>
          <Form.Item>
            Preço do Ativo: {numberToCurrency(data?.baseTickerPrice)}
          </Form.Item>
          <Form.Item>
            Data do preço:{" "}
            {data?.baseTickerPriceUpdatedAt
              ? formatDateTime(data?.baseTickerPriceUpdatedAt)
              : "Sem preço"}
          </Form.Item>
          <Form.Item>
            Dias úteis até o vencimento: {data?.businessDays}
          </Form.Item>
          <Form.Item>
            Volatilidade justa:{" "}
            {data?.fairBaseTickerVolatility
              ? numberToPercentageDisplay(data.fairBaseTickerVolatility * 100)
              : "N/D"}
          </Form.Item>
          {isMissingPrices && (
            <Form.Item>
              <Typography.Text type="warning">
                Alguns registros não possuem preço ou os preços estão
                desatualizados. A tabela será atualizada automaticamente até que
                todos os preços tenham sido obtidos.
              </Typography.Text>
            </Form.Item>
          )}
        </Form>
      </CollapsibleCard>
      <MonitorTable
        optionsMonitor={data}
        isLoading={isLoading}
        currentPrice={data?.baseTickerPrice}
      />
    </>
  );
};

export default OptionsMonitorPage;
