import { Button, List, Typography } from "antd";
import { ListResponse } from "../../api/types";
import { Communication } from "../../api/models";
import { formatDateTime } from "../../services/dates";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ReadOutlined } from "@ant-design/icons";
import { appApi } from "../../api";
import { communicationsQueryKey } from "./constants";

const MessagesList = ({ data }: { data: ListResponse<Communication> }) => {
  const queryClient = useQueryClient();
  const { mutate: markAsRead } = useMutation({
    mutationFn: ({ id, read }: { id: string; read: boolean }) =>
      appApi.communications.update({ id, read }),
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: [communicationsQueryKey],
      });
    },
  });

  return (
    <List
      size="small"
      pagination={{ pageSize: 5 }}
      locale={{ emptyText: "Sem notificações" }}
      dataSource={data?.results}
      renderItem={(item) => {
        const listOfMessageContents = item.message.split("\n");
        const contentMessage = listOfMessageContents.map((content, index) => {
          return (
            <p style={{ marginTop: 1, marginBottom: 1 }} key={index}>
              {content}
            </p>
          );
        });
        return (
          <List.Item key={item.id}>
            <List.Item.Meta
              title={
                <div className="flex flex-row justify-between">
                  <div style={{ fontWeight: item.read ? "600" : "1000" }}>
                    {formatDateTime(item.created)} - {item.title}
                  </div>
                  {!item.read && (
                    <Button
                      type="default"
                      onClick={() => markAsRead({ id: item.id, read: true })}
                    >
                      <ReadOutlined />
                    </Button>
                  )}
                </div>
              }
              description={
                <Typography.Paragraph
                  ellipsis={{
                    rows: 1,
                    expandable: true,
                    symbol: "Ver mais",
                  }}
                >
                  {contentMessage}
                </Typography.Paragraph>
              }
            />
          </List.Item>
        );
      }}
    />
  );
};

export default MessagesList;
