import { useState } from "react";
import type { ListResponse } from "../../api/types";
import { AccountMonthlyIncomeEntry } from "../../api/models";
import { useQuery } from "@tanstack/react-query";
import { appApi } from "../../api";
import { numberToCurrency } from "../../services/utils";
import { useSearchParams } from "react-router-dom";
import ResponsiveTable from "../../components/ResponsiveTable";
import CollapsibleCard from "../../components/collapsibleCard";
import PriceDisplay from "../../components/priceDisplay";
import { Radio, Form } from "antd";
import TableStringFilter from "../../components/tableStringFilter";
import { EditMonthlyIncomeEntryRecord } from "./editMonthlyIncomeEntryRecord";
import TickerDetailsLink from "../../components/tickerDetailsLink";

const renderIncomeDetails = (record: AccountMonthlyIncomeEntry) => {
  // se for evento, exibe as datas
  if (record?.event) {
    return `Data COM: ${record.event.comDate}, Valor: ${numberToCurrency(
      record.event.eventAmount,
      4,
    )}, Qtd: ${record.event.stockAmount}`;
  }
  // se for operacao com opcao e exercicio e preco 0, é a zeragem da opcao apenas
  if (record?.stockOperation && parseFloat(record.stockOperation.price) === 0) {
    return `Zeragem da opção após exercício / expiração.`;
  }
  // se for operacao com opcao e exercicio
  if (record?.stockOperation && record.stockOperation.isExercise) {
    return `Exercício de opção. Ativo: ${
      record?.incomeDetails?.underlyingAsset
    }, Qtd: ${
      record?.incomeDetails?.amount
    }, Preço exercício: ${numberToCurrency(
      record?.incomeDetails?.exercisePrice,
    )}, Preço mercado: ${numberToCurrency(
      record?.incomeDetails?.underlyingAssetCurrentPrice,
    )}, Qtd: ${record.stockOperation.amount}`;
  }
  // se for operacao com opcao, exibe o tipo Compra / venda a partir do amount
  if (record?.stockOperation) {
    return record.stockOperation.amount > 0
      ? `Compra de opção. PM: ${numberToCurrency(
          record.stockOperation.price,
        )}, Qtd: ${record.stockOperation.amount}`
      : `Venda de opção. PM: ${numberToCurrency(
          record.stockOperation.price,
        )}, Qtd: ${record.stockOperation.amount}`;
  }
  // para mini indice exibe os dados do preco anterior, novo preco e quantidade
  const incD = record?.incomeDetails;
  return `Preço anterior: ${numberToCurrency(
    incD?.previousClosePrice,
  )}, Novo preço: ${numberToCurrency(incD?.closePrice)}, Quantidade: ${
    incD?.amount
  }, Qtd operada no dia: ${
    incD?.addedAmountAtDate
  }, preço Médio operado: ${numberToCurrency(incD?.averagePriceAtDate)}`;
};

const MonthlyIncomeRecordEntries = () => {
  const queryKey = "monthlyIncomeEntries";
  const [page, setPage] = useState(1);
  const [form] = Form.useForm();

  const [searchParams, setSearchParams] = useSearchParams();
  const typeFilter = searchParams.get("type") || "all";
  const year = searchParams.get("year") || new Date().getFullYear().toString();
  const month = searchParams.get("month") || new Date().getMonth().toString();
  const asset = searchParams.get("asset") || "";

  const { data, isFetching, isLoading } = useQuery<
    ListResponse<AccountMonthlyIncomeEntry>
  >({
    queryKey: [queryKey, page, year, month, typeFilter, asset],
    queryFn: () =>
      appApi.pnl.getAccountMonthlyIncomeEntries(
        page,
        year,
        month,
        typeFilter,
        asset,
      ),
  });

  const columns = [
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Renda",
      dataIndex: "totalIncome",
      render: (value: string, record: AccountMonthlyIncomeEntry) => (
        <PriceDisplay
          price={value}
          hasOverride={record.overridenIncome !== null}
        />
      ),
    },
    {
      title: "Ativo",
      dataIndex: "id",
      render: (_: string, record: AccountMonthlyIncomeEntry) =>
        record?.incomeDetails?.ticker ? (
          <TickerDetailsLink ticker={record.incomeDetails.ticker} />
        ) : (
          ""
        ),
    },
    {
      title: "Tipo",
      dataIndex: "id",
      render: (_: string, record: AccountMonthlyIncomeEntry) =>
        record?.event
          ? record.event.eventTypeDisplay
          : record?.stockOperation
            ? "Operação com opção"
            : "Ajuste de mini índice",
    },
    {
      title: "Detalhes",
      dataIndex: "id",
      render: (_: string, record: AccountMonthlyIncomeEntry) =>
        renderIncomeDetails(record),
    },
    {
      title: "Editar",
      dataIndex: "id",
      render: (_: any, record: AccountMonthlyIncomeEntry) => (
        <EditMonthlyIncomeEntryRecord
          id={record.id}
          overridenIncome={record.overridenIncome}
          asset={record.incomeDetails?.ticker}
          date={record.date}
        />
      ),
    },
  ];

  const setFilter = (value: string | null) => {
    if (!value) {
      value = "";
    }
    setPage(1);
    setSearchParams({
      type: typeFilter,
      year: year,
      month: month,
      asset: value,
    });
  };

  return (
    <CollapsibleCard title="Detalhes da renda" collapsible={false}>
      <Form form={form} component={false}>
        <ResponsiveTable
          dataSource={data?.results}
          columns={columns}
          loading={isFetching || isLoading}
          title={() => (
            <div className="flex-1 flex justify-between flex-row w-full mb-2">
              <Radio.Group
                onChange={(e) => {
                  setPage(1); // reset page when changing filters
                  setSearchParams({
                    type: e.target.value,
                    year: year,
                    month: month,
                    asset: asset,
                  });
                }}
                value={typeFilter}
                className="mb-2"
              >
                <Radio.Button value="all">Todos</Radio.Button>
                <Radio.Button value="dividends">Dividendos</Radio.Button>
                <Radio.Button value="options">
                  Operações com opções
                </Radio.Button>
                <Radio.Button value="mini_index">
                  Ajustes de mini índice
                </Radio.Button>
              </Radio.Group>
              <TableStringFilter label="Ativo" setFilter={setFilter} />
            </div>
          )}
          size="small"
          pagination={{
            current: page,
            onChange: (page) => {
              setPage(page);
            },
            total: data?.count,
            pageSize: 10,
            showSizeChanger: false,
          }}
          rowKey="id"
        />
      </Form>
    </CollapsibleCard>
  );
};

export default MonthlyIncomeRecordEntries;
