import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="404"
      title="404"
      subTitle="Oops, a página que você está procurando não existe ou ocorreu um erro ao tentar exibi-la."
      extra={
        <Button type="primary" onClick={() => navigate("/")}>
          Voltar para o início
        </Button>
      }
    />
  );
};

export default PageNotFound;
