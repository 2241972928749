import type { ListResponse } from "../../api/types";
import { StockOperation } from "../../api/models";
import { useQuery } from "@tanstack/react-query";
import { appApi } from "../../api";
import { Link } from "react-router-dom";
import { TabKeys } from "../wallet/positions";
import CollapsibleCard from "../../components/collapsibleCard";
import TickerDetailsLink from "../../components/tickerDetailsLink";
import ResponsiveTable from "../../components/ResponsiveTable";
import PriceDisplay from "../../components/priceDisplay";

const LastOperationsTable = () => {
  const queryKey = "lastOperationsHistory";
  const { data, isLoading } = useQuery<ListResponse<StockOperation>>({
    queryKey: [queryKey],
    queryFn: () => appApi.stockOperations.getList(1, undefined, 5),
  });

  const columns = [
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Ticker",
      dataIndex: "ticker",
      key: "ticker",
      render: (value: string) => <TickerDetailsLink ticker={value} />,
    },
    {
      title: "Quantidade",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Preço",
      dataIndex: "price",
      key: "price",
      render: (value: string) => <PriceDisplay price={value} />,
    },
  ];

  const title = (
    <div className="flex flex-row justify-between items-center w-full">
      <div className="font-semibold">Últimas operações</div>
      <div>
        <Link to={`/positions/?tab=${TabKeys.TRANSACTIONS}`}>Ver todas</Link>
      </div>
    </div>
  );

  return (
    <CollapsibleCard title={title} defaultCollapsed={false}>
      <ResponsiveTable
        dataSource={data?.results}
        columns={columns}
        loading={isLoading}
        className="w-full"
        size={"small"}
        pagination={false}
        rowKey="id"
      />
    </CollapsibleCard>
  );
};

export default LastOperationsTable;
