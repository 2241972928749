import { Row, Col } from "antd";
import LastOperationsTable from "./lastOperations";
import LastWalletEventsTable from "./lastWalletEvents";
import ValueAllocationChart from "./valueAllocationChart";
import PnlChart from "./pnlChart";
import IncomeChart from "./incomeChart";
import OptionPositionsIndicator from "./optionPositionsIndicator";
import CashflowIndicator from "./cashflowIndicator";

const DashboardPage = () => {
  return (
    <Row>
      <Col lg={24} xl={16}>
        <Row>
          <PnlChart />
        </Row>
        <Row>
          <IncomeChart />
        </Row>
      </Col>
      <Col sm={24} md={12} lg={24} xl={8} className="w-full">
        <Col className="w-full">
          <Row>
            <OptionPositionsIndicator />
          </Row>
          <Row>
            <CashflowIndicator />
          </Row>
          <Row>
            <ValueAllocationChart />
          </Row>
          <Row>
            <Col className="w-full">
              <LastOperationsTable />
            </Col>
          </Row>
          <Row>
            <Col className="w-full">
              <LastWalletEventsTable />
            </Col>
          </Row>
        </Col>
      </Col>
    </Row>
  );
};

export default DashboardPage;
