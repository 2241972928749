import { Tooltip, Typography } from "antd";
import { useAssetDetailsDrawer } from "../providers/assetDetailsProvider";

const { Text } = Typography;

const TickerDetailsLink = ({
  ticker,
  displayedText,
}: {
  ticker: string;
  displayedText?: string;
}) => {
  const { showTickerDetails } = useAssetDetailsDrawer();
  return (
    <div className="whitespace-nowrap">
      <Text copyable={{ text: ticker }}>
        <Tooltip title={ticker}>
          <Typography.Link onClick={() => showTickerDetails({ ticker })}>
            {displayedText ? displayedText : ticker}
          </Typography.Link>
        </Tooltip>
      </Text>
    </div>
  );
};

export default TickerDetailsLink;
