import { useQuery } from "@tanstack/react-query";
import { appApi } from "../../api";
import { Descriptions, Drawer, Col } from "antd";
import CollapsibleCard from "../collapsibleCard";
import { assetCategoryDecoder } from "../../api/models";
import PriceHistoryTable from "./priceHistoryList";
import DividendsHistoryTable from "./dividendsHistoryList";
import SplitHistoryTable from "./stockSplitHistoryList";
import BonificationHistoryTable from "./bonificationHistoryList";
import PriceHistoryChart from "./priceHistoryChart";
import AssetPriceDetails from "./assetPrice";
import { numberToCurrency } from "../../services/utils";

const AssetDetailsDrawer = ({
  open,
  onClose,
  ticker,
}: {
  open: boolean;
  onClose: () => void;
  ticker: string;
}) => {
  const stockTypes = ["FUNDS", "BDR", "UNIT", "SHARES", "ETF EQUITIES"];
  // se ticker terminado em E, ajusta removendo
  const adjustedTicker = ticker.endsWith("E") ? ticker.slice(0, -1) : ticker;
  const { data: assetData, isLoading } = useQuery({
    queryKey: ["asset", adjustedTicker],
    queryFn: () =>
      appApi.resources.getInstruments(adjustedTicker as string, true),
    enabled: adjustedTicker !== "" && adjustedTicker !== undefined,
  });

  const assetDetails =
    assetData?.results && assetData?.results.length > 0
      ? assetData.results[0]
      : undefined;
  if (!adjustedTicker || (!assetDetails && !isLoading)) {
    return null;
  }

  return (
    <Drawer
      size="large"
      open={open}
      onClose={onClose}
      title={`Detalhes do ativo ${assetDetails?.ticker}`}
    >
      <Col style={{ width: "95%" }}>
        <CollapsibleCard
          title="Detalhes do Ativo"
          loading={isLoading}
          className="w-full"
        >
          <Descriptions column={2}>
            <Descriptions.Item label="Código do ativo">
              {assetDetails?.ticker}
            </Descriptions.Item>
            <Descriptions.Item label="Categoria">
              {assetDetails?.category
                ? assetCategoryDecoder(assetDetails?.category)
                : "Não informado"}
            </Descriptions.Item>
            <Descriptions.Item label="É opção?">
              {assetDetails?.optionType ? "Sim" : "Não"}
            </Descriptions.Item>
            <Descriptions.Item label="Lote padrão">
              {assetDetails?.lotSize}
            </Descriptions.Item>
          </Descriptions>
          {assetDetails?.optionType && (
            <Descriptions column={2} title="Detalhes da opção" size="small">
              <Descriptions.Item label="Tipo">
                {assetDetails?.optionType}
              </Descriptions.Item>
              <Descriptions.Item label="Ativo relacionado">
                {assetDetails?.asset}
              </Descriptions.Item>
              <Descriptions.Item label="Data de vencimento">
                {assetDetails?.expirationDt}
              </Descriptions.Item>
              <Descriptions.Item label="Início da negociação">
                {assetDetails?.tradingStartDt}
              </Descriptions.Item>
              <Descriptions.Item label="Fim da negociação">
                {assetDetails?.tradingEndDt}
              </Descriptions.Item>
              <Descriptions.Item label="Preço de exercício">
                {numberToCurrency(assetDetails?.exercisePrice)}
              </Descriptions.Item>
              <Descriptions.Item label="Estilo da opção">
                {assetDetails?.optionStyle}
              </Descriptions.Item>
            </Descriptions>
          )}
        </CollapsibleCard>
        <AssetPriceDetails asset={adjustedTicker} />
        <PriceHistoryChart asset={adjustedTicker} />
        <PriceHistoryTable asset={adjustedTicker} />
        {stockTypes.includes(assetDetails?.category || "N/A") && (
          <DividendsHistoryTable asset={adjustedTicker} />
        )}
        {stockTypes.includes(assetDetails?.category || "N/A") && (
          <BonificationHistoryTable asset={adjustedTicker} />
        )}
        {stockTypes.includes(assetDetails?.category || "N/A") && (
          <SplitHistoryTable asset={adjustedTicker} />
        )}
      </Col>
    </Drawer>
  );
};

export default AssetDetailsDrawer;
