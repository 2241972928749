import { CurrentPosition, Wallet } from "../../../../api/models";
import { List, Button } from "antd";
import CollapsibleCard from "../../../../components/collapsibleCard";
import { SelectedTickerConfig } from "./picturesWallet";
import { UseMutationResult } from "@tanstack/react-query";

const AvailableItems = ({
  valueWallet,
  monitoredKeys,
  editWalletMutation,
  setMonitoredKeys,
}: {
  valueWallet: Wallet;
  monitoredKeys: SelectedTickerConfig[];
  editWalletMutation: UseMutationResult;
  setMonitoredKeys: React.SetStateAction<any>;
}) => {
  const addTickerToConfig = (ticker: string) => {
    const newSelectedKeys = [...monitoredKeys];
    newSelectedKeys.push({
      ticker,
      targetPercentage: 50,
    });
    editWalletMutation.mutateAsync(newSelectedKeys);
    setMonitoredKeys(newSelectedKeys);
  };

  return (
    <CollapsibleCard
      title="Posições disponíveis para monitorar"
      classNameBody="flex align-middle justify-center items-center"
    >
      <List
        dataSource={
          valueWallet.positions
            .map((position) => {
              if (position.optionType) {
                return null;
              }
              if (
                monitoredKeys.some((item) => item.ticker === position.ticker)
              ) {
                return null;
              }
              return position;
            })
            .filter((item) => item !== null) as any
        }
        style={{ width: "100%" }}
        pagination={{ pageSize: 5 }}
        renderItem={(item: CurrentPosition) => (
          <List.Item style={{ width: "100%" }}>
            <List.Item.Meta
              title={item.ticker}
              description={`Quantidade em carteira: ${item.amount}`}
            />
            <Button onClick={() => addTickerToConfig(item.ticker)}>
              Monitorar
            </Button>
          </List.Item>
        )}
      />
    </CollapsibleCard>
  );
};

export default AvailableItems;
