export const walletPnlColumnMap = [
  {
    label: "Ações",
    dataIndex: "acoes",
  },
  {
    label: "Internacional",
    dataIndex: "internacional",
  },
  {
    label: "Renda",
    dataIndex: "renda",
  },
  {
    label: "Carrego",
    dataIndex: "carrego",
  },
  {
    label: "Figuras",
    dataIndex: "figuras",
  },
  {
    label: "FIIs",
    dataIndex: "fundosImobiliarios",
  },
  {
    label: "Fundos do agronegócio",
    dataIndex: "fundosDoAgronegocio",
  },
  {
    label: "Outros",
    dataIndex: "outros",
  },
];
