import { useState } from "react";
import { Avatar, Badge, Space } from "antd";
import { PlusSquareOutlined } from "@ant-design/icons";
import NewOperationModal from "./newOperationModal";

const GlobalNewOperation = () => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Space size="middle">
        <Badge size="default">
          <Avatar
            shape="square"
            icon={<PlusSquareOutlined size={30} />}
            onClick={() => setVisible(!visible)}
            style={{ fontSize: "24px", cursor: "pointer" }}
          />
        </Badge>
      </Space>
      <NewOperationModal
        visible={visible}
        onComplete={() => setVisible(false)}
      />
    </>
  );
};

export default GlobalNewOperation;
