import { useState } from "react";
import type { ListResponse } from "../../../api/types";
import { PositionEvent } from "../../../api/models";
import { useQuery } from "@tanstack/react-query";
import { appApi } from "../../../api";
import TickerDetailsLink from "../../../components/tickerDetailsLink";
import CollapsibleCard from "../../../components/collapsibleCard";
import ResponsiveTable from "../../../components/ResponsiveTable";
import PriceDisplay from "../../../components/priceDisplay";
import TableStringFilter from "../../../components/tableStringFilter";

const PositionEventHistory = () => {
  const queryKey = "positionEventsHistory";
  const [page, setPage] = useState(1);

  const [filteredTicker, setFilteredTicker] = useState<string | null>(null);
  const [sortedColumn, setSortedColumn] = useState<string | null>(null);
  const [sortedOrder, setSortedOrder] = useState<string | null>(null);

  const { data, isFetching } = useQuery<ListResponse<PositionEvent>>({
    queryKey: [queryKey, page, filteredTicker, sortedColumn, sortedOrder],
    queryFn: () =>
      appApi.positionEvent.getList(
        page,
        filteredTicker,
        10,
        sortedColumn,
        sortedOrder,
      ),
  });

  const columns = [
    {
      title: "Data Com",
      dataIndex: "comDate",
      key: "comDate",
    },
    {
      title: "Ticker",
      dataIndex: "ticker",
      key: "ticker",
      render: (value: string) => <TickerDetailsLink ticker={value} />,
      sorter: true,
    },
    {
      title: "Tipo",
      dataIndex: "eventTypeDisplay",
      key: "eventTypeDisplay",
    },
    {
      title: "Razão / Dividendo",
      dataIndex: "eventAmount",
      key: "eventAmount",
    },
    {
      title: "Total de Ações",
      dataIndex: "stockAmount",
      key: "stockAmount",
      render: (value: string) => (value ? value : "N/A"),
    },
    {
      title: "Total financeiro",
      dataIndex: "financialAmount",
      key: "financialAmount",
      render: (value: string) => <PriceDisplay price={value} />,
    },
    {
      title: "Data de Pagamento",
      dataIndex: "payDate",
      key: "payDate",
      sorter: true,
    },
  ];

  const setFilter = (value: string | null) => {
    setFilteredTicker(value);
  };
  const handleTableChange = (_: any, __: any, sorter: any) => {
    if (sorter) {
      setSortedColumn(sorter.field);
      setSortedOrder(sorter?.order === "ascend" ? "asc" : "desc");
    }
  };
  return (
    <CollapsibleCard
      title="Histórico de Eventos de Posição"
      collapsible={false}
    >
      <ResponsiveTable
        title={() => <TableStringFilter label="Ativo" setFilter={setFilter} />}
        dataSource={data?.results}
        columns={columns}
        loading={isFetching}
        size="small"
        pagination={{
          current: page,
          onChange: (page) => {
            setPage(page);
          },
          total: data?.count,
          pageSize: 10,
          showSizeChanger: false,
        }}
        rowKey="id"
        onChange={handleTableChange}
      />
    </CollapsibleCard>
  );
};

export default PositionEventHistory;
