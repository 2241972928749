import ResponsiveTable from "../../components/ResponsiveTable";
import "./styles.css";
import { OptionsMonitor, OptionsMonitorRecord } from "../../api/models";
import { numberToPercentageDisplay } from "../../services/utils";
import TickerDetailsLink from "../../components/tickerDetailsLink";
import CollapsibleCard from "../../components/collapsibleCard";
import PriceDisplay from "../../components/priceDisplay";
import PriceDetails from "../../components/priceDetails";

type MonitorTableRecord = {
  key: string;
  call: OptionsMonitorRecord;
  put: OptionsMonitorRecord | undefined;
};

const MonitorTable = ({
  optionsMonitor,
  isLoading,
  currentPrice,
}: {
  optionsMonitor?: OptionsMonitor;
  isLoading: boolean;
  currentPrice?: number;
}) => {
  const records: OptionsMonitorRecord[] = optionsMonitor?.records || [];
  const callsList = records.filter(
    (record) => record.type.toUpperCase() === "CALL",
  );
  const putsList = records.filter(
    (record) => record.type.toUpperCase() === "PUT",
  );
  const resultList: MonitorTableRecord[] = callsList.map((callRecord) => {
    const putRecord = putsList.find(
      (putRecord) => putRecord.strike === callRecord.strike,
    );
    return {
      key: callRecord.ticker,
      call: callRecord,
      put: putRecord,
    };
  });
  const strikeJustAbovePrice = currentPrice
    ? resultList.find((record) => record.call.strike > currentPrice)?.call
        ?.strike
    : undefined;

  const columns = [
    {
      title: "Código da Call",
      dataIndex: "call",
      key: "callTicker",
      render: (value: OptionsMonitorRecord) => (
        <TickerDetailsLink ticker={value?.ticker} />
      ),
    },
    {
      title: "Preço da Call",
      dataIndex: "call",
      key: "callLastPrice",
      render: (value: OptionsMonitorRecord) =>
        value?.lastPrice ? (
          <PriceDetails
            price={value.lastPrice}
            priceAsk={value.priceAsk}
            priceBid={value.priceBid}
            priceStatus={value.priceStatus}
            liquidityStatus={value.liquidityStatus}
            priceDate={value.lastPriceDate}
            showAsTooltip={true}
          />
        ) : (
          "Não disponível"
        ),
    },
    {
      title: "Preço da Call (BS)",
      dataIndex: "call",
      key: "callBsPrice",
      render: (value: OptionsMonitorRecord) =>
        value?.bsPrice !== null && value?.bsPrice !== undefined ? (
          <PriceDisplay price={value.bsPrice} />
        ) : (
          "N/D"
        ),
    },
    {
      title: "Delta da Call",
      dataIndex: "call",
      key: "callDelta",
      render: (value: OptionsMonitorRecord) => value?.deltaDisplay,
    },
    {
      title: "Volatilidade da Call",
      dataIndex: "call",
      key: "callVolatility",
      render: (value: OptionsMonitorRecord) =>
        value?.volatility !== undefined ? (
          value?.volatilityStatus !== "Mercado" ? (
            <span style={{ color: "red" }}>
              {numberToPercentageDisplay(value.volatility)}
            </span>
          ) : (
            numberToPercentageDisplay(value.volatility)
          )
        ) : (
          "N/D"
        ),
    },
    {
      title: "Strike",
      dataIndex: "call",
      key: "strike",
      render: (value: OptionsMonitorRecord) =>
        value?.strike !== undefined ? (
          <PriceDisplay price={value?.strike} />
        ) : (
          "N/D"
        ),
    },
    {
      title: "Volatilidade da Put",
      dataIndex: "put",
      key: "putVolatility",
      render: (value: OptionsMonitorRecord) =>
        value?.volatility !== undefined ? (
          value?.volatilityStatus !== "Mercado" ? (
            <span style={{ color: "red" }}>
              {numberToPercentageDisplay(value.volatility)}
            </span>
          ) : (
            numberToPercentageDisplay(value.volatility)
          )
        ) : (
          "N/D"
        ),
    },
    {
      title: "Delta da Put",
      dataIndex: "put",
      key: "putDelta",
      render: (value: OptionsMonitorRecord) => value?.deltaDisplay,
    },
    {
      title: "Preço da Put (BS)",
      dataIndex: "put",
      key: "putBsPrice",
      render: (value: OptionsMonitorRecord) =>
        value?.bsPrice !== undefined ? (
          <PriceDisplay price={value?.bsPrice} />
        ) : (
          "N/D"
        ),
    },
    {
      title: "Preço da Put",
      dataIndex: "put",
      key: "putLastPrice",
      render: (value: OptionsMonitorRecord) =>
        value?.lastPrice ? (
          <PriceDetails
            price={value.lastPrice}
            priceAsk={value.priceAsk}
            priceBid={value.priceBid}
            priceStatus={value.priceStatus}
            liquidityStatus={value.liquidityStatus}
            priceDate={value.lastPriceDate}
            showAsTooltip={true}
          />
        ) : (
          "Não disponível"
        ),
    },
    {
      title: "Código da Put",
      dataIndex: "put",
      key: "putTicker",
      render: (value: OptionsMonitorRecord) => (
        <TickerDetailsLink ticker={value?.ticker} />
      ),
    },
  ];

  const rowClassName = (record: MonitorTableRecord) => {
    if (record.call.strike === strikeJustAbovePrice) {
      return "highlight-top-border";
    }
    return "";
  };

  return (
    <CollapsibleCard title="Monitor de Opções" collapsible={false}>
      <ResponsiveTable
        dataSource={resultList}
        columns={columns}
        columnSize={24}
        size="small"
        loading={isLoading}
        rowKey="key"
        rowClassName={rowClassName}
        pagination={false}
        priorityKeys={[
          "callDelta",
          "strike",
          "putDelta",
          "callTicker",
          "putTicker",
          "callPrice",
          "putPrice",
        ]}
      />
    </CollapsibleCard>
  );
};

export default MonitorTable;
