import { CurrentPosition } from "../../../api/models";
import { generateRandomColors } from "../../../services/utils";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useEffect } from "react";

ChartJS.register(ArcElement, Tooltip, Legend);

const ValueWalletAllocationChart = ({
  positions,
}: {
  positions: CurrentPosition[];
}) => {
  if (!positions || positions.length === 0) {
    return <p>Nenhuma posição ainda na carteira.</p>;
  }
  const colors = generateRandomColors(positions.length);
  const colorsRGBA = colors.map((color) => `rgba(${color}, 0.8)`);

  // subscribing to the visibility changed event and re-rendering the chart whenever the tab transitions to visible
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        const chart = ChartJS.getChart("allocationChart");
        if (chart) {
          chart.update();
        }
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const data = {
    labels: positions.map((item) => item.ticker),
    datasets: [
      {
        label: "Total da posição",
        data: positions.map((item) => item.positionTotal),
        backgroundColor: colorsRGBA,
      },
    ],
  };

  return <Doughnut data={data} id="allocationChart" />;
};

export default ValueWalletAllocationChart;
