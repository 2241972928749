import { WalletNames } from "../../../api/models";
import ValueWalletTemplate from "./valueWalletTemplate";

const InternationalWallet = () => {
  return (
    <ValueWalletTemplate
      walletName={WalletNames.INTERNATIONAL}
      title="Carteira Internacional"
      descriptionTwoTitle="Boas práticas"
      descriptionTwo={
        <>
          <p>Realizar a utilização de ETFs para diversificação de carteira.</p>
          <p>
            A carteira internacional deve servir como Hedge cambial que trás
            equilíbrio para a carteira.
          </p>
        </>
      }
    />
  );
};

export default InternationalWallet;
