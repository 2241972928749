import { CurrentPosition, Wallet, WalletNames } from "../../../../api/models";
import DeltaHedgeOptionsMonitor, {
  MonitorTab,
  OperationType,
  OptionType,
} from "../dhOptionsMonitor";
import { decodeOptionAction } from "../../../../api/models";
import CollapsibleCard from "../../../../components/collapsibleCard";
import WalletItemList from "../../walletItemsList";
import { Row } from "antd";
import OptionDetails from "../../../../components/optionDetails";
import DhNotificationMode from "../dhNotificationMode";

const columns = [
  {
    title: "Delta da opção",
    dataIndex: "optionDeltaDisplay",
    key: "optionDeltaDisplay",
    render: (value: string, record: CurrentPosition) => {
      return (
        <div>
          <span className="mr-2">{value}</span>
          <OptionDetails position={record} showAsTooltip />
        </div>
      );
    },
  },
  {
    title: "Ação a realizar",
    dataIndex: "optionActionToPerform",
    key: "optionActionToPerform",
    render: (value: string) => (value ? decodeOptionAction(value) : ""),
  },
  {
    title: "Notificações de DH",
    dataIndex: "positionNotificationFreezeMode",
    render: (_: string, record: CurrentPosition) => {
      return <DhNotificationMode currentPosition={record} />;
    },
  },
];

const AssetTab = ({
  asset,
  picturesWallet,
}: {
  asset: string;
  picturesWallet: Wallet;
}) => {
  const monitorTabs: MonitorTab[] = [
    {
      key: `${asset}-sell-call`,
      ticker: asset,
      optionType: OptionType.CALL,
      minDelta: 18,
      maxDelta: 35,
      label: `CALLs a vender`,
      operationType: OperationType.SELL,
    },
    {
      key: `${asset}-buy-call`,
      ticker: asset,
      optionType: OptionType.CALL,
      minDelta: 4,
      maxDelta: 10,
      label: `CALLs a comprar`,
      operationType: OperationType.BUY,
    },
    {
      key: `${asset}-put-sell`,
      ticker: asset,
      optionType: OptionType.PUT,
      minDelta: 40,
      maxDelta: 60,
      label: `PUTs a vender`,
      operationType: OperationType.SELL,
    },
    {
      key: `${asset}-put-buy`,
      ticker: asset,
      optionType: OptionType.PUT,
      minDelta: 20,
      maxDelta: 32,
      label: `PUTs a comprar`,
      operationType: OperationType.BUY,
    },
  ];

  const renderedPositions = picturesWallet.positions.filter((position) =>
    asset === "all" ? true : position.optionUnderlyingAsset === asset,
  );

  return (
    <>
      <Row>
        <WalletItemList
          title={
            asset === "all"
              ? "Todos os ativos da carteira de Figuras"
              : "Posições de opções do ativo selecionado"
          }
          positions={renderedPositions}
          strategies={picturesWallet?.strategies}
          isLoading={false}
          additionalColumns={columns}
          walletName={WalletNames.PICTURES}
          walletId={picturesWallet.id}
        />
      </Row>
      {asset === "all" ? null : (
        <Row>
          <CollapsibleCard title="Opções disponívels para negociar">
            <DeltaHedgeOptionsMonitor
              key={monitorTabs.length}
              monitorTabs={monitorTabs}
            />
          </CollapsibleCard>
        </Row>
      )}
    </>
  );
};

export default AssetTab;
