import { Form, Input, Select, Row, Col, Button } from "antd";
import CollapsibleCard from "../../components/collapsibleCard";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { B3Instrument } from "../../api/models";
import { ListResponse } from "../../api/types";
import { appApi } from "../../api";
import TickerDetailsLink from "../../components/tickerDetailsLink";
import { assetCategoryDecoder } from "../../api/models";
import ResponsiveTable from "../../components/ResponsiveTable";

type FormValues = {
  assetCode: string;
  exactSearch: boolean;
  optionType: string;
  assetCategory: string;
  strikeGreaterThan: number;
  strikeLessThan: number;
  relatedAsset: string;
};

const AssetSearch = () => {
  const [page, setPage] = useState(1);
  const [assetTicker, setAssetTicker] = useState<string | undefined>(undefined);
  const [exactSearch, setExactSearch] = useState(false);
  const [optionType, setOptionType] = useState<string | undefined>(undefined);
  const [assetCategory, setAssetCategory] = useState<string | undefined>(
    undefined,
  );
  const [strikeGreaterThan, setStrikeGreaterThan] = useState<
    number | undefined
  >(undefined);
  const [strikeLessThan, setStrikeLessThan] = useState<number | undefined>(
    undefined,
  );
  const [relatedAsset, setRelatedAsset] = useState<string | undefined>(
    undefined,
  );

  const { data, isLoading } = useQuery<ListResponse<B3Instrument>>({
    queryKey: [
      "b3istruments",
      assetTicker,
      optionType,
      assetCategory,
      strikeGreaterThan,
      strikeLessThan,
      exactSearch,
      relatedAsset,
      page,
    ],
    queryFn: () =>
      appApi.resources.getInstruments(
        assetTicker,
        exactSearch,
        optionType,
        assetCategory,
        strikeGreaterThan,
        strikeLessThan,
        relatedAsset,
        15,
        page,
      ),
    enabled:
      (assetTicker !== "" && assetTicker !== undefined) ||
      (optionType !== "" && optionType !== undefined) ||
      (assetCategory !== "" && assetCategory !== undefined) ||
      strikeGreaterThan !== undefined ||
      strikeLessThan !== undefined ||
      (relatedAsset !== undefined && relatedAsset !== ""),
  });

  const columns = [
    {
      title: "Código",
      dataIndex: "ticker",
      key: "ticker",
      render: (ticker: string) => <TickerDetailsLink ticker={ticker} />,
    },
    {
      title: "Ativo relacionado",
      dataIndex: "asset",
      key: "asset",
    },
    {
      title: "Categoria",
      dataIndex: "category",
      key: "category",
      render: (category: string) => assetCategoryDecoder(category),
    },
    {
      title: "Tipo",
      dataIndex: "optionType",
      key: "optionType",
    },
    {
      title: "Strike",
      dataIndex: "exercisePrice",
      key: "exercisePrice",
    },
    {
      title: "Data de vencimento",
      dataIndex: "expirationDt",
      key: "expirationDt",
    },
  ];
  const [form] = Form.useForm();
  return (
    <Row className="w-full">
      <Col sm={24} md={12} lg={8} className="w-full">
        <CollapsibleCard title="Busca de ativos">
          <Form
            form={form}
            layout="vertical"
            onFinish={(values: FormValues) => {
              setAssetTicker(values.assetCode);
              setOptionType(values.optionType);
              setAssetCategory(values.assetCategory);
              setStrikeGreaterThan(values.strikeGreaterThan);
              setStrikeLessThan(values.strikeLessThan);
              setExactSearch(values.exactSearch);
              setRelatedAsset(values.relatedAsset);
            }}
          >
            <Form.Item name="assetCode" label="Código do ativo">
              <Input />
            </Form.Item>
            <Form.Item name="relatedAsset" label="Ativo relacionado">
              <Input />
            </Form.Item>
            <Form.Item name="exactSearch" label="Busca exata">
              <Select>
                <Select.Option value={true}>Sim</Select.Option>
                <Select.Option value={false}>Não</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="assetCategory" label="Categoria do ativo">
              <Select>
                <Select.Option value="SHARES">Ação</Select.Option>
                <Select.Option value="BDR">BDR</Select.Option>
                <Select.Option value="ETF EQUITIES">ETF de Ação</Select.Option>
                <Select.Option value="ETF FOREIGN INDEX">
                  ETF de índice do exterior
                </Select.Option>
                <Select.Option value="FUNDS">Fundos</Select.Option>
                <Select.Option value="OPTION ON EQUITIES">
                  Opção de ativo
                </Select.Option>
                <Select.Option value="OPTION ON INDEX">
                  Opção de índice
                </Select.Option>
                <Select.Option value="STOCK FUTURE">
                  Futuro de Ação
                </Select.Option>
                <Select.Option value="UNIT">Unit</Select.Option>
                <Select.Option value="INDEX">Índice</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="optionType" label="Tipo de opção">
              <Select>
                <Select.Option value="Call">Call</Select.Option>
                <Select.Option value="Put">Put</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="strikeGreatherThan" label="Strike maior que">
              <Input type="number" />
            </Form.Item>
            <Form.Item name="strikeLowerThan" label="Strike menor que">
              <Input type="number" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Buscar
              </Button>
            </Form.Item>
          </Form>
        </CollapsibleCard>
      </Col>
      <Col sm={24} md={12} lg={16} className="w-full">
        <CollapsibleCard title="Resultado da busca">
          <ResponsiveTable
            columns={columns}
            dataSource={data?.results || []}
            loading={isLoading}
            pagination={{
              pageSize: 15,
              onChange: (page) => setPage(page),
              total: data?.count,
              pageSizeOptions: [],
              showSizeChanger: false,
            }}
            size="small"
          />
        </CollapsibleCard>
      </Col>
    </Row>
  );
};

export default AssetSearch;
