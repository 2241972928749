import { Modal } from "antd";
import NewOperationForm from "./newOperation";
import { WalletNames } from "../api/models";

const NewOperationModal = ({
  ticker,
  amount,
  walletName,
  price,
  title,
  visible,
  onComplete,
}: {
  ticker?: string;
  amount?: number;
  price?: number;
  walletName?: WalletNames;
  title?: string;
  visible: boolean;
  onComplete: (success: boolean) => void;
}) => {
  return (
    <>
      <Modal
        centered={true}
        title={title || "Nova Operação"}
        onCancel={() => onComplete(false)}
        footer={null}
        open={visible}
      >
        <NewOperationForm
          withCard={false}
          onSubmit={() => onComplete(true)}
          ticker={ticker}
          amount={amount}
          walletName={walletName}
          price={price}
        />
      </Modal>
    </>
  );
};

export default NewOperationModal;
