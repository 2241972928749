import { Breadcrumb } from "antd";
import { useSearchParams, Link } from "react-router-dom";
import DailyPnlRecords from "./dailyPNLRecords";
import DailyWalletPnlRecordDetails from "./dailyWalletPNLRecordDetails";

const DailyPnl = () => {
  const [searchParams] = useSearchParams();

  const date = searchParams.get("date");
  const walletName = searchParams.get("wallet");

  const tableComponent = date ? (
    <DailyWalletPnlRecordDetails />
  ) : (
    <DailyPnlRecords />
  );

  return (
    <>
      <div className="p-2 flex justify-between items-center">
        <Breadcrumb className="pb-2">
          <Breadcrumb.Item>
            {date ? (
              <Link to={"?period=daily"} className="underline">
                Registros de PNL Diários
              </Link>
            ) : (
              "Registros de PNL Diários"
            )}
          </Breadcrumb.Item>
          {date && <Breadcrumb.Item>{date}</Breadcrumb.Item>}
          {walletName && <Breadcrumb.Item>{walletName}</Breadcrumb.Item>}
        </Breadcrumb>
        {date && (
          <Link to={"?period=daily"} className="underline">
            Voltar
          </Link>
        )}
      </div>
      {tableComponent}
    </>
  );
};

export default DailyPnl;
