import { WalletNames } from "../../../api/models";
import ValueWalletTemplate from "./valueWalletTemplate";

const FiisWallet = () => {
  return (
    <ValueWalletTemplate
      walletName={WalletNames.FIIS}
      title="Carteira de fundos imobiliários"
      descriptionTwoTitle="Boas práticas"
      descriptionTwo={
        <>
          <p>Ideal para criar renda mais estável, previsível e recorrente.</p>
          <p>
            Diversifique entre vários fundos de várias gestoras para mitigar o
            risco de gestão dos fundos.
          </p>
          <p>Evite fundos monoativos.</p>
        </>
      }
    />
  );
};

export default FiisWallet;
