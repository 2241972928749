import { Form, Button, Select, Modal, Input, Tooltip } from "antd";
import { EditOutlined, GroupOutlined, CloseOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { appApi } from "../../../api";
import { WALLET_QUERY_KEYS } from "../constants";
import {
  CurrentPosition,
  PositionStrategyBundle,
  WalletNames,
} from "../../../api/models";
import useScreenSize from "../../../layouts/useScreenSize";

const PositionStrategyEditor = ({
  strategyBundle,
  walletId,
  walletName,
  walletPositions,
}: {
  strategyBundle?: PositionStrategyBundle;
  walletName: WalletNames;
  walletId: string;
  walletPositions: CurrentPosition[];
}) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const positionsKey = ["currentPositions"];
  const [mutationLoading, setMutationLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  const { isMobile } = useScreenSize();

  const createMutation = useMutation({
    mutationFn: (values: PositionStrategyBundle) => {
      const payload = {
        id: strategyBundle?.id,
        strategy: values.strategy,
        description: values.description,
        wallet: walletId,
        items: values.items?.map((item) => ({
          position: item as any,
        })),
      };
      setMutationLoading(true);
      return appApi.strategyBundle.create_or_update(payload);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: positionsKey });
      WALLET_QUERY_KEYS.forEach(async (key: any) => {
        queryClient.invalidateQueries({ queryKey: [key] });
      });
      setMutationLoading(false);
      setVisible(false);
    },
    onError: async () => {
      setMutationLoading(false);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id: string) => {
      setMutationLoading(true);
      return appApi.strategyBundle.delete(id);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: positionsKey });
      WALLET_QUERY_KEYS.forEach(async (key: any) => {
        queryClient.invalidateQueries({ queryKey: [key] });
      });
      setMutationLoading(false);
    },
    onError: async () => {
      setMutationLoading(false);
    },
  });

  const onFormSubmit = async () => {
    const values = form.getFieldsValue() as PositionStrategyBundle;
    createMutation.mutateAsync(values);
  };

  const onStrategyDelete = async (id: string) => {
    modal.confirm({
      title: "Deseja realmente excluir a estratégia?",
      content:
        "Essa ação não pode ser desfeita, porém não afeta suas posições na carteira, apenas desagrupa as mesmas.",
      onOk: () => {
        deleteMutation.mutateAsync(id);
      },
    });
  };

  const strategyItems =
    strategyBundle?.items.map((item) => item.position) || [];

  return (
    <div>
      {strategyBundle ? (
        <div className="flex flex-row">
          <Tooltip title={"Editar o agrupamento da estratégia."}>
            <Button
              icon={<EditOutlined style={{ color: "blue" }} />}
              size="small"
              type="text"
              style={{ marginLeft: 8 }}
              onClick={() => setVisible(true)}
              className="mr-1"
            />
          </Tooltip>
          <Tooltip
            title={
              "Excluir o agrumento de estratégia. Não afeta as posições envolvidas, apenas a visualização agrupada."
            }
          >
            <Button
              icon={<CloseOutlined style={{ color: "red" }} />}
              size="small"
              type="text"
              style={{ marginLeft: 8 }}
              onClick={() => onStrategyDelete(strategyBundle.id)}
            />
          </Tooltip>
        </div>
      ) : (
        <Button
          type="default"
          onClick={() => setVisible(true)}
          icon={<GroupOutlined />}
        >
          {isMobile ? "" : "Nova estratégia"}
        </Button>
      )}
      <Modal
        title={`Novo agrupamento de estratégia para a carteira ${walletName}`}
        open={visible}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
        onOk={() => form.submit()}
        confirmLoading={mutationLoading}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFormSubmit}
          initialValues={{
            strategy: strategyBundle?.strategy || null,
            baseTicker: strategyBundle?.baseTicker || null,
            description: strategyBundle?.description || null,
            items: strategyItems,
          }}
        >
          <Form.Item
            name="strategy"
            label="Nome da estratégia"
            rules={[{ required: true, message: "Por favor informe um nome" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Descrição da estratégia">
            <Input />
          </Form.Item>
          <Form.Item
            name="items"
            label="Ativos da estratégia"
            rules={[
              { required: true, message: "Por favor selecione os ativos" },
            ]}
            help="Selecione os ativos da carteira que compõem a estratégia"
          >
            <Select style={{ width: "100%" }} mode="multiple">
              {walletPositions?.map((position) => (
                <Select.Option key={position.id} value={position.id}>
                  {position.ticker} - {position.optionAlias}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      {contextHolder}
    </div>
  );
};

export default PositionStrategyEditor;
