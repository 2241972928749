import { Alert, Collapse } from "antd";
import type { ListResponse } from "../../../api/types";
import { WalletDailyResult } from "../../../api/models";
import { useQuery } from "@tanstack/react-query";
import { appApi } from "../../../api";
import { numberToCurrency } from "../../../services/utils";
import { useSearchParams } from "react-router-dom";
import { EventResultDetails, PositionResultDetails } from "../../../api/models";
import ResponsiveTable from "../../../components/ResponsiveTable";
import PriceDisplay from "../../../components/priceDisplay";
import { formatDate } from "../../../services/dates";

const DailyWalletPNLRecordDetails = () => {
  const queryKey = "dailyWalletPnlRecords";
  const [searchParams] = useSearchParams();
  const date = searchParams.get("date") || "";
  const { data, isFetching } = useQuery<ListResponse<WalletDailyResult>>({
    queryKey: [queryKey, date],
    queryFn: () => appApi.pnl.getWalletDailyPNL(1, date),
  });

  if (!date || date === "") {
    return <Alert message="Data não informada" type="error" />;
  }

  const positionResultColumns = [
    {
      title: "Ativo",
      dataIndex: "ticker",
      key: "ticker",
    },
    {
      title: "PNL",
      dataIndex: "totalPnl",
      key: "totalPnl",
      render: (value: string) => <PriceDisplay price={value} />,
    },
    {
      title: "Preço de fechamento do dia",
      dataIndex: "closePrice",
      render: (value: string) => <PriceDisplay price={value} />,
    },
    {
      title: "Preço de fechamento anterior",
      dataIndex: "previousClosePrice",
      render: (value: string) => <PriceDisplay price={value} />,
    },
    {
      title: "Posição ao final do dia",
      dataIndex: "positionAtEod",
      key: "positionAtEod",
    },
    {
      title: "Posição dia anterior",
      dataIndex: "positionPreviousDay",
      key: "positionPreviousDay",
    },
    {
      title: "Preço Médio posição alterada",
      dataIndex: "id",
      render: (_: string, record: PositionResultDetails) => (
        <PriceDisplay price={record.tradesAvgPrice} />
      ),
    },
    {
      title: "Pnl Day Trade",
      dataIndex: "dayTradePnl",
      key: "dayTradePnl",
      render: (value: string) => <PriceDisplay price={value} />,
    },
    {
      title: "PNL posição alterada",
      dataIndex: "tradedPositionPnl",
      key: "tradedPositionPnl",
      render: (value: string) => <PriceDisplay price={value} />,
    },
    {
      title: "PNL posição carregada",
      dataIndex: "carriedPositionPnl",
      key: "carriedPositionPnl",
      render: (value: string) => <PriceDisplay price={value} />,
    },
  ];

  const eventResultColumns = [
    {
      title: "Dividendos",
      dataIndex: "dividends",
      render: (value: string) => <PriceDisplay price={value} />,
    },
    {
      title: "JSCP",
      dataIndex: "jscp",
      render: (value: string) => <PriceDisplay price={value} />,
    },
    {
      title: "Tipo de evento",
      dataIndex: "id",
      render: (_: string, record: EventResultDetails) => {
        if (record.dividends) {
          return "Dividendo";
        }
        if (record.jscp) {
          return "JSCP";
        }
        return null;
      },
    },
    {
      title: "Ativo",
      dataIndex: "id",
      render: (_: string, record: EventResultDetails) => {
        const value = record.dividendDetails
          ? record.dividendDetails
          : record.jscpDetails;
        if (!value) {
          return null;
        }
        return value.ticker;
      },
    },
    {
      title: "Data de pagamento",
      dataIndex: "id",
      key: "id",
      render: (_: string, record: EventResultDetails) => {
        if (record?.dividendDetails) {
          return `Data de pagamento: ${record.dividendDetails.payDate ? formatDate(record.dividendDetails.payDate) : "Não informada"}`;
        }
        if (record?.jscpDetails) {
          return `Data de pagamento: ${
            record.jscpDetails.payDate
              ? formatDate(record.jscpDetails.payDate)
              : "Não informada"
          }`;
        }
      },
    },
  ];

  const renderPanelBody = (wallet: WalletDailyResult) => {
    const positionResultDetails: PositionResultDetails[] =
      wallet?.positionResultDetails || [];
    const eventResultDetails: EventResultDetails[] =
      wallet?.eventResultDetails || [];

    const items = [
      {
        key: "1",
        label: `Resultado de posições: ${numberToCurrency(wallet.positionResult)}`,
        children: (
          <ResponsiveTable
            dataSource={positionResultDetails}
            columns={positionResultColumns}
            loading={isFetching}
            size="small"
            pagination={{
              pageSize: 5,
              showSizeChanger: false,
            }}
            rowKey="id"
          />
        ),
      },
      {
        key: "2",
        label: `Resultado de dividendos / JSCP: ${numberToCurrency(wallet.eventResult)}`,
        children: (
          <ResponsiveTable
            dataSource={eventResultDetails}
            columns={eventResultColumns}
            loading={isFetching}
            size="small"
            pagination={{
              pageSize: 5,
              showSizeChanger: false,
            }}
            rowKey="id"
          />
        ),
      },
    ];
    return <Collapse items={items} />;
  };

  const items = data?.results.map((wallet) => ({
    key: wallet.walletName,
    label: `${wallet.walletName}: ${numberToCurrency(wallet.pnlResult)}`,
    children: renderPanelBody(wallet),
  }));

  return <Collapse items={items} />;
};

export default DailyWalletPNLRecordDetails;
