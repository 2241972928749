import { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { useMutation } from "@tanstack/react-query";
import { appApi } from "../../api";
import CollapsibleCard from "../../components/collapsibleCard";

interface ChangePasswordValues {
  newPassword1: string;
  newPassword2: string;
}

const ChangePasswordPage = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const mutation = useMutation({
    mutationFn: ({ newPassword1, newPassword2 }: ChangePasswordValues) => {
      setLoading(true);
      return appApi.authentication.passwordChange({
        newPassword1,
        newPassword2,
      });
    },
    onSuccess: () => {
      setLoading(false);
      message.success("Senha alterada com sucesso.");
    },
    onError: () => {
      setLoading(false);
    },
  });

  return (
    <CollapsibleCard title="Alterar a senha">
      <Form
        form={form}
        onFinish={mutation.mutateAsync}
        layout="vertical"
        style={{
          maxWidth: 500,
          width: "100%",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Form.Item
          name="newPassword1"
          label="Senha"
          rules={[{ required: true, message: "Por favor informe a senha." }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="newPassword2"
          label="Repetir a senha"
          rules={[{ required: true, message: "Por favor repita a senha." }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Alterar a senha
          </Button>
        </Form.Item>
      </Form>
    </CollapsibleCard>
  );
};

export default ChangePasswordPage;
