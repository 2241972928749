import { appApi } from "../../api";
import { useQuery } from "@tanstack/react-query";
import { formatDate } from "../../services/dates";
import CollapsibleCard from "../collapsibleCard";
import { useState } from "react";
import ResponsiveTable from "../ResponsiveTable";
import PriceDisplay from "../priceDisplay";

const DividendsHistoryTable = ({ asset }: { asset: string }) => {
  const [page, setPage] = useState(1);
  const { data, isLoading } = useQuery({
    queryKey: ["asset-dividends-history", asset, page],
    queryFn: () =>
      appApi.resources.getHistoricalDividends(asset as string, page),
  });

  const columns = [
    {
      title: "Data COM",
      dataIndex: "comDate",
      key: "date",
      render: (date: string) => formatDate(date),
    },
    {
      title: "Data de Pagamento",
      dataIndex: "payDate",
      key: "payDate",
      render: (date: string) => formatDate(date),
    },
    {
      title: "Valor por ação",
      dataIndex: "amount",
      key: "amount",
      render: (amount: number) => <PriceDisplay price={amount} />,
    },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
    },
  ];
  return (
    <CollapsibleCard title="Histórico de Dividendos" loading={isLoading}>
      <ResponsiveTable
        columns={columns}
        dataSource={data?.results || []}
        loading={isLoading}
        size="small"
        rowKey="id"
        pagination={{
          pageSize: 10,
          total: data?.count,
          current: page,
          onChange: setPage,
          showSizeChanger: false,
        }}
      />
    </CollapsibleCard>
  );
};

export default DividendsHistoryTable;
