import { Button, Modal, Form, InputNumber } from "antd";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { appApi } from "../../api";
import { walletPnlColumnMap } from "./constants";
import { MonthSelectionInput } from "../../components/monthSelectionInput";

interface FormValues {
  year: number;
  month: number;
  acoes: string | null;
  internacional: string | null;
  renda: string | null;
  carrego: string | null;
  figuras: string | null;
  fundosImobiliarios: string | null;
  fundosDoAgronegocio: string | null;
  outros: string | null;
}

type EditMonthlyPnlRecordProps = {
  year?: number;
  month?: number;
  overrideAcoes?: string | null;
  overrideInternacional?: string | null;
  overrideRenda?: string | null;
  overrideCarrego?: string | null;
  overrideFiguras?: string | null;
  overrideFundosImobiliarios?: string | null;
  overrideFundosDoAgronegocio?: string | null;
  overrideOutros?: string | null;
};

export const EditMonthlyPnlRecord = ({
  year,
  month,
  overrideAcoes,
  overrideInternacional,
  overrideRenda,
  overrideCarrego,
  overrideFiguras,
  overrideFundosImobiliarios,
  overrideFundosDoAgronegocio,
  overrideOutros,
}: EditMonthlyPnlRecordProps) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [mutationLoading, setMutationLoading] = useState(false);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({
      year,
      month,
      acoes,
      internacional,
      renda,
      carrego,
      figuras,
      fundosImobiliarios,
      fundosDoAgronegocio,
      outros,
    }: FormValues) => {
      setMutationLoading(true);
      return appApi.pnl.overrideMonthlyPnlWalletResults({
        year,
        month,
        walletResults: {
          acoes: acoes !== null ? Number(acoes) : null,
          internacional: internacional !== null ? Number(internacional) : null,
          renda: renda !== null ? Number(renda) : null,
          carrego: carrego !== null ? Number(carrego) : null,
          figuras: figuras !== null ? Number(figuras) : null,
          fundosImobiliarios:
            fundosImobiliarios !== null ? Number(fundosImobiliarios) : null,
          fundosDoAgronegocio:
            fundosDoAgronegocio !== null ? Number(fundosDoAgronegocio) : null,
          outros: outros !== null ? Number(outros) : null,
        },
      });
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["dailyPNLRecords"] });
      queryClient.invalidateQueries({ queryKey: ["monthlyPNLRecords"] });
      queryClient.invalidateQueries({ queryKey: ["yearlyPNLRecords"] });
      queryClient.invalidateQueries({ queryKey: ["pnlChartData"] });
      setMutationLoading(false);
      setVisible(false);
    },
    onError: async () => {
      setMutationLoading(false);
    },
  });

  return (
    <div>
      <Button type="link" onClick={() => setVisible(true)}>
        {!year || !month ? "Novo" : "Editar"}
      </Button>
      <Modal
        title={
          !year || !month
            ? "Novo registro de PNL mensal"
            : "Editar registro de PNL mensal"
        }
        open={visible}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
        onOk={() => form.submit()}
        confirmLoading={mutationLoading}
      >
        <Form
          form={form}
          layout="horizontal"
          onFinish={() => mutation.mutateAsync(form.getFieldsValue())}
          initialValues={{
            year,
            month,
            acoes: overrideAcoes,
            internacional: overrideInternacional,
            renda: overrideRenda,
            carrego: overrideCarrego,
            figuras: overrideFiguras,
            fundosImobiliarios: overrideFundosImobiliarios,
            fundosDoAgronegocio: overrideFundosDoAgronegocio,
            outros: overrideOutros,
          }}
        >
          <Form.Item name="year" label="Ano">
            <InputNumber
              placeholder="Ano"
              style={{ width: "100%" }}
              disabled={!!year}
              type="number"
            />
          </Form.Item>
          <MonthSelectionInput
            name="month"
            label="Mês"
            disabled={month !== undefined}
            help={
              "Deixe o campo da carteira que não deseja sobrepor em branco."
            }
          />
          {walletPnlColumnMap.map((wallet) => (
            <Form.Item name={wallet.dataIndex} label={wallet.label}>
              <InputNumber
                placeholder={wallet.label}
                style={{ width: "100%" }}
                prefix="R$"
                decimalSeparator=","
              />
            </Form.Item>
          ))}
        </Form>
      </Modal>
    </div>
  );
};
