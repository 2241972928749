import {
  Form,
  Select,
  Row,
  Col,
  Button,
  InputNumber,
  Divider,
  Descriptions,
  Radio,
  message,
} from "antd";
import CollapsibleCard from "../../components/collapsibleCard";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { BlackAndScholesCalculatorResponse } from "../../api/models";
import { appApi } from "../../api";
import { numberToPercentageDisplay } from "../../services/utils";
import PriceDisplay from "../../components/priceDisplay";

type FormValues = {
  assetPrice: number;
  optionType: string;
  volatility: number;
  strike: number;
  businessDaysToExpiration: number;
  optionBlackScholesPrice: number;
};

const BlackScholesCalculator = () => {
  const [calculatorMode, setCalculatorMode] = useState<"volatility" | "price">(
    "price",
  );
  const [assetPrice, setAssetPrice] = useState<number | undefined>(undefined);
  const [optionType, setOptionType] = useState<string | undefined>(undefined);
  const [volatility, setVolatility] = useState<number | undefined>(undefined);
  const [strike, setStrike] = useState<number | undefined>(undefined);
  const [businessDaysToExpiration, setBusinessDaysToExpiration] = useState<
    number | undefined
  >(undefined);
  const [optionBlackScholesPrice, setOptionBlackScholesPrice] = useState<
    number | undefined
  >(undefined);

  const { data, isLoading } = useQuery<BlackAndScholesCalculatorResponse>({
    queryKey: [
      "bs-calculator",
      assetPrice,
      optionType,
      volatility,
      strike,
      businessDaysToExpiration,
      optionBlackScholesPrice,
    ],
    queryFn: () =>
      appApi.blackAndScholesCalculator.get(
        assetPrice!,
        optionType!,
        volatility!,
        strike!,
        businessDaysToExpiration!,
        optionBlackScholesPrice!,
      ),
    enabled:
      !!assetPrice &&
      !!optionType &&
      (!!volatility || !!optionBlackScholesPrice) &&
      !!strike &&
      !!businessDaysToExpiration,
  });

  const [form] = Form.useForm();
  return (
    <Row className="w-full">
      <Col className="w-full">
        <CollapsibleCard title="Calculadora Black & Scholes">
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              calculatorMode: "price",
            }}
            onFinish={(values: FormValues) => {
              if (
                (!values.optionBlackScholesPrice && !values.volatility) ||
                !values.assetPrice ||
                !values.optionType ||
                !values.strike ||
                !values.businessDaysToExpiration
              ) {
                message.error(
                  "Por favor informe todos os campos do formulário.",
                );
                return;
              }
              setAssetPrice(values.assetPrice);
              setOptionType(values.optionType);
              setVolatility(values.volatility);
              setStrike(values.strike);
              setBusinessDaysToExpiration(values.businessDaysToExpiration);
              setOptionBlackScholesPrice(values.optionBlackScholesPrice);
            }}
          >
            <Form.Item name="optionType" label="Tipo de opção">
              <Select style={{ maxWidth: 200 }}>
                <Select.Option value="Call">Call</Select.Option>
                <Select.Option value="Put">Put</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="strike" label="Strike">
              <InputNumber
                min={0}
                precision={2}
                prefix={"R$"}
                decimalSeparator=","
                style={{ width: 200 }}
              />
            </Form.Item>
            <Form.Item name="assetPrice" label="Preço do ativo adjacente">
              <InputNumber
                min={0}
                precision={2}
                prefix={"R$"}
                decimalSeparator=","
                style={{ width: 200 }}
              />
            </Form.Item>
            <Form.Item
              name="businessDaysToExpiration"
              label="Dias úteis para vencimento"
            >
              <InputNumber min={0.5} precision={1} style={{ width: 200 }} />
            </Form.Item>
            <Form.Item name="calculatorMode" label="Modo de cálculo">
              <Radio.Group
                onChange={(e) => {
                  setCalculatorMode(e.target.value);
                  setVolatility(undefined);
                  setOptionBlackScholesPrice(undefined);
                }}
                value={calculatorMode}
              >
                <Radio.Button value="price">
                  Calcular preço da opção
                </Radio.Button>
                <Radio.Button value="volatility">
                  Calcular volatilidade da opção
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            {calculatorMode === "price" ? (
              <Form.Item name="volatility" label="Volatilidade">
                <InputNumber
                  min={0}
                  precision={2}
                  suffix={"%"}
                  decimalSeparator=","
                  style={{ width: 200 }}
                />
              </Form.Item>
            ) : (
              <Form.Item name="optionBlackScholesPrice" label="Preço da opção">
                <InputNumber
                  min={0}
                  precision={2}
                  prefix={"R$"}
                  decimalSeparator=","
                  style={{ width: 200 }}
                />
              </Form.Item>
            )}
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={isLoading}>
                Buscar
              </Button>
            </Form.Item>
            <Divider />
            {data && (volatility || optionBlackScholesPrice) && (
              <Descriptions title="Resultado">
                {calculatorMode === "price" && (
                  <Descriptions.Item label="Preço da opção">
                    {data?.optionBlackScholesPrice ? (
                      <PriceDisplay price={data?.optionBlackScholesPrice} />
                    ) : (
                      "N/D"
                    )}
                  </Descriptions.Item>
                )}
                {calculatorMode === "volatility" && (
                  <Descriptions.Item label="Volatilidade da opção">
                    {data?.volatility
                      ? numberToPercentageDisplay(data?.volatility)
                      : "N/D"}
                  </Descriptions.Item>
                )}
                <Descriptions.Item label="Delta da opção">
                  {data?.optionBlackScholesDelta
                    ? numberToPercentageDisplay(data?.optionBlackScholesDelta)
                    : "N/D"}
                </Descriptions.Item>
              </Descriptions>
            )}
          </Form>
        </CollapsibleCard>
      </Col>
    </Row>
  );
};

export default BlackScholesCalculator;
