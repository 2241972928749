import { CurrentPosition } from "../api/models";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import PriceDisplay from "./priceDisplay";

const OptionDetails = ({
  position,
  showAsTooltip = false,
}: {
  position: CurrentPosition;
  showAsTooltip: boolean;
}) => {
  const {
    ticker,
    expiration,
    strike,
    optionType,
    fairOptionPrice,
    optionAssetLastPrice,
    businessDaysToExpiration,
    optionDeltaDisplay,
    optionUnderlyingAsset,
  } = position;

  if (!expiration) {
    return null;
  }
  const details = (
    <div>
      <h3>Detalhes da Opção</h3>
      <div>
        <p>Ticker: {ticker}</p>
        <p>Vencimento: {expiration}</p>
        <p>
          Strike: <PriceDisplay price={strike} />
        </p>
        <p>Tipo: {optionType}</p>
        <p>
          Preço Justo (B&S): <PriceDisplay price={fairOptionPrice} />
        </p>
        <p>Dias úteis para Vencimento: {businessDaysToExpiration}</p>
        <p>Delta: {optionDeltaDisplay}</p>
        <p>Ativo relacionado: {optionUnderlyingAsset}</p>
        <p>
          Último preço do Ativo relacionado:{" "}
          <PriceDisplay price={optionAssetLastPrice} />
        </p>
      </div>
    </div>
  );

  if (showAsTooltip) {
    return (
      <Tooltip title={details}>
        <InfoCircleOutlined
          title="Detalhes da Opção"
          size={24}
          color="blue"
          style={{ color: "blue", fontWeight: "bold" }}
        />
      </Tooltip>
    );
  }

  return details;
};

export default OptionDetails;
