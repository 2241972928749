import { Button, Modal, Form, InputNumber } from "antd";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { appApi } from "../../api";
import { MonthSelectionInput } from "../../components/monthSelectionInput";

interface FormValues {
  month: number | string;
  year: number | string;
  overridenDividendsIncome: string | null;
  overridenOptionsIncome: string | null;
  overridenMiniIndexIncome: string | null;
}

type EditMonthlyIncomeRecordProps = {
  month?: number | string;
  year?: number | string;
  overridenDividendsIncome?: string | null;
  overridenOptionsIncome?: string | null;
  overridenMiniIndexIncome?: string | null;
};

export const EditMonthlyIncomeRecord = ({
  month,
  year,
  overridenDividendsIncome,
  overridenOptionsIncome,
  overridenMiniIndexIncome,
}: EditMonthlyIncomeRecordProps) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [mutationLoading, setMutationLoading] = useState(false);
  const queryClient = useQueryClient();

  const newRecordMutation = useMutation({
    mutationFn: ({
      year,
      month,
      overridenDividendsIncome,
      overridenMiniIndexIncome,
      overridenOptionsIncome,
    }: FormValues) => {
      setMutationLoading(true);
      return appApi.pnl.createAccountMonthlyIncome({
        year,
        month,
        overridenDividendsIncome,
        overridenMiniIndexIncome,
        overridenOptionsIncome,
      });
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["monthlyIncomeRecords"] });
      setMutationLoading(false);
      setVisible(false);
    },
    onError: async () => {
      setMutationLoading(false);
    },
  });

  return (
    <div>
      <Button type="link" onClick={() => setVisible(true)}>
        {!year && !month ? "Novo" : "Editar"}
      </Button>
      <Modal
        title={
          !year && !month
            ? "Novo registro de renda mensal"
            : "Editar registro de renda mensal"
        }
        open={visible}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
        onOk={() => form.submit()}
        confirmLoading={mutationLoading}
      >
        <Form
          form={form}
          layout="horizontal"
          onFinish={() => newRecordMutation.mutateAsync(form.getFieldsValue())}
          initialValues={{
            year,
            month,
            overridenDividendsIncome,
            overridenOptionsIncome,
            overridenMiniIndexIncome,
          }}
        >
          <Form.Item
            name="year"
            label="Ano"
            rules={[{ required: true, message: "Campo obrigatório" }]}
          >
            <InputNumber
              placeholder="Ano"
              style={{ width: "100%" }}
              disabled={year !== undefined}
            />
          </Form.Item>
          <MonthSelectionInput disabled={month !== undefined} />
          <Form.Item
            name="overridenDividendsIncome"
            label="Renda de dividendos"
            help="Deixar o campo em branco para remover sobreposição"
          >
            <InputNumber
              placeholder="Renda de dividendos"
              style={{ width: "100%" }}
              prefix="R$"
              decimalSeparator=","
            />
          </Form.Item>
          <Form.Item
            name="overridenOptionsIncome"
            label="Renda de opções"
            help="Deixar o campo em branco para remover sobreposição"
          >
            <InputNumber
              placeholder="Renda de opções"
              style={{ width: "100%" }}
              prefix="R$"
              decimalSeparator=","
            />
          </Form.Item>
          <Form.Item
            name="overridenMiniIndexIncome"
            label="Renda de mini índice"
            help="Deixar o campo em branco para remover sobreposição"
          >
            <InputNumber
              placeholder="Renda de mini índice"
              style={{ width: "100%" }}
              prefix="R$"
              decimalSeparator=","
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
