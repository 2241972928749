import type { ListResponse } from "../api/types";
import { Account } from "../api/models";
import { useQuery } from "@tanstack/react-query";
import { appApi } from "../api";
import { Spin, Space, Badge, Avatar, Dropdown, message } from "antd";
import {
  UserOutlined,
  RobotOutlined,
  ShoppingCartOutlined,
  AreaChartOutlined,
} from "@ant-design/icons";
import { formatDateTime } from "../services/dates";
import type { MenuProps } from "antd";
import { useEffect, useState } from "react";

const AccountData = () => {
  const [refetchInterval, setRefreshInterval] = useState<number | undefined>(
    120000,
  );
  const [accountCalculationsInProgress, setAccountCalculationsInProgress] =
    useState<boolean>(false);

  const { data, isLoading } = useQuery<ListResponse<Account>>({
    queryKey: ["accounts"],
    queryFn: appApi.accounts.getList,
    refetchInterval: refetchInterval,
  });

  useEffect(() => {
    if (data && data?.results?.length > 0) {
      if (data.results[0].accountAcceleratedProcessingPending) {
        setRefreshInterval(10000);
        setAccountCalculationsInProgress(true);
      } else {
        setRefreshInterval(120000);
        if (accountCalculationsInProgress) {
          setAccountCalculationsInProgress(false);
          message.success("Dados da conta atualizados.");
        }
      }
    }
  }, [data]);

  if (isLoading) {
    return <Spin />;
  }

  if (!data || data?.results.length === 0) {
    return null;
  }

  const account: Account = data.results[0];
  const indicator =
    account.metatraderConnectionStatus === "connected"
      ? "🟢"
      : account.metatraderConnectionStatus === "disconnected"
        ? "🔴"
        : "🟡";

  let items: MenuProps["items"] = [
    {
      label: account.user,
      key: account.id,
      icon: <UserOutlined />,
    },
    {
      label: `Metatrader: ${account.accountNumber} ${indicator}`,
      key: "metatrader",
      icon: <RobotOutlined />,
    },
    {
      label: `Última conexão com o metatrader: ${
        account.metatraderLastConnectionDate
          ? formatDateTime(account.metatraderLastConnectionDate)
          : "N/D"
      }`,
      key: "metatraderLastConnectionDate",
      icon: <RobotOutlined />,
    },
    {
      label: `Ultimo registro de operação da conta: ${
        account.lastOperationsSyncDate
          ? formatDateTime(account.lastOperationsSyncDate)
          : "N/D"
      }`,
      key: "lastOperationsSync",
      icon: <ShoppingCartOutlined />,
    },
    {
      label: `Ultima atualização de calculos da conta: ${
        account.accountLastProcessingDate
          ? formatDateTime(account.accountLastProcessingDate)
          : "N/D"
      }`,
      key: "lastProcessingDate",
      icon: <AreaChartOutlined />,
    },
  ];

  if (accountCalculationsInProgress) {
    items = items.map((item) => {
      if (item?.key === "lastProcessingDate") {
        return {
          ...item,
          label: (
            <div className="text-orange-300">
              Cálculos da conta em andamento.
            </div>
          ),
        };
      }
      return item;
    });
  }

  return (
    <Dropdown menu={{ items }} placement="bottom" arrow={true}>
      <Space size="middle">
        <Badge size="default">
          {accountCalculationsInProgress ? (
            <Spin size={"large"} />
          ) : (
            <Avatar
              shape="square"
              icon={<UserOutlined size={30} />}
              style={{ fontSize: "24px", cursor: "pointer" }}
            />
          )}
        </Badge>
      </Space>
    </Dropdown>
  );
};

export default AccountData;
