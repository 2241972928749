import { useState } from "react";
import type { ListResponse } from "../../api/types";
import { Communication } from "../../api/models";
import { appApi } from "../../api";
import { Button, Modal, Tabs } from "antd";
import { Avatar, Badge, Space } from "antd";
import { BellOutlined } from "@ant-design/icons";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { communicationsQueryKey } from "./constants";
import MessagesList from "./messagesList";

const NotificationBell = () => {
  const queryClient = useQueryClient();
  const [visible, setVisible] = useState(false);
  const [tab, setTab] = useState<"unread" | "all">("unread");
  const { data } = useQuery<ListResponse<Communication>>({
    queryKey: [communicationsQueryKey, tab],
    queryFn: () => appApi.communications.getList(tab, 20),
    refetchInterval: 60000,
  });

  const { mutateAsync: markAllAsRead } = useMutation({
    mutationFn: () => appApi.communications.markAllAsRead(),
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: [communicationsQueryKey],
      });
    },
  });

  const count = data?.results.filter((item) => !item.read).length;

  const messagesList = <MessagesList data={data || { results: [] }} />;
  const tabItems = [
    {
      key: "unread",
      label: "Não lidas",
      children: messagesList,
    },
    {
      key: "all",
      label: "Todas as mensagens",
      children: messagesList,
    },
  ];

  return (
    <>
      <Space size="middle">
        <Badge size="default" count={count}>
          <Avatar
            shape="square"
            icon={<BellOutlined size={30} />}
            onClick={() => setVisible(!visible)}
            style={{ fontSize: "24px", cursor: "pointer" }}
          />
        </Badge>
      </Space>
      <Modal
        style={{ top: 10, right: 10, float: "right" }}
        centered={false}
        title="Notificações"
        onCancel={() => setVisible(false)}
        footer={null}
        open={visible}
      >
        <Tabs
          defaultActiveKey="unread"
          onChange={(key) => setTab(key as any)}
          items={tabItems}
          tabBarExtraContent={
            <Button key="mark-all-as-read" onClick={() => markAllAsRead()}>
              Todas lidas
            </Button>
          }
        />
      </Modal>
    </>
  );
};

export default NotificationBell;
