import { Form, Button, message, InputNumber, Select, Modal } from "antd";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { appApi } from "../../../../api";
import { WALLET_QUERY_KEYS } from "../../constants";
import { CurrentPosition, WalletNames } from "../../../../api/models";

interface FormValues {
  amount: number;
  wallet: WalletNames;
}

const SplitPositionForm = ({
  currentPosition,
}: {
  currentPosition: CurrentPosition;
}) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const positionsKey = ["currentPositions"];
  const [mutationLoading, setMutationLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const splitPositionMutation = useMutation({
    mutationFn: ({ amount, wallet }: FormValues) => {
      setMutationLoading(true);
      return appApi.currentPositions.splitPosition({
        id: currentPosition.id,
        splitAmount: amount,
        walletName: wallet,
      });
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: positionsKey });
      WALLET_QUERY_KEYS.forEach(async (key) => {
        queryClient.invalidateQueries({ queryKey: [key] });
      });
      setMutationLoading(false);
      setVisible(false);
    },
    onError: async () => {
      setMutationLoading(false);
    },
  });

  const onFormSubmit = async () => {
    const values = form.getFieldsValue() as FormValues;
    const { amount, wallet } = values;
    if (currentPosition.amount > 0) {
      if (amount <= 0) {
        message.error("A quantidade informada deve ser positiva.");
        return;
      }
      if (currentPosition.amount <= amount) {
        message.error(
          `A quantidade informada deve ser inferior a ${currentPosition.amount} (quantidade atual da posição).`,
        );
        return;
      }
    }
    if (currentPosition.amount < 0) {
      if (amount >= 0) {
        message.error(
          "A quantidade informada deve ser negativa pois a posição atual é vendida.",
        );
        return;
      }
      if (currentPosition.amount >= amount) {
        message.error(
          `A quantidade informada deve ser um número negativo entre ${
            currentPosition.amount + 1
          } e -1.`,
        );
        return;
      }
    }
    if (wallet === currentPosition.walletName) {
      message.error(
        "A carteira de destino deve ser diferente da carteira atual.",
      );
      return;
    }
    splitPositionMutation.mutateAsync(values);
  };

  return (
    <div>
      <Button type="link" onClick={() => setVisible(true)}>
        Dividir
      </Button>
      <Modal
        title={`Dividir a posição ${currentPosition.ticker}, quantidade atual: ${currentPosition.amount}`}
        open={visible}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
        onOk={() => onFormSubmit()}
        confirmLoading={mutationLoading}
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            name="amount"
            label="Quantidade a mover"
            rules={[
              { required: true, message: "Por favor informe a quantidade" },
            ]}
          >
            <InputNumber
              precision={0}
              placeholder="Quantidade"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="wallet"
            label="Carteira"
            rules={[
              { required: true, message: "Por favor informe a carteira" },
            ]}
          >
            <Select>
              <Select.Option value={WalletNames.VALUE}>Ações</Select.Option>
              <Select.Option value={WalletNames.INCOME}>Renda</Select.Option>
              <Select.Option value={WalletNames.DEFENSIVE}>
                Carrego
              </Select.Option>
              <Select.Option value={WalletNames.PICTURES}>
                Figuras
              </Select.Option>
              <Select.Option value={WalletNames.FIIS}>FIIs</Select.Option>
              <Select.Option value={WalletNames.FIAGROS}>FIAgros</Select.Option>
              <Select.Option value={WalletNames.INTERNATIONAL}>
                Internacional
              </Select.Option>
              <Select.Option value={WalletNames.OTHERS}>Outros</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default SplitPositionForm;
