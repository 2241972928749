import React, { useState } from "react";
import { Layout, theme, Button, Typography, Drawer } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import MenuBar from "../components/menuBar";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import NotificationBell from "../components/notifications";
import GlobalNewOperation from "../components/globalNewOperation";
import AccountData from "../components/accountData";
import useScreenSize from "../layouts/useScreenSize";
import CalendarData from "../components/calendarData";

const { Header, Content, Footer, Sider } = Layout;

const DashboardLayout = ({ children }: { children: React.ReactNode }) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const { isTablet, isDesktop } = useScreenSize();
  const [collapsed, setCollapsed] = useState(!isDesktop);

  if (isDesktop) {
    return (
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={isTablet ? true : collapsed}
          style={{ minHeight: "100vh", minWidth: 400, zIndex: 1 }}
        >
          <div className="p-10 flex items-center justify-center">
            <Typography.Title level={3} style={{ color: "white" }}>
              {collapsed ? "" : "FIAInvest"}
            </Typography.Title>
          </div>
          <MenuBar />
        </Sider>
        <Layout>
          <Header
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
                color: "white",
              }}
            />
            <CalendarData />
            <GlobalNewOperation />
            <div style={{ paddingRight: 15 }}>
              <AccountData />
              <NotificationBell />
            </div>
          </Header>
          <Content style={{ padding: "16px" }}>
            <div
              style={{
                background: colorBgContainer,
                minHeight: "240px",
                padding: 16,
                borderRadius: borderRadiusLG,
              }}
            >
              {children}
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            FIAInvest ©{new Date().getFullYear()}
          </Footer>
        </Layout>
      </Layout>
    );
  }

  return (
    <Layout>
      <Drawer
        style={{ minHeight: "100vh", backgroundColor: "#001529" }}
        placement="left"
        closeIcon={<CloseOutlined style={{ color: "white", fontSize: 24 }} />}
        closable={true}
        onClose={() => setCollapsed(true)}
        open={!collapsed}
        width={300}
        title={
          collapsed ? (
            ""
          ) : (
            <Typography.Title level={3}>FIAInvest</Typography.Title>
          )
        }
      >
        <MenuBar
          onPress={() => {
            setCollapsed(true);
          }}
        />
      </Drawer>
      <Layout>
        <Header
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            type="text"
            icon={<MenuOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
              color: "white",
            }}
          />
          <div style={{ paddingRight: 15 }}>
            <AccountData />
            <NotificationBell />
          </div>
        </Header>
        <Content style={{ padding: "8px" }}>
          <div
            style={{
              background: colorBgContainer,
              minHeight: "80vh",
              padding: 0,
              borderRadius: borderRadiusLG,
            }}
          >
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
