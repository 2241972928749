import { Table } from "antd";
import { appApi } from "../../api";
import { useQuery } from "@tanstack/react-query";
import { formatDate } from "../../services/dates";
import CollapsibleCard from "../collapsibleCard";
import { useState } from "react";

const SplitHistoryTable = ({ asset }: { asset: string }) => {
  const [page, setPage] = useState(1);
  const { data, isLoading } = useQuery({
    queryKey: ["asset-split-history", asset, page],
    queryFn: () => appApi.resources.getHistoricalSplits(asset as string, page),
  });

  const columns = [
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
      render: (date: string) => formatDate(date),
    },
    {
      title: "Razão",
      dataIndex: "ratio",
      key: "ratio",
    },
  ];
  return (
    <CollapsibleCard title="Agrupamentos / Divisões" loading={isLoading}>
      <Table
        columns={columns}
        dataSource={data?.results || []}
        loading={isLoading}
        size="small"
        rowKey="id"
        pagination={{
          pageSize: 10,
          total: data?.count,
          current: page,
          onChange: setPage,
          showSizeChanger: false,
        }}
      />
    </CollapsibleCard>
  );
};

export default SplitHistoryTable;
