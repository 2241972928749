import { Form, Input, Select, InputNumber } from "antd";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  value: string;
  dataIndex: string;
  title: any;
  inputType: "number" | "text" | "select";
  record: any;
  index: number;
  children: React.ReactNode;
  currentRowEditing: boolean;
  selectOptions: string[];
  required?: boolean;
  inputProps?: any;
  placeholder?: string;
  prefix?: string;
  editableFieldInitialValue?: any;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  inputProps,
  required,
  selectOptions,
  currentRowEditing,
  placeholder,
  prefix,
  editableFieldInitialValue,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber {...inputProps} placeholder={placeholder} prefix={prefix} />
    ) : inputType === "select" && selectOptions ? (
      <Select {...inputProps} placeholder={placeholder}>
        {selectOptions.map((option: string) => (
          <Select.Option key={option} value={option}>
            {option}
          </Select.Option>
        ))}
      </Select>
    ) : (
      <Input prefix={prefix} {...inputProps} placeholder={placeholder} />
    );

  return (
    <td {...restProps}>
      {editing && currentRowEditing ? (
        <Form.Item
          name={dataIndex}
          initialValue={
            editableFieldInitialValue
              ? editableFieldInitialValue
              : record[dataIndex]
          }
          style={{ margin: 0 }}
          rules={
            required
              ? [
                  {
                    required: true,
                    message: `Por favor informe um valor para ${title}!`,
                  },
                ]
              : []
          }
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
