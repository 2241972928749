import { useState } from "react";
import { Form, Input, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { valueType } from "antd/lib/statistic/utils";

const TableStringFilter = ({
  label,
  setFilter,
}: {
  label: string;
  setFilter: (value: string | null) => void;
}) => {
  const [tempFilter, setTempFilter] = useState<string | null>(null);
  const submitFilter = () => {
    setFilter(tempFilter);
  };
  const clearFilter = () => {
    setFilter(null);
    setTempFilter(null);
  };
  return (
    <Form layout={"inline"}>
      <Form.Item label={label} className="bg-white p-2">
        <div className="flex flex-row">
          <Input
            onChange={(element) => setTempFilter(element.target.value)}
            onPressEnter={submitFilter}
            value={tempFilter as valueType}
          />
          <Button
            type="primary"
            className="ml-2"
            onClick={submitFilter}
            disabled={!tempFilter}
          >
            Pesquisar
          </Button>
          <Button
            type="text"
            icon={<CloseOutlined />}
            onClick={clearFilter}
            className="ml-2"
          />
        </div>
      </Form.Item>
    </Form>
  );
};

export default TableStringFilter;
