import {
  CashflowResult,
  OperationCashflow,
  MiniIndexCashflow,
  DividendCashflow,
} from "../../api/models";
import { appApi } from "../../api";
import { Modal, Typography, Tooltip, List, Descriptions } from "antd";
import { useQuery } from "@tanstack/react-query";
import CollapsibleCard from "../../components/collapsibleCard";
import useScreenSize from "../../layouts/useScreenSize";
import PriceDisplay from "../../components/priceDisplay";
import { formatDate } from "../../services/dates";
import { Dispatch, SetStateAction, useState } from "react";
import { numberToCurrency } from "../../services/utils";

const { Title, Text } = Typography;
type CashflowDisplayOptions = {
  data: CashflowResult | undefined;
  day: "0" | "1" | "2" | null;
  onCancel: Dispatch<SetStateAction<"0" | "1" | "2" | null>>;
};

const renderOperationDetails = (operation: OperationCashflow) => {
  return (
    <List.Item>
      <Descriptions
        title={`Operação sobre o ativo ${operation.ticker}`}
        layout="vertical"
      >
        <Descriptions.Item label="Fluxo de caixa">
          <PriceDisplay price={operation.cashflow} />
        </Descriptions.Item>
        <Descriptions.Item label="Data da operação">
          {formatDate(operation.operationDate)}
        </Descriptions.Item>
        <Descriptions.Item label="Preço da operação">
          <PriceDisplay price={operation.price} />
        </Descriptions.Item>
        <Descriptions.Item label="Quantidade">
          {operation.quantity}
        </Descriptions.Item>
      </Descriptions>
    </List.Item>
  );
};

const renderDividendDetails = (dividend: DividendCashflow) => {
  return (
    <List.Item>
      <Descriptions
        title={`Dividendo / JCP do ativo ${dividend.ticker}`}
        layout="vertical"
      >
        <Descriptions.Item label="Fluxo de caixa">
          <PriceDisplay price={dividend.cashflow} />
        </Descriptions.Item>
        <Descriptions.Item label="Valor do dividendo por ação">
          <PriceDisplay price={dividend.dividendPerShare} />
        </Descriptions.Item>
        <Descriptions.Item label="Data do pagamento">
          {formatDate(dividend.date)}
        </Descriptions.Item>
        <Descriptions.Item label="Quantidade de ações">
          {dividend.quantity}
        </Descriptions.Item>
      </Descriptions>
    </List.Item>
  );
};

const renderMiniIndexDetails = (miniIndex: MiniIndexCashflow) => {
  return (
    <List.Item>
      <Descriptions
        title={`Ajuste de mini índice do ativo ${miniIndex.ticker}`}
        layout="vertical"
      >
        <Descriptions.Item label="Fluxo de caixa">
          <PriceDisplay price={miniIndex.cashflow} />
        </Descriptions.Item>
        <Descriptions.Item label="Data">
          {formatDate(miniIndex.date)}
        </Descriptions.Item>
        <Descriptions.Item label="Caixa de day trade">
          <PriceDisplay price={miniIndex.dayTradeCashflow} />
        </Descriptions.Item>
        <Descriptions.Item label="Caixa de posições negociadas">
          <PriceDisplay price={miniIndex.positionChangeCashflow} />
        </Descriptions.Item>
        <Descriptions.Item label="Caixa de posições carregadas">
          <PriceDisplay price={miniIndex.carriedOverPositionCashflow} />
        </Descriptions.Item>
        <Descriptions.Item label={"Preço de fechamento do dia"}>
          <PriceDisplay price={miniIndex.closePrice} />
        </Descriptions.Item>
        <Descriptions.Item label="Preço de fechamento dia anterior">
          <PriceDisplay price={miniIndex.previousClosePrice} />
        </Descriptions.Item>
      </Descriptions>
    </List.Item>
  );
};

const renderCashflowDetails = ({
  data,
  day,
  onCancel,
}: CashflowDisplayOptions) => {
  if (!data) return null;
  let operationsList: OperationCashflow[] = [];
  let dividendsList: DividendCashflow[] = [];
  let miniIndexList: MiniIndexCashflow[] = [];
  let displayDate: string = "";
  let result: number = 0;

  if (day === "0") {
    operationsList = data.cashflowD0Operations;
    dividendsList = data.cashflowD0Dividends;
    miniIndexList = data.cashflowD0MiniIndexes;
    displayDate = formatDate(data.currentDate);
    result = data.cashflowResultD0;
  }
  if (day === "1") {
    operationsList = data.cashflowD1Operations;
    dividendsList = data.cashflowD1Dividends;
    miniIndexList = data.cashflowD1MiniIndexes;
    displayDate = formatDate(data.datePlus1);
    result = data.cashflowResultD1;
  } else if (day === "2") {
    operationsList = data.cashflowD2Operations;
    dividendsList = data.cashflowD2Dividends;
    miniIndexList = [];
    displayDate = formatDate(data.datePlus2);
    result = data.cashflowResultD2;
  }

  return (
    <Modal
      title={`Fluxo de caixa do dia ${displayDate}: ${numberToCurrency(result)}`}
      open={day !== null}
      onCancel={() => onCancel(null)}
      footer={null}
    >
      {operationsList.length > 0 && (
        <List
          itemLayout="vertical"
          dataSource={operationsList}
          renderItem={(item) => renderOperationDetails(item)}
        />
      )}
      {dividendsList.length > 0 && (
        <List
          itemLayout="vertical"
          dataSource={dividendsList}
          renderItem={(item) => renderDividendDetails(item)}
        />
      )}
      {miniIndexList.length > 0 && (
        <List
          itemLayout="vertical"
          dataSource={miniIndexList}
          renderItem={(item) => renderMiniIndexDetails(item)}
        />
      )}
    </Modal>
  );
};

const CashflowIndicator = () => {
  const { data, isLoading } = useQuery<CashflowResult>({
    queryKey: ["accontCashflowIndicator"],
    queryFn: () => appApi.pnl.getCashflow(),
  });

  const { isMobile } = useScreenSize();
  const [cashflowDetailsVisible, setCashflowDetailsVisible] = useState<
    "0" | "1" | "2" | null
  >(null);
  const titleFontSize = isMobile ? 12 : 16;

  if (!data) return null;

  return (
    <div className="w-full">
      <CollapsibleCard title="Fluxo de caixa estimado" loading={isLoading}>
        {renderCashflowDetails({
          data,
          day: cashflowDetailsVisible,
          onCancel: setCashflowDetailsVisible,
        })}
        <div className="flex justify-between p-2">
          <div
            className={`flex flex-col cursor-pointer align-middle justify-center w-1/3 items-center p-2 rounded ml-1 ${
              data?.cashflowResultD1
                ? Number(data.cashflowResultD1) > 0
                  ? "bg-green-200"
                  : "bg-red-200"
                : "bg-gray-200"
            }`}
            onClick={() => setCashflowDetailsVisible("0")}
          >
            <Text>{formatDate(data.currentDate)}</Text>
            <Tooltip
              title={`Clique para ver detalhes do fluxo de caixa estimado para o dia ${formatDate(data.currentDate)}`}
            >
              <Title
                level={4}
                style={{ marginTop: 0, fontSize: titleFontSize }}
              >
                <PriceDisplay price={data?.cashflowResultD0} />
              </Title>
            </Tooltip>
          </div>
          <div
            className={`flex flex-col cursor-pointer align-middle justify-center w-1/3 items-center p-2 rounded ml-1 ${
              data?.cashflowResultD1
                ? Number(data.cashflowResultD1) > 0
                  ? "bg-green-200"
                  : "bg-red-200"
                : "bg-gray-200"
            }`}
            onClick={() => setCashflowDetailsVisible("1")}
          >
            <Text>{formatDate(data.datePlus1)}</Text>
            <Tooltip
              title={`Clique para ver detalhes do fluxo de caixa estimado para o dia ${formatDate(data.datePlus1)}`}
            >
              <Title
                level={4}
                style={{ marginTop: 0, fontSize: titleFontSize }}
              >
                <PriceDisplay price={data?.cashflowResultD1} />
              </Title>
            </Tooltip>
          </div>
          <div
            className={`flex flex-col cursor-pointer align-middle justify-center w-1/3 items-center p-2 rounded ml-1 ${
              data?.cashflowResultD2
                ? Number(data.cashflowResultD2) > 0
                  ? "bg-green-200"
                  : "bg-red-200"
                : "bg-gray-200"
            }`}
            onClick={() => setCashflowDetailsVisible("2")}
          >
            <Text>{formatDate(data.datePlus2)}</Text>
            <Tooltip
              title={`Clique para ver detalhes do fluxo de caixa estimado para o dia ${formatDate(data.datePlus2)}`}
            >
              <Title
                level={4}
                style={{ marginTop: 0, fontSize: titleFontSize }}
              >
                <PriceDisplay price={data?.cashflowResultD2} />
              </Title>
            </Tooltip>
          </div>
        </div>
      </CollapsibleCard>
    </div>
  );
};

export default CashflowIndicator;
