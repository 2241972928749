import { Button, Modal, Form, InputNumber } from "antd";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { appApi } from "../../api";

interface FormValues {
  id: string;
  overridenIncome: string | null;
}

export const EditMonthlyIncomeEntryRecord = ({
  id,
  overridenIncome,
  asset,
  date,
}: FormValues & {
  asset: string;
  date: string;
}) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [mutationLoading, setMutationLoading] = useState(false);
  const queryClient = useQueryClient();

  const overrideIncomeEntryMutation = useMutation({
    mutationFn: ({ overridenIncome }: { overridenIncome: string }) => {
      setMutationLoading(true);
      return appApi.pnl.overrideAccountMonthlyIncomeEntry({
        id,
        overridenIncome,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["accountMonthlyIncome"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["monthlyIncomeEntries"],
      });
      setMutationLoading(false);
      setVisible(false);
    },
    onError: async () => {
      setMutationLoading(false);
    },
  });

  return (
    <div>
      <Button type="link" onClick={() => setVisible(true)}>
        Editar
      </Button>
      <Modal
        title={"Sobrepor renda para o ativo " + asset + " em " + date}
        open={visible}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
        onOk={() => form.submit()}
        confirmLoading={mutationLoading}
      >
        <Form
          form={form}
          layout="horizontal"
          onFinish={() =>
            overrideIncomeEntryMutation.mutateAsync(form.getFieldsValue())
          }
          initialValues={{
            overridenIncome,
          }}
        >
          <Form.Item
            name="overridenIncome"
            label="Renda sobreposta"
            help={"Deixar em branco para limpar sobreposição"}
          >
            <InputNumber
              placeholder="Renda sobreposta"
              style={{ width: "100%" }}
              prefix="R$"
              decimalSeparator=","
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
