import ExternalLayout from "../layouts/externalLayout";
import DashboardLayout from "../layouts/dashboardLayout";
import { useAuth } from "../providers/authProvider";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Spin } from "antd";
import { loggedOutRoutes } from "../main";

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { isLoggedIn, isLoading } = useAuth();
  const navigate = useNavigate();
  const [time, setTime] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((time: number) => time + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (
      !isLoading &&
      !isLoggedIn &&
      navigate &&
      !loggedOutRoutes.includes(window.location.pathname.replace("/", ""))
    ) {
      navigate("/");
    }
  }, [isLoggedIn, isLoading, navigate]);

  if (time < 1) {
    return (
      <Flex
        align="center"
        vertical
        justify="center"
        style={{ height: "100vh" }}
      >
        <Spin size="large" />
      </Flex>
    );
  }

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      {isLoggedIn ? (
        <DashboardLayout>{children}</DashboardLayout>
      ) : (
        <ExternalLayout>{children}</ExternalLayout>
      )}
    </>
  );
};

export default Layout;
