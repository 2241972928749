import { Progress } from "antd";

const WalletMetricProgressBar = ({
  label,
  current,
  target,
}: {
  label: string | React.ReactNode;
  current: string | number | undefined;
  target: string | number | undefined;
}) => {
  let percent = (Number(current || 0) / Number(target || 1)) * 100;
  if (Number(target) === 0) {
    percent = 100;
  }

  return (
    <div>
      {label}
      {`: (${current} / ${target})`}
      <Progress
        percent={percent}
        format={(percent) => `${percent?.toFixed(0)}%`}
        className="pr-2 pl-2"
      />
    </div>
  );
};

export default WalletMetricProgressBar;
