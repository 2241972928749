import { Row, Col, Tag, Select, Form, List, Descriptions } from "antd";
import { useState } from "react";
import {
  ExerciseResultData,
  OptionBaseTicker,
  OptionsExerciseData,
} from "../../../api/models";
import { useQuery } from "@tanstack/react-query";
import { appApi } from "../../../api";
import CollapsibleCard from "../../../components/collapsibleCard";
import ResponsiveTable from "../../../components/ResponsiveTable";
import PriceDetails from "../../../components/priceDetails";
import TickerDetailsLink from "../../../components/tickerDetailsLink";
import {
  numberToPercentageDisplay,
  numberToCurrency,
} from "../../../services/utils";

const ExerciseScreen = () => {
  const [expirationDate, setExpirationDate] = useState<string | undefined>();
  const [baseTicker, setBaseTicker] = useState<string | undefined>();
  const [walletName, setWalletName] = useState<string | undefined>();

  const { data, isLoading } = useQuery<OptionsExerciseData>({
    queryKey: ["optionExercisesList", expirationDate, baseTicker, walletName],
    queryFn: () =>
      appApi.currentPositions.getExerciseOptions(
        expirationDate,
        baseTicker,
        walletName,
      ),
  });

  const expirationDates = data?.expirationDates || [];
  const defaultExpirationDate =
    expirationDates.length > 0 ? expirationDates[0] : undefined;
  const baseTickers = data?.relatedAssets || [];
  const wallets = data?.walletsList || [];
  const expirationRecordDetails = data?.data;
  const baseTickerRecords: OptionBaseTicker[] =
    expirationRecordDetails?.baseTickers || [];

  const columns = [
    {
      title: "Ativo",
      dataIndex: "ticker",
      key: "ticker",
      render: (value: string) => (
        <div className="flex flex-row">
          <TickerDetailsLink ticker={value} />
        </div>
      ),
    },
    {
      title: "Quantidade",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Tipo de opção",
      dataIndex: "optionType",
      key: "optionType",
    },
    {
      title: "Strike",
      dataIndex: "strikePrice",
      key: "strikePrice",
      render: (value: number) => numberToCurrency(value),
    },
    {
      title: "Distância do strike",
      dataIndex: "priceDistanceInPercentage",
      key: "priceDistanceInPercentage",
      render: (value: number) => numberToPercentageDisplay(value),
    },
    {
      title: "Carteira",
      dataIndex: "walletName",
      key: "walletName",
    },
    {
      title: "Resultado no momento",
      dataIndex: "exerciseData",
      key: "exerciseData",
      render: (value: ExerciseResultData) => {
        if (value.exerciseResult === "EXERCISED") {
          return <Tag color="red">Exercício</Tag>;
        }
        return <Tag color="green">Expiração</Tag>;
      },
    },
    {
      title: "Caixa do exercício",
      dataIndex: "exerciseData",
      key: "exerciseData",
      render: (value: ExerciseResultData) => {
        if (value.cashResult < 0) {
          return <Tag color="red">{numberToCurrency(value.cashResult)}</Tag>;
        }
        return <Tag color="green">{numberToCurrency(value.cashResult)}</Tag>;
      },
    },
  ];

  const renderBaseTickerCard = (record: OptionBaseTicker) => {
    const title = (
      <Descriptions
        layout="horizontal"
        column={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 4, xxl: 4 }}
      >
        <Descriptions.Item label="Ativo" className="pb-1">
          <TickerDetailsLink ticker={record.baseTicker} />
        </Descriptions.Item>
        <Descriptions.Item label="Quantidade atual" className="pb-1">
          {record.currentAmount}
        </Descriptions.Item>
        <Descriptions.Item label="Quantidade em exercício" className="pb-1">
          <Tag color="blue">{record.baseTickerExerciseData.exerciseAmount}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Preço" className="pb-1">
          <PriceDetails
            price={record.price.average}
            priceAsk={record.price.ask}
            priceBid={record.price.bid}
            priceStatus={record.price.status}
            liquidityStatus={record.price.liquidityStatus}
            priceDate={record.price.date}
            showAsTooltip={true}
          />
        </Descriptions.Item>
      </Descriptions>
    );
    return (
      <CollapsibleCard title={title} defaultCollapsed className="w-full">
        {record.hasOptionsInExercise && (
          <Row>
            <Col lg={8}>
              <Descriptions
                layout="horizontal"
                title={
                  <div className="pt-3 pr-3 pl-3 font-semibold text-base">
                    Exercício
                  </div>
                }
                bordered
                column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }}
              >
                <Descriptions.Item
                  label="Caixa sem ordem de zeragem"
                  className="pb-1"
                >
                  {numberToCurrency(
                    record.baseTickerExerciseData.cashResultWithoutOrder,
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Caixa com ordem de zeragem"
                  className="pb-1"
                >
                  {numberToCurrency(
                    record.baseTickerExerciseData.cashResultWithOrder,
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col lg={16}>
              <Descriptions
                layout="horizontal"
                title={
                  <div className="pt-3 pr-3 pl-3 font-semibold text-base">
                    Ordem de zeragem
                  </div>
                }
                bordered
                column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2 }}
              >
                <Descriptions.Item label="Tipo de ordem">
                  {record.baseTickerExerciseData.orderAmount > 0
                    ? "Compra"
                    : "Venda"}
                </Descriptions.Item>
                <Descriptions.Item label="Quantidade da ordem" className="pb-1">
                  {Math.abs(record.baseTickerExerciseData.orderAmount)}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Preço da ordem a enviar"
                  className="pb-1"
                >
                  {record.baseTickerExerciseData.orderPriceToSend ===
                  record.price.average
                    ? "Mercado"
                    : numberToCurrency(
                        record.baseTickerExerciseData.orderPriceToSend,
                      )}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Preço provável de execução"
                  className="pb-1"
                >
                  {numberToCurrency(
                    record.baseTickerExerciseData.orderLikelyExecutionPrice,
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Preço máximo para enviar"
                  className="pb-1"
                >
                  {record.baseTickerExerciseData.orderMaxPrice ===
                  record.price.average
                    ? "Mercado"
                    : numberToCurrency(
                        record.baseTickerExerciseData.orderMaxPrice,
                      )}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Preço mínimo para enviar"
                  className="pb-1"
                >
                  {record.baseTickerExerciseData.orderMinPrice ===
                  record.price.average
                    ? "Mercado"
                    : numberToCurrency(
                        record.baseTickerExerciseData.orderMinPrice,
                      )}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        )}
        <ResponsiveTable
          title={() => <div className=" font-semibold">Opções</div>}
          columns={columns}
          dataSource={record.options}
          loading={isLoading}
        />
      </CollapsibleCard>
    );
  };

  return (
    <Row className="w-full">
      <CollapsibleCard title="Exercício de opções">
        <Row className="w-full">
          <List
            header={
              <Row className="flex flex-row justify-between">
                <Form.Item label="Data de expiração" className="mb-1">
                  <Select
                    placeholder="Selecione a data de expiração"
                    style={{ width: 200 }}
                    onChange={(value) => setExpirationDate(value)}
                    defaultValue={defaultExpirationDate}
                    value={expirationDate || defaultExpirationDate}
                  >
                    {expirationDates.map((date) => (
                      <Select.Option key={date} value={date}>
                        {date}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Ativo base" className="mb-1">
                  <Select
                    placeholder="Selecione o ativo base se desejar"
                    style={{ width: 200 }}
                    onChange={(value) => setBaseTicker(value)}
                  >
                    {baseTickers.map((ticker) => (
                      <Select.Option key={ticker} value={ticker}>
                        {ticker}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Carteira" className="mb-1">
                  <Select
                    placeholder="Selecione a carteira se desejar"
                    style={{ width: 200 }}
                    onChange={(value) => setWalletName(value)}
                  >
                    {wallets.map((wallet) => (
                      <Select.Option key={wallet} value={wallet}>
                        {wallet}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Row>
            }
            className="w-full"
            itemLayout="horizontal"
            dataSource={baseTickerRecords}
            renderItem={(record) => renderBaseTickerCard(record)}
          />
        </Row>
      </CollapsibleCard>
    </Row>
  );
};

export default ExerciseScreen;
