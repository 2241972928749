import { Tag, Tooltip } from "antd";
import PriceDisplay from "./priceDisplay";
import {
  PriceLiquidityStatus,
  PriceStatus,
  decodePriceLiquidityStatus,
  decodePriceStatus,
} from "../api/models";
import { formatDateTime } from "../services/dates";

const PriceDetails = ({
  price,
  showAsTooltip = false,
  liquidityStatus,
  priceStatus,
  priceBid,
  priceAsk,
  priceDate,
}: {
  price: number | string;
  liquidityStatus?: string;
  priceStatus?: string;
  priceBid?: number;
  priceAsk?: number;
  priceDate?: string | null | undefined;
  showAsTooltip: boolean;
}) => {
  if (!price) {
    return <Tag color="red">Não disponível</Tag>;
  }
  const details = (
    <div>
      <h3>Detalhes do preço</h3>
      <div>
        <p>
          Status de atualização:{" "}
          {priceStatus ? decodePriceStatus(priceStatus) : "Não disponível"}
        </p>
        <p>
          Data da última atualização:{" "}
          {priceDate ? formatDateTime(priceDate) : "Não disponível"}
        </p>
        <p>
          Liquidez:{" "}
          {liquidityStatus
            ? decodePriceLiquidityStatus(liquidityStatus)
            : "Não disponível"}
        </p>
        <p>
          Bid: {priceBid ? <PriceDisplay price={priceBid} /> : "Não disponível"}
        </p>
        <p>
          Ask: {priceAsk ? <PriceDisplay price={priceAsk} /> : "Não disponível"}
        </p>
      </div>
    </div>
  );

  if (showAsTooltip) {
    const color =
      priceStatus === PriceStatus.UPDATED &&
      liquidityStatus === PriceLiquidityStatus.LIQUID
        ? "green"
        : priceStatus === PriceStatus.OUTDATED ||
            liquidityStatus === PriceLiquidityStatus.NO_LIQUIDITY ||
            priceStatus == PriceStatus.MARKET_CLOSED
          ? "orange"
          : "red";
    return (
      <Tooltip title={details}>
        <Tag color={color}>
          <PriceDisplay price={price} />
        </Tag>
      </Tooltip>
    );
  }

  return details;
};

export default PriceDetails;
