import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Typography } from "antd";
import { useAuth } from "../../providers/authProvider";
import { useEffect } from "react";

type FieldType = {
  email: string;
  password: string;
};

const LoginPage = () => {
  const navigate = useNavigate();
  const { login, isLoggedIn } = useAuth();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard");
    }
  }, [isLoggedIn, navigate]);

  const navigateToPasswordReset = () => {
    navigate("/password-reset");
  };

  return (
    <>
      <Typography.Title level={4}>Login</Typography.Title>
      <Form
        name="basic"
        style={{
          maxWidth: 500,
          width: "100%",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
        layout="vertical"
        onFinish={login}
        autoComplete="off"
      >
        <Form.Item<FieldType>
          label="Email"
          name="email"
          rules={[{ required: true, message: "Por favor preencha o e-mail" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label="Senha"
          name="password"
          rules={[{ required: true, message: "Por favor preencha a senha" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item className="flex justify-center">
          <Button type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>
        <Form.Item className="flex justify-center">
          <Button type="link" onClick={() => navigateToPasswordReset()}>
            Esqueci a senha
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default LoginPage;
