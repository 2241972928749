import type { ListResponse } from "../../api/types";
import { PositionEvent, PositionEventTypes } from "../../api/models";
import { useQuery } from "@tanstack/react-query";
import { appApi } from "../../api";
import { Link } from "react-router-dom";
import { TabKeys } from "../wallet/positions";
import CollapsibleCard from "../../components/collapsibleCard";
import TickerDetailsLink from "../../components/tickerDetailsLink";
import ResponsiveTable from "../../components/ResponsiveTable";
import PriceDisplay from "../../components/priceDisplay";

const LastWalletEventsTable = () => {
  const queryKey = "lastWalletEventsTable";
  const { data, isLoading } = useQuery<ListResponse<PositionEvent>>({
    queryKey: [queryKey],
    queryFn: () => appApi.positionEvent.getList(1, undefined, 5),
  });

  const columns = [
    {
      title: "Data Com",
      dataIndex: "comDate",
      key: "comDate",
    },
    {
      title: "Ticker",
      dataIndex: "ticker",
      key: "ticker",
      render: (value: string) => <TickerDetailsLink ticker={value} />,
    },
    {
      title: "Tipo",
      dataIndex: "eventTypeDisplay",
      key: "eventTypeDisplay",
    },
    {
      title: "Detalhe",
      dataIndex: "stockAmount",
      key: "financialAmount",
      render: (value: string, record: PositionEvent) =>
        [
          PositionEventTypes.STOCK_BONIFICATION,
          PositionEventTypes.STOCK_GROUPING,
          PositionEventTypes.STOCK_SPLIT,
        ] ? (
          record.eventAmount
        ) : value ? (
          <PriceDisplay price={value} />
        ) : (
          "N/A"
        ),
    },
  ];

  const title = (
    <div className="flex flex-row justify-between items-center w-full">
      <div className="font-semibold">Últimos eventos da carteira</div>
      <div>
        <Link to={`/positions/?tab=${TabKeys.EVENTS}`}>Ver todos</Link>
      </div>
    </div>
  );

  return (
    <CollapsibleCard title={title} defaultCollapsed={false}>
      <ResponsiveTable
        dataSource={data?.results}
        columns={columns}
        className="w-full"
        loading={isLoading}
        size={"small"}
        pagination={false}
        rowKey="id"
      />
    </CollapsibleCard>
  );
};

export default LastWalletEventsTable;
