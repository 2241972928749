import { Typography, Layout, Flex, Menu, MenuProps } from "antd";
import {
  LockOutlined,
  RadarChartOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const { Header, Content, Footer } = Layout;

export default function ExternalLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const navigate = useNavigate();
  const [current, setCurrent] = useState("login");

  const externalMenuItems = [
    { key: "login", label: "Login", icon: <LockOutlined /> },
    {
      key: "monitor",
      label: "Monitor de opções",
      icon: <RadarChartOutlined />,
    },
    {
      key: "reset-password",
      label: "Resetar senha",
      icon: <QuestionCircleOutlined />,
    },
  ];

  const onClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key);
    if (e.key === "monitor") {
      navigate("/options-monitor");
    }
    if (e.key === "login") {
      navigate("/");
    }
    if (e.key === "reset-password") {
      navigate("/password-reset");
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography.Title
          level={2}
          style={{
            color: "white",
            paddingRight: "50px",
          }}
        >
          FIAInvest
        </Typography.Title>
        <Menu
          className=" flex-1"
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={[current]}
          items={externalMenuItems}
          onClick={onClick}
        />
      </Header>
      <Content
        style={{
          padding: "16px",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            background: "white",
            height: "100%",
            minHeight: "80vh",
            padding: 8,
            borderRadius: 8,
          }}
        >
          <Flex vertical align="center" className=" h-full">
            {children}
          </Flex>
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        FIAInvest ©{new Date().getFullYear()}
      </Footer>
    </Layout>
  );
}
