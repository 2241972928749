import { Tabs } from "antd";
import type { TabsProps } from "antd";
import DailyPnl from "./daily";
import MonthlyPNLRecords from "./monthlyPNLRecords";
import YearlyPNLRecords from "./yearlyPNLRecords";
import { useSearchParams } from "react-router-dom";

const items: TabsProps["items"] = [
  {
    key: "monthly",
    label: "Mensal",
    children: <MonthlyPNLRecords />,
  },
  {
    key: "daily",
    label: "Diário",
    children: <DailyPnl />,
  },
  {
    key: "anual",
    label: "Anual",
    children: <YearlyPNLRecords />,
  },
];

const PNLPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const period = searchParams.get("period") || "monthly";
  let defaultTab = "monthly";
  if (["daily", "monthly", "anual"].includes(period)) {
    defaultTab = period;
  }

  return (
    <Tabs
      items={items}
      activeKey={defaultTab}
      onChange={(activeKey) => setSearchParams({ period: activeKey })}
      tabBarStyle={{ paddingLeft: 16, paddingRight: 16 }}
    />
  );
};

export default PNLPage;
