import React, { useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const CollapsibleCard = ({
  title,
  loading,
  children,
  defaultCollapsed = false,
  collapsible = true,
  className,
  classNameBody,
  bordered = true,
  action = null,
}: {
  title: string | React.ReactNode;
  loading?: boolean;
  collapsible?: boolean;
  children: string | React.ReactNode;
  defaultCollapsed?: boolean;
  className?: string;
  classNameBody?: string;
  bordered?: boolean;
  action?: React.ReactNode;
}) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  let titleClassNames =
    "flex flex-row justify-between font-semibold items-center w-full border-solid border-0 pt-2 pb-3";
  if (!collapsed) {
    titleClassNames += " border-b border-gray-300";
  }

  const titleWithArrow = (
    <div className={titleClassNames}>
      {title}
      {action}
      {!collapsible ? null : (
        <div
          className="cursor-pointer"
          onClick={() => {
            if (collapsible) {
              setCollapsed(!collapsed);
            }
          }}
        >
          {collapsed ? (
            <DownOutlined className="mx-2" />
          ) : (
            <UpOutlined className="mx-2" />
          )}
        </div>
      )}
    </div>
  );
  const classNameOuter = "p-2 w-full";
  return (
    <div className={classNameOuter}>
      <div
        className={`w-full ${
          bordered ? "border border-solid border-gray-300" : ""
        } p-2 rounded-md ${className}`}
      >
        {titleWithArrow}
        {collapsed ? null : loading ? (
          <div className="flex w-full align-middle justify-center min-h-40 items-center">
            <Spin />
          </div>
        ) : (
          <div className={`pt-2 ${classNameBody}`}>{children}</div>
        )}
      </div>
    </div>
  );
};

export default CollapsibleCard;
