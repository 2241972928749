import "chart.js/auto";
import { WalletAllocation, WalletAllocations } from "../../api/models";
import { appApi } from "../../api";
import { Alert } from "antd";
import { generateRandomColors } from "../../services/utils";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useQuery } from "@tanstack/react-query";
import CollapsibleCard from "../../components/collapsibleCard";
import { useEffect } from "react";

ChartJS.register(ArcElement, Tooltip, Legend);

const ValueAllocationChart = () => {
  const { data, isLoading } = useQuery<WalletAllocations>({
    queryKey: ["walletAllocations"],
    queryFn: () => appApi.wallets.getWalletAllocations(),
  });

  // subscribing to the visibility changed event and re-rendering the chart whenever the tab transitions to visible
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        const chart = ChartJS.getChart("valueAllocationChart");
        if (chart) {
          chart.update();
        }
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const items: WalletAllocation[] | undefined = data?.wallets;

  if (!items || items.length === 0) {
    return (
      <Alert
        type="warning"
        message="Nenhuma posição registrada em nenhuma das carteiras de valor (Ações, FIIs, Fiagro, Internacional ou Outros)."
      />
    );
  }
  const colors = generateRandomColors(items.length);
  const colorsRGBA = colors.map((color) => `rgba(${color}, 0.8)`);

  const chartData = {
    labels: items.map((item) => `${item.walletName} : ${item.percentage}%`),
    datasets: [
      {
        label: "Alocação",
        data: items.map((item) => item.totalValue),
        backgroundColor: colorsRGBA,
      },
    ],
  };

  return (
    <div className="w-full">
      <CollapsibleCard title={"Alocação por carteira"} loading={isLoading}>
        <Doughnut data={chartData} id="valueAllocationChart" />
      </CollapsibleCard>
    </div>
  );
};

export default ValueAllocationChart;
