import "chart.js/auto";
import { PnlChartData } from "../../api/models";
import { appApi } from "../../api";
import { Form, Select, Alert } from "antd";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { useQuery } from "@tanstack/react-query";
import CollapsibleCard from "../../components/collapsibleCard";
import { useEffect, useState } from "react";
import {
  getPreferredPnlVisualization,
  savePreferredPnlVisualization,
} from "../../services/localStorage";

ChartJS.register(ArcElement, Tooltip, Legend);

enum ChartPeriod {
  DAILY = "Diário",
  MONTHLY = "Mensal",
  YEARLY = "Anual",
}

const ChartPeriodValueMap: any = {
  [ChartPeriod.DAILY]: "daily",
  [ChartPeriod.MONTHLY]: "monthly",
  [ChartPeriod.YEARLY]: "yearly",
};

const daysList = [30, 60, 90, 180];
const monthslist = [6, 12, 24, 36];

const PnlChart = () => {
  const preferredPnlVisualization = getPreferredPnlVisualization();
  const [displayedPeriod, setDisplayedPeriod] = useState(
    preferredPnlVisualization
      ? preferredPnlVisualization.period
      : ChartPeriod.DAILY,
  );
  const [chartPeriod, setChartPeriod] = useState(
    preferredPnlVisualization
      ? ChartPeriodValueMap[preferredPnlVisualization.period]
      : ChartPeriodValueMap[ChartPeriod.DAILY],
  );
  const [displayedBarMode, setDisplayedBarMode] = useState<
    "totalized" | "detailed" | "daily"
  >(
    preferredPnlVisualization
      ? preferredPnlVisualization.displayedBarMode
      : "totalized",
  );
  const [detailPeriod, setDetailPeriod] = useState(
    preferredPnlVisualization
      ? parseInt(preferredPnlVisualization.detailPeriod)
      : 30,
  );

  const { data, isLoading } = useQuery<PnlChartData[]>({
    queryKey: ["pnlChartData", chartPeriod, detailPeriod],
    queryFn: () => appApi.pnl.getPnlChartData(chartPeriod, detailPeriod),
  });

  // saving the user's preferred PNL visualization settings
  useEffect(() => {
    savePreferredPnlVisualization({
      period: displayedPeriod,
      displayedBarMode,
      detailPeriod: detailPeriod.toString(),
    });
  }, [displayedPeriod, displayedBarMode, detailPeriod]);

  // subscribing to the visibility changed event and re-rendering the chart whenever the tab transitions to visible
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        const chart = ChartJS.getChart("pnlChart");
        if (chart) {
          chart.update();
        }
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  let chartData;
  if (displayedBarMode === "detailed") {
    chartData = {
      labels: data ? data.map((item) => item.date) : [],
      datasets: [
        {
          label: "PNL acumulado até o período anterior (R$)",
          data: data ? data.map((item) => item.details.previousDayPnl) : [],
          backgroundColor: "#777a82",
        },
        {
          label: "PNL de Ações (R$)",
          data: data ? data.map((item) => item.details.wallets["ações"]) : [],
          backgroundColor: "#7d9ff5",
        },
        {
          label: "PNL de Internacional (R$)",
          data: data
            ? data.map((item) => item.details.wallets["internacional"])
            : [],
          backgroundColor: "#2661f5",
        },
        {
          label: "PNL de FIIs (R$)",
          data: data
            ? data.map((item) => item.details.wallets["fundosImobiliários"])
            : [],
          backgroundColor: "#baceff",
        },
        {
          label: "PNL de Fiagros (R$)",
          data: data
            ? data.map((item) => item.details.wallets["fundosDoAgronegócio"])
            : [],
          backgroundColor: "#5791fc",
        },
        {
          label: "PNL de Outros (R$)",
          data: data ? data.map((item) => item.details.wallets["outros"]) : [],
          backgroundColor: "#3dc190",
        },
        {
          label: "PNL de Renda (R$)",
          data: data ? data.map((item) => item.details.wallets["renda"]) : [],
          backgroundColor: "#dc3450",
        },
        {
          label: "PNL de Carrego (R$)",
          data: data ? data.map((item) => item.details.wallets["carrego"]) : [],
          backgroundColor: "#912f40",
        },
        {
          label: "PNL de Figuras (R$)",
          data: data ? data.map((item) => item.details.wallets["figuras"]) : [],
          backgroundColor: "#f57d91",
        },
      ],
    };
  } else if (displayedBarMode === "daily") {
    chartData = {
      labels: data ? data.map((item) => item.date) : [],
      datasets: [
        {
          label: "PNL diário",
          data: data ? data.map((item) => item.pnl) : [],
          backgroundColor: "#dc3450",
        },
      ],
    };
  } else {
    chartData = {
      labels: data ? data.map((item) => item.date) : [],
      datasets: [
        {
          label: "PNL acumulado",
          data: data ? data.map((item) => item.accumulatedPnl) : [],
          backgroundColor: "#c2bb3e",
        },
      ],
    };
  }

  return (
    <CollapsibleCard title={"PNL"} loading={isLoading}>
      <Form layout="inline" style={{ marginBottom: "20px" }}>
        <Form.Item
          name="chartDetails"
          label="Detalhamento"
          initialValue={displayedBarMode}
          rules={[{ required: true }]}
        >
          <Select
            style={{ minWidth: 75 }}
            value={displayedBarMode}
            onChange={(value) => {
              setDisplayedBarMode(value);
            }}
          >
            <Select.Option key="totalized" value="totalized">
              Acumulado
            </Select.Option>
            <Select.Option key="detailed" value="detailed">
              Detalhado
            </Select.Option>
            <Select.Option key="daily" value="daily">
              Diário
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="chartPeriod"
          label="Peridicidade"
          initialValue={displayedPeriod}
          rules={[{ required: true }]}
        >
          <Select
            style={{ minWidth: 75 }}
            value={displayedPeriod}
            onChange={(value) => {
              setDisplayedPeriod(value);
              setChartPeriod(ChartPeriodValueMap[value]);
              if (value === ChartPeriod.DAILY) {
                setDetailPeriod(30);
              } else {
                setDetailPeriod(6);
              }
            }}
          >
            {Object.entries(ChartPeriod).map(([_, value]) => (
              <Select.Option key={value} value={value}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {displayedPeriod === ChartPeriod.DAILY && (
          <Form.Item
            name="periodCountDays"
            label="Quantidade de dias"
            rules={[{ required: true }]}
            style={{ minWidth: 75 }}
            initialValue={detailPeriod || daysList[0]}
          >
            <Select
              onChange={(value) => setDetailPeriod(value)}
              value={detailPeriod}
            >
              {daysList.map((value) => (
                <Select.Option key={value} value={value}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {displayedPeriod === ChartPeriod.MONTHLY && (
          <Form.Item
            name="periodCountMonths"
            label="Quantidade de meses"
            rules={[{ required: true }]}
            style={{ minWidth: 75 }}
            initialValue={detailPeriod || monthslist[0]}
          >
            <Select
              onChange={(value) => setDetailPeriod(value)}
              value={detailPeriod}
            >
              {monthslist.map((value) => (
                <Select.Option key={value} value={value}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form>
      {(!data && !isLoading) || data?.length === 0 ? (
        <Alert
          type="warning"
          message="Você ainda não possui nenhum resultado de PNL registrado."
        />
      ) : (
        <Bar data={chartData} options={options} id="pnlChart" />
      )}
    </CollapsibleCard>
  );
};

export default PnlChart;
