import React, { createContext, useContext, useState } from "react";
import AssetDetailsDrawer from "../components/asset-details-drawer";

export type AssetDetailsDisplay = {
  ticker: string;
};

interface AssetDetailsContextType {
  ticker: string;
  showTickerDetails: ({ ticker }: AssetDetailsDisplay) => void;
}

const AssetDetailsContext = createContext<AssetDetailsContextType | undefined>(
  undefined,
);

export const AssetDetailsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [ticker, setTicker] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const showTickerDetails = ({ ticker }: AssetDetailsDisplay) => {
    setTicker(ticker);
    setOpen(true);
  };

  return (
    <AssetDetailsContext.Provider value={{ ticker, showTickerDetails }}>
      {children}
      <AssetDetailsDrawer
        open={open && ticker !== ""}
        onClose={() => setOpen(false)}
        ticker={ticker}
      />
    </AssetDetailsContext.Provider>
  );
};

export const useAssetDetailsDrawer = () => {
  const context = useContext(AssetDetailsContext);
  if (!context) {
    throw new Error(
      "useAssetDetailsDrawer must be used within an AssetDetailsProvider",
    );
  }
  return context;
};

export default AssetDetailsProvider;
